
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';
export const APP_LOADING = 'APP_LOADING';
export const SET_TOKEN = 'SET_TOKEN';
export const LOADING = 'LOADING';
export const GET_ITEMS = 'GET_ITEMS';
export const GET_SPECIFIC_ITEMS = 'GET_SPECIFIC_ITEMS';
export const SELECT_ITEM = 'SELECT_ITEM';
export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const COUNT_CLASSES = 'COUNT_CLASSES';
export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_LOCATION_MAP = 'UPDATE_LOCATION_MAP';
export const EMPTY_ROW_TO_EXPAND = 'EMPTY_ROW_TO_EXPAND';
export const UPDATE_SELECTION = 'UPDATE_SELECTION'
export const TOGGLE_ADD_DIALOG = 'TOGGLE_ADD_DIALOG'
export const DELETE_MULTIPLE = 'DELETE_MULTIPLE'

export const PRODUCTION_CYCLES_NUMBER = 'production_cycles number',
  PRODUCTION_CYCLES_RIGHT = 'production_cycle right',
  PRODUCTION_CYCLES_LIST = 'production_cycle list',
  AREAS_NUMBER = 'Areas Number',
  AREAS_RIGHT = 'Areas right',
  AREAS_LIST = 'Areas list',
  DEPLOYED_DEVICES_NUMBER = 'DEPLOYED_DEVICES Number',
  HAS_RENEWAL_DISCOUNT = 'HAS_RENEWAL_DISCOUNT',
  USER_RIGHTS = 'User Rights',
  USER_STATUS = 'User Status',
  PREFERRED_LANGUAGE = 'Preferred Language',
  USER_COUNTRY = 'User Country',
  PAYMENT_PROVIDER = 'Payment Provider',
  CURRENCY = 'Currency',
  CURRENCY_DECIMALS = 'Currency Decimals',
  USER_ID = 'User ID',

  SET_CURRENTLANGUAGE = "SET_CURRENTLANGUAGE",
  ACTIVE_SERVICES_PRODUCTION_CYCLES = "ACTIVE_SERVICES_PRODUCTION_CYCLES",
  STATS_LOADING = "STATS LOADING",
  USER_TYPE = 'USER_TYPE', START_IMPERSONATING = "START_IMPERSONATING",
  LEAVE_IMPERSONATING = "LEAVE_IMPERSONATING",

  HAS_RENEWAL_DISCOUNT_VALIDITY='HAS_RENEWAL_DISCOUNT_VALIDITY',
  DISCOUNT_FOR_ALL_ENABLED='DISCOUNT_FOR_ALL_ENABLED',
  DISCOUNT_FOR_ALL='DISCOUNT_FOR_ALL',
  DISCOUNT_FOR_ALL_VALIDITY='DISCOUNT_FOR_ALL_VALIDITY'





export const
  UPDATE_RECALCULATING_CYCLES = "UPDATE_RECALCULATING_CYCLES",
  GET_WEATHER_DATA = "GET_WEATHER_DATA",
  REMOVE_RECALCULATING_CYCLE = "REMOVE_RECALCULATING_CYCLE"


export const
  ACTIVE_SERVICES_SENCROP_WEATHER_STATIONS = "ACTIVE_SERVICES_SENCROP_WEATHER_STATIONS",
  SENCROP_WEATHER_STATIONS_NUMBER = "SENCROP_WEATHER_STATIONS_NUMBER",
  CAN_CREATE_SENCROP_STATION = "CAN_CREATE_SENCROP_STATION",
  BILLING_DETAILS="BILLING_DETAILS"
