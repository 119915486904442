/* eslint-disable no-unreachable*/

import React, { useReducer } from "react";

import IntroJsStepModal from "../../../App/Components/IntroJsStepModal";
import {
  CURRENT_STEP,
  INITIAL_STEP,
  IS_ENABLED,
} from "../../../App/globalConsts";
import { vocabulary } from "../../../App/Strings";
import goto from "../../../assets/guide/goto.gif";
import stopEditingBtn from "../../../assets/guide/AddAreaStopEditing.png";
import addAreaPolygonBtn from "../../../assets/guide/addAreaPolygon.png";
import addAreaRectangleBtn from "../../../assets/guide/addAreaRectangle.png";
import mapLayersBtn from "../../../assets/guide/mapLayers.png";
import drawAreaButtons from "../../../assets/guide/drawAreaButtons.png";
import drawArea from "../../../assets/guide/drawArea.gif";
import soilType from "../../../assets/guide/soilType.png";
import addSourceType from "../../../assets/guide/addSourceType.gif";
import snackBar from "../../../assets/guide/snackBar.png";
import addAreaFile from "../../../assets/guide/addAreaFile.gif";
import mapButton from "../../../assets/guide/mapButton.png";
import arrowSite from "../../../assets/guide/arrowSite.png";
import changeStepsUsingKeyboard from "../../../assets/guide/changeStepsUsingKeyboard.png";
import stepperTrigger from "../../../assets/guide/stepperTrigger.png";
import cultureCycle from "../../../assets/guide/cultureCycle.png";
import productionCycle from "../../../assets/guide/productionCycle.png";
import recommandedProductionCycle from "../../../assets/guide/recommandedProductionCycle.png";
import cycleDate from "../../../assets/guide/cycleDate.png";
import existCycleNotif from "../../../assets/guide/existCycleNotif.png";
import downloadXSL from "../../../assets/guide/downloadXSL.png";
import logsStatics from "../../../assets/guide/logsStatics.png";
import logsTable from "../../../assets/guide/logsTable.png";
import realTimeStatus from "../../../assets/guide/realTimeStatus.png";
import realTimeTable from "../../../assets/guide/realTimeTable.png";

// define the content of our guide in a dynamic way, so when we want to modify or add a step we can change it easly without modifying all the next steps.
// define the content in a structured object that it will consumed from Steps. So if you need to modify or add a step: please respect this syntax.
const content = {
  introSeabex: {
    title: vocabulary.introSeabexTitleGuide,
    description: vocabulary.introSeabexGuide,
  },
  changeLanguage: {
    title: vocabulary.changeLanguageTitleGuide,
    description: vocabulary.changeLanguageGuide,
  },
  changeStepsUsingKeyboard: {
    title: vocabulary.changeStepsTitleGuide,
    description: vocabulary.changeStepsGuide,
    images: {
      gif: { src: changeStepsUsingKeyboard, alt: "changeStepsUsingKeyboard" },
    },
  },
  stepperTrigger: {
    title: vocabulary.stepperTriggerTitleGuide,
    description: vocabulary.stepperTriggerGuide,
    images: { stepperTrigger: { src: stepperTrigger, alt: "stepperTrigger" } },
  },
  "vertical-timeline-element--work": {
    title: vocabulary.ariticleTitleGuide,
    description: vocabulary.articleGuide,
  },
  intro: {
    title: vocabulary.introSiteTitleGuide,
    description: vocabulary.introSiteGuide,
  },
  addIcon: {
    title: vocabulary.addSiteTitleGuide,
    description: vocabulary.addSiteGuide,
  },
  goToButton: {
    title: vocabulary.goToButtonTitleGuide,
    description: vocabulary.goToButtonGuide,
  },
  goToModalDialog: {
    title: vocabulary.goToModalTitleGuide,
    description: vocabulary.goToModalGuide,
    images: { gif: { src: goto, alt: "goto" } },
  },
  drawAreaBtn: {
    title: vocabulary.drawAreaButtonsTitleGuide,
    description: vocabulary.drawAreaButtonsGuide,
  },
  drawPolygonRectangleBtn: {
    title: vocabulary.drawPolygonRectangleButtonTitleGuide,
    description: vocabulary.drawPolygonRectangleButtonGuide,
    images: {
      gif: null,
      stopEditing: { src: stopEditingBtn, alt: "stopEditingBtn" },
      addAreaPolygon: { src: addAreaPolygonBtn, alt: "addAreaPolygonBtn" },
      addAreaRectangle: {
        src: addAreaRectangleBtn,
        alt: "addAreaRectangleBtn",
      },
      mapLayers: { src: mapLayersBtn, alt: "mapLayersBtn" },
      drawAreaButtons: {
        src: drawAreaButtons,
        alt: "drawAreaButtons",
        style: { height: 40 },
      },
    },
  },
  addNewAreaModal: {
    title: vocabulary.addNewAreaModalTitleGuide,
    description: vocabulary.addNewAreaModalGuide,
    images: { gif: { src: drawArea, alt: "drawArea" } },
  },
  areaOnMap: {
    title: vocabulary.areaOnMapTitleGuide,
    description: vocabulary.areaOnMapGuide,
  },
  areaSoilStructure: {
    title: vocabulary.areaSoilStructureTitleGuide,
    description: vocabulary.areaSoilStructureGuide,
    images: { gif: { src: soilType, alt: "soilType" } },
  },
  nextBtnAddArea: {
    title: vocabulary.nextButtonAddAreaTitleGuide,
    description: vocabulary.nextButtonAddAreaGuide,
  },
  dataSourceTable: {
    title: vocabulary.dataSourceTableTitleGuide,
    description: vocabulary.dataSourceTableGuide,
  },
  formFooter: {
    title: vocabulary.addNewSourceTitleGuide,
    description: vocabulary.addNewSourceGuide,
    images: {
      gif: { src: addSourceType, alt: "addSourceType", style: { height: 250 } },
    },
  },
  saveBtnAddArea: {
    title: vocabulary.saveBtnAddAreaTitleGuide,
    description: vocabulary.saveBtnAddAreaGuide,
  },
  areasListGuide: {
    title: vocabulary.areasListTitleGuide,
    description: vocabulary.areasListGuide,
    images: {
      snackBar: {
        src: snackBar,
        alt: "snackBar",
      },
    },
  },
  attachFileIcon: {
    title: vocabulary.addAreaUsingFileTitleGuide,
    description: vocabulary.addAreaUsingFileGuide,
    images: { gif: { src: addAreaFile, alt: "addAreaFile" } },
  },
  areasListGuideStep: {
    title: vocabulary.addParcelSectorTitleGuide,
    description: vocabulary.addParcelSectorGuide,
    images: {
      mapButton: { src: mapButton, alt: "mapButton" },
      arrowSite: { src: arrowSite, alt: "arrowSite", style: { height: 40 } },
    },
  },
  introCycle: {
    title: vocabulary.introCycleTitleGuide,
    description: vocabulary.introCycleGuide,
  },
  addCycleBtn: {
    title: vocabulary.addCycleBtnTitleGuide,
    description: vocabulary.addCycleBtnGuide,
  },
  addCycleModal: {
    title: vocabulary.addCycleModalTitleGuide,
    description: vocabulary.addCycleModalGuide,
    images: {
      cultureCycle: {
        src: cultureCycle,
        alt: "cultureCycle",
        style: { width: "100%" },
      },
      productionCycle: {
        src: productionCycle,
        alt: "productionCycle",
      },
      recommandedProductionCycle: {
        src: recommandedProductionCycle,
        alt: "recommandedProductionCycle",
        style: { height: "100%", width: "100%" },
      },
      cycleDate: {
        src: cycleDate,
        alt: "cycleDate",
        style: { height: 80, width: "100%" },
      },
      existCycleNotif: {
        src: existCycleNotif,
        alt: "existCycleNotif",
        style: { width: "100%" },
      },
    },
  },
  cycleList: {
    title: vocabulary.cycleListTitleGuide,
    description: vocabulary.cycleListGuide,
  },
  mapViewBtn: {
    title: vocabulary.mapViewBtnTitleGuide,
    description: vocabulary.mapViewBtnGuide,
  },
  onePagerMeteo: {
    title: vocabulary.onePagerMeteoTitleGuide,
    description: vocabulary.onePagerMeteoGuide,
  },
  onePagerWaterNeed: {
    title: vocabulary.onePagerWaterNeedTitleGuide,
    description: vocabulary.onePagerWaterNeedGuide,
  },
  onePagerWaterNeedHierarchy: {
    title: vocabulary.onePagerWaterNeedHierarchyTitleGuide,
    description: vocabulary.onePagerWaterNeedHierarchyGuide,
  },
  onePagerWaterNeedHistory: {
    title: vocabulary.onePagerWaterNeedHistoryTitleGuide,
    description: vocabulary.onePagerWaterNeedHistoryGuide,
  },
  // introDeployedDevices: {
  //   title: vocabulary.introDeployedDevicesTitleGuide,
  //   description: vocabulary.introDeployedDevicesGuide,
  // },
  // addDeployedDeviceBtn: {
  //   title: vocabulary.addDeployedDeviceBtnTitleGuide,
  //   description: vocabulary.addDeployedDeviceBtnGuide,
  // },
  // addDeployedDeviceModal1: {
  //   title: vocabulary.addDeployedDeviceModal1TitleGuide,
  //   description: vocabulary.addDeployedDeviceModal1Guide,
  // },
  // addDeployedDeviceModal2: {
  //   title: vocabulary.addDeployedDeviceModal2TitleGuide,
  //   description: vocabulary.addDeployedDeviceModal2Guide,
  // },
  // addDeployedDeviceModal3: {
  //   title: vocabulary.addDeployedDeviceModal3TitleGuide,
  //   description: vocabulary.addDeployedDeviceModal3Guide,
  // },
  realTimeintro: {
    title: vocabulary.realTimeintroTitleGuide,
    description: vocabulary.realTimeintroGuide,
  },
  realTime: {
    title: vocabulary.realTimeTitleGuide,
    description: vocabulary.realTimeGuide,
    images: {
      realTimeStatus: { src: realTimeStatus, alt: "realTimeStatus" },
      realTimeTable: {
        src: realTimeTable,
        alt: "realTimeTable",
        style: { width: "100%", height: "100%" },
      },
    },
  },
  logsIntro: {
    title: vocabulary.logsIntroTitleGuide,
    description: vocabulary.logsIntroGuide,
  },
  logs: {
    title: vocabulary.logsTitleGuide,
    description: vocabulary.logsGuide,
    images: {
      logsStatics: {
        src: logsStatics,
        alt: "logsStatics",
        style: { width: "100%", height: "100%" },
      },
      logsTable: {
        src: logsTable,
        alt: "logsTable",
        style: { width: "100%", height: "100%" },
      },
      downloadXSL: { src: downloadXSL, alt: "downloadXSL" },
    },
  },
};
let _steps = [];
for (const item in content) {
  _steps.push({
    element: "." + item,
    intro: <IntroJsStepModal content={content[item]} />,
    position: item === "changeLanguage" ? "right" : "",
  });
}
export const steps = _steps;

// initial state for our context
const initialState = { initialStep: 0, currentStep: "", isEnabled: false };

// define actions for 3 variables state changes
const reducer = (state, action) => {
  switch (action.type) {
    case INITIAL_STEP:
      return { ...state, initialStep: action.payload };
      break;
    case CURRENT_STEP:
      return { ...state, currentStep: action.payload };
      break;
    case IS_ENABLED:
      return { ...state, isEnabled: action.payload };
      break;
    default:
      // console.log("unhandled case error on Guide Context");
  }
};

// creation of guide context
export const GuideContext = React.createContext(initialState);

export const GuideContextProvider = ({ children }) => {
  // define our reducer with value: guide and action: dispatchGuide which is reducer function defined upstairs
  const [guide, dispatchGuide] = useReducer(reducer, initialState);

  return (
    // define our provider and share the guide values(initialStep,currentStep,isEnabled) and the function to modify our values (reducer)
    <GuideContext.Provider value={{ guide, dispatchGuide }}>
      {children}
    </GuideContext.Provider>
  );
};

export default GuideContextProvider;
