import React from "react";
import logo from './Logo_chambre_agriculture.svg';

export default (props) => {

    const {styles, ...properties} = props

    let style= {
        margin: 8,
        ...styles,
    }

    return (
        <img {...properties} src={logo} style={style} alt="Chambres d'Agriculture" />
    )

}
