import React from "react";
import logoSeabex from "../../images/seabex/SeabexPlatformWhiteBG.svg";
function IntroJsStepModal({ content }) {
  const { title, description, images } = content;
  const imageName = images && Object.keys(images);

  let newDescription = (typeof description === "object"
    ? description.props.children[1]
    : description
  )
    .split("\n") // return to a new line 
    .map((i) => {
      if (i.slice(0, 2) === "::" && images) { // ::image name:: if there is '::' that means the image name is between of them to define an image
        let imgName = i.slice(2).split("::");
        return (
          imageName.includes(imgName[0]) && ( // imgName[0]is the name of image
            <div
              style={{
                display: "flex",
                flexDirection:
                  /*currentLanguage === "ar" ? "row-reverse" :*/ "row",
              }}
            >
              <img
                style={{
                  height: 27,
                  marginRight: 8,
                  ...images[imgName[0]].style,
                }}
                src={images[imgName[0]].src}
                alt={images[imgName[0]].alt}
              />
              <p
                style={{
                  textAlign: /*currentLanguage === "ar" ? "right" : */ "left",
                }}
              >
                {imgName[1]} {/* text to show after image on the same line*/}
              </p>
            </div>
          )
        );
      } else if (i.slice(0, 2) === ";;") { // ';;' used to put text on bold style
        let textContent = i.slice(2).split(";;");
        return (
          <p
            style={{
              textAlign: /*currentLanguage === "ar" ? "right" : */ "left",
            }}
          >
            <b>{textContent[0]}</b> {/* text to show as title bold on the same line*/}
            {textContent[1]} {/* text to show in front of the title */}
          </p>
        );
      } else // return normal text or italic text when it start with '('
        return (
          <p
            style={{
              textAlign: /*currentLanguage === "ar" ? "right" : */ "left",
              fontStyle: i[0] === "(" ? "italic" : "normal",
            }}
          >
            {i}
          </p>
        );
    });
  return (
    <div style={{ overflowY: "auto", maxHeight: "33vh" }}>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <img style={{ height: 30 }} src={logoSeabex} alt="logoSeabex" />
      </div>
      {title && <h3 style={{ textAlign: "center" }}>{title}</h3>}
      <div style={{ marginBottom: 20 }}> {/* show gif image if there is one */ }
        {images?.gif && images.gif.src && images.gif.alt && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img
              style={{ maxWidth: "36vw", maxHeight: 200, ...images.gif.style }}
              src={images.gif.src}
              alt={images.gif.alt}
            />
          </div>
        )}
      </div>
      {newDescription && newDescription} {/*show the description after being formatted with this syntax */}
    </div>
  );
}

export default IntroJsStepModal;
