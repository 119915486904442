import moment from "moment";
import { REACT_DEBUG } from "../config";
import L from "leaflet";

export const makeid = (length = 8) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}

export const logger = (...args) => {
  if (REACT_DEBUG) {
    console.log("--->> CONSOLE LOG : ", ...args)
  }
}

export const getFormattedDate = (date, currentLanguage) => {
  return moment(date).format(getDateFormat(currentLanguage))
}

export const getFormattedDateTime = (date, currentLanguage) => {
  return moment(date).format(getDateFormat(currentLanguage) + " HH:mm")
}


export const getDateFormat = (currentLanguage) => {
  return currentLanguage === 'fr' ? "DD-MM-YYYY" : "MM-DD-YYYY"
}


export const customMapIcons = {
  site: new L.Icon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|abcdef&chf=a,s,ee00FFFF",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  }),
  parcel: new L.Icon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ed0514&chf=a,s,ee00FFFF",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  }),
  section: new L.Icon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|eb348f&chf=a,s,ee00FFFF",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  }),
  sensor: new L.Icon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|eb348f&chf=a,s,ee00FFFF",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  }),
  weather_station: new L.Icon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|52cf0a&chf=a,s,ee00FFFF",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  })
};

export const defaultMarker = new L.Icon.Default


export const validateBillingFields = (obj) => {
  const validationErrors = {};

 
  const requiredFields = [
    'company', 'tva', 'billingAddress',
    'notificationEmail', 'country', 'city', 'postal_code'
  ];

 
  const missingFields = requiredFields.filter(fieldName => !obj[fieldName]);
  if (missingFields.length > 0) {
    missingFields.forEach(fieldName => {
      validationErrors[fieldName] = `Veuillez remplir le champ ${fieldName}.`;
    });
  }

 
  if (obj.tva && obj.tva.length !== 14) {
    validationErrors['tva'] = 'Le numéro de TVA doit être exactement de 14 caractères.';
  }

  return validationErrors;
};

