import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledBackground, StyledCard } from '../../../ui/layout';
import CABySeabex from './assets/CABySeabex';
import { StyledInput, StyledSubmitButton } from '../../../ui/forms';
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../actions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const CA_Signin = () => {
  let history = useHistory();

  const { register, handleSubmit, errors } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,
    });
  };

  const login = (data) => {
    dispatch(
      loginUser(
        {
          email: data ? data.username : email,
          password: data ? data.password : password,
        },
        function (r) {
          if (r) {
            if (r.status === 200) {
              snackbar(vocabulary.accessGranted, 'success');
              if (r.data && r.data.user_type !== 'Super') {
                localStorage.setItem('login_Token', r.data.token);
                localStorage.setItem('userrights', r.data.user_type);
                localStorage.setItem('userid', r.data.userid);
                localStorage.setItem('username', r.data.username);
                localStorage.setItem('company_name', r.data.company_name ?? '');

                // mqttSubs(r.data.userid);
                history.push('/app/sites');
              } else history.push('/app/dashboardManagement');
            } else if (r.status === 403) {
              snackbar(vocabulary.checkAccessCode, 'error');
            } else if (r.status === 401) {
              snackbar(vocabulary.loginInvalidLabel, 'error');
            } else {
              snackbar(vocabulary.errorOccurred + ' ' + r.status, 'error');
            }
          }
        }
      )
    );
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(login());
    }
  };

  return (
    <StyledBackground style={{background:`url('/assets/backgrounds/seabex_ca_bg.jpg')`}}>
      <form onSubmit={handleSubmit(login)}>
        <Grid container direction='column' style={{ width: 960 }}>
          <Grid item style={{textAlign:'center'}}>
            <CABySeabex width={500} styles={{ marginBottom: 62 }} />
          </Grid>
          <Grid item>
            <StyledCard direction='row' elevation={8} styleProp={{padding:12, borderRadius:16}}>
              <Grid container direction='row' spacing={2}>
                <Grid item style={{ flexGrow: 1 }}>
                  <StyledInput
                    register={register}
                    rules={{ required: true }}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    styles={{ width: `100%` }}
                    name='username'
                    label={vocabulary.email}
                    onKeyDown={onEnterPress}
                    autoComplete='username'
                    errors={errors.email}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <StyledInput
                    register={register}
                    rules={{ required: true }}
                    name='password'
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    styles={{ width: `100%` }}
                    type={showPassword ? 'text' : 'password'}
                    label={vocabulary.password}
                    autoComplete='current-password'
                    onKeyDown={onEnterPress}
                    errors={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <StyledSubmitButton label={vocabulary.signin} />
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
          <Grid item style={{marginTop:62}}>
              <Typography style={{fontSize:15}}>Vous n'avez pas de compte ? <a href="#">Cliquez ici pour faire votre demande en ligne.</a></Typography>
          </Grid>
        </Grid>
      </form>
      {/* <a style={{position:"absolute", bottom:10, left:10}} href="https://www.pexels.com/video/view-of-green-plants-in-a-farmland-3880967/" rel="nofollow" >Video by Ilya Klimenko from Pexels</a> */}
    </StyledBackground>
  );
};

CA_Signin.propTypes = {
  // directions: PropTypes.arrayOf(PropTypes.oneOf(['top', 'right', 'bottom', 'left'])),
};

export default CA_Signin;
