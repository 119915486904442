/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from "react";

import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { vocabulary } from "../../App/Strings";
import { logger } from "../../globalsTools/utilities";
import { generateUid } from "../../utils";

export default (props) => {


    let { register,
        emptyDataMsg,
        indexV,
        rules,
        selectValue,
        styles,
        name=generateUid(),
        secondLabelField,
        errors,
        dataType,
        triggerValidation,
        label,
        data,
        valueField,
        labelField,
        render,
        disabled=false,
        addMargin=true,
         
        ...properties } = props

    const [selectedValue, setSelectedValue] = useState(selectValue)

    const useStyle = makeStyles((theme) => ({
        root: {
            margin: addMargin ? 8 : 0,
            ...styles,
            '& p': {
                fontSize: 12
            }
        },
        '& .MuiListItem': {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: 'rgba(200, 200, 200, 0.14)',
                    borderLeft: 'solid 4px',
                    paddingLeft: 12,
                },
                color: '#333333',
            },

        },
        selectComponent: {
            '& option': {
                backgroundColor: 'red',
                padding:12
            }
        }
    }))

    useEffect(() => {
        setSelectedValue(selectValue)
    }, [selectValue])

    const classes = useStyle();

    const checkErrors = (errorType) => {
        switch (errorType) {
            case 'required':
                return vocabulary.required
            case 'minLength':
                return vocabulary.minLength + rules.minLength

            default:
                return true
        }
    }


    return (
        <FormControl className={classes.root} name={name} disabled={disabled}
        >
            <InputLabel id={name + "-label"}>{label}</InputLabel>
            <Select
                inputRef={register && register({name, rules})}
                {...properties}
                required={rules && rules.required}
                name={name}
                id={name}
                inputProps = {{ref:register && register({name, rules})}}
                labelId={name + "-label"}
                fullWidth
                error={errors ? true : false}
            >
                {/* To do in future because there is some errors to handle  */}
                {
                    data == null 
                    ? <MenuItem key={null} value={''}>{vocabulary.loading}</MenuItem> : data.length == 0 ? <span style={{ width: '100%' }}>{emptyDataMsg ? emptyDataMsg : 'N/A'}</span> 
                    : null // <MenuItem component="div" value={null} >&nbsp;</MenuItem>

                }
                 
                {
                    data && data.length > 0 && data.map((item, index) => {

                        return (
                            <MenuItem
                                // component={'option'}
                                component="div"
                                key={index}
                                value={indexV ? index : dataType ? item.data_type[valueField] : valueField ? item[valueField] : item}
                            >
                                {render ? render(item) : (secondLabelField ? (item[secondLabelField] + ' ' + item[labelField])
                                    : (labelField ? item[labelField] : item))}
                            </MenuItem>
                        );
                    })
                }
            </Select>
            <FormHelperText>{errors ? checkErrors(errors.type) : null}</FormHelperText>
        </FormControl>
    )

}