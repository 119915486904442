import LocalizedStrings from 'react-localization';

import { en } from './Language/en';
import { ar } from './Language/ar';
import { fr } from './Language/fr';
import { netirrig_fr } from './Language/netirrig_fr';
import { DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT } from './globalConsts';
import { REACT_APP_ENV_VERSION } from '../config';

export const vocabulary = new LocalizedStrings({ ar: ar, fr: ((REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? { ...fr, ...netirrig_fr } : fr), en: en });


/*
export  = new LocalizedStrings({
    ar: {
        relay_status: 'حالة الترحيل',
        SoilElecConductivity: "الموصلية الكهربائية للتربة",
        SoilTemperature: 'درجة حرارة التربة',
        SoilWaterContent: 'محتوى الماء الحجمي للتربة',
        listView: 'عرض القائمة',
        finishCycle: 'إنهائه الآن',
        minLength: ' يجب أن يكون الحد الأدنى للطول',
        username: 'اسم المستخدم',
        timeLabel: 'زمن',
        addCycle: 'أضف دورة',
        andLabel:'و',
        groupeManagment: 'إدارة المجموعات',
        allStepsCompleted: 'اكتملت جميع الخطوات',
        isoUnit: 'وحدة ايزو',
        passwordDntMatch: 'كلمة المرور غير متطابقة',
        emailInvalidLabel: 'البريد الإلكتروني غير صحيح',
        lowLabel: 'منخفض',
        calculationParam: 'ضبط الحسابات',
        highLabel: 'عالي',
        endCycle: 'إنهاء دورة',
        confirmationTitle:'التأكيد',
        warningLabel: 'تحذير',
        veryHighLabel: 'عالي جدا',
        moderateLabel: 'معتدل',
        extremeLabel: 'شديد',
        tasksLabel: 'المهام',
        addedSucc: 'اضيف بنجاح',
        errorHandler: 'معالجة الأخطاء',
        pressureLabel: 'الضغط الجوي',
        noCycleInSite: 'لا توجد دورة إنتاج تعزى إلى هذا الموقع',
        waterNeedHistory: 'تاريخ إحتياج المياه',
        creationCycle: 'إنشاء دورة إنتاج',
        irrigationMode: 'وضع الري',
        LatitudeLabel: 'خط العرض',
        sunset: 'الشروق و الغروب',
        windStatus: 'حالة الرياح',
        'Total': 'مجموع',
        LongitudeLabel: 'خط الطول',
        alreadySubscribe: 'لقد اشتركت بالفعل في المجموعة',
        editCycle: 'تعديل الدورة',
        navigateTo: 'انتقل إلى',
        downloadLabel: 'تحميل ',
        yourSiteLabel: 'مواقعك',
        realTimeData: 'معلومات الوقت الحقيقي -',
        subscribeSucces: 'أنت مشترك في المجموعة',
        leftGroup: 'تركت المجموعة',
        drawZone: 'ارسم منطقة',
        noAttachedEquipment: 'لا توجد معدات مرفقة',
        goToLabel: 'اذهب إلى',
        backLabel: 'عودة',
        errorList: 'قائمة الأخطاء',
        placeToSearch: 'أدخل مكانًا للبحث عنه',
        valueLabel: 'تقدير',
        realTimeSensorsAndActuators: 'أجهزة الاستشعار والمحركات في الوقت الحقيقي',
        waterVolume: 'الري',
        waterRequirementLabel: 'متطلبات المياه وتوازن المياه',
        today: 'اليوم',
        subscriptions: 'الاشتراكات',
        chooseMySoilType: 'اختر نوع التربة الخاص بي',
        refreshData: 'تحديث البيانات',
        yesterday: 'في الامس',
        now: 'الآن',
        loading: 'جاري التحميل',
        mapView: 'عرض الخريطة',
        otherInformations: 'المعلومات الأخرى',
        public: 'عامة',
        irrigationMangement: 'إدارة الري',
        findIrrigation: 'ابحث عن الري',
        addIrrigation: 'أضف الري',
        editIrrigation: 'تعديل الري',
        originalValue: 'القيمة الأصلية',
        governates: 'محافظات',
        waterNeedOnDemand: 'متطلبات المياه عند الطلب ',
        specifications: 'مواصفات',
        addGrowthDuration: 'إضافة مدة النمو',
        editGrowthDuration: '  تعديل مدة النمو',
        confirmationLabel: 'هل أنت متأكد من أنك تريد',
        filterAdvice: 'استخدام المرشحات لمزيد من الدقة',
        selectAreaToMonitor: 'حدد المنطقة التي تريد مراقبتها',
        resetSelection: 'إعادة تعيين الاختيار',
        searchPlace: 'البحث عن الأماكن',
        private: 'خاص',
        search: 'بحث',
        rowsLabel: 'صفوف',
        parcel: 'قطعة أرض',
        calculatedValue: 'القيمة المحسوبة',
        nonProfit: 'غير ربحية',
        cycleDetails: 'تفاصيل الدورة',
        searchCountry: 'البحث عن البلد',
        searchGovernorate: 'البحث عن المحافظة',
        salinity: 'الملوحة',
        addDataType: 'إضافة نوع البيانات',
        editDataType: 'تعديل نوع البيانات',
        initialStage: 'المرحلة الأولية',
        'Phase initiale': 'المرحلة الأولية',
        durationInitialStage: 'مدة المرحلة الأولية',
        equipmentType: 'نوع الآداة',
        equipmentList: 'نوع قائمة المعدات',
        addEquipmentType: 'إضافة نوع المعدات',
        addEquipment: 'إضافة المعدات',
        editEquipment: 'تعديل المعدات',
        editEquipmentType: 'تعديل نوع المعدات',
        equipmentLabel: 'المعدات',
        developmentPhase: 'مرحلة التطوير',
        'Phase de developpement': 'مرحلة التطوير',
        durationDevelopmentPhase: 'مدة مرحلة التطوير',
        midSeasonPhase: 'مرحلة منتصف الموسم',
        'Phase mi-saison': 'مرحلة منتصف الموسم',
        durationMidSeasonPhase: ' مدة مرحلة منتصف الموسم',
        phaseArriereSaison: 'أواخر الموسم',
        'Phase arrière-saison': 'أواخر الموسم',
        durationPhaseArriereSaison: 'مدة أواخر الموسم ',
        addSoilTexture: 'إضافة نسيج التربة',
        editSoilTexture: 'تغيير نسيج التربة',
        addSoilType: 'إضافة نوع التربة',
        modelPackName: 'اسم النموذج',
        addModelPack: 'إضافة نموذج',
        editModelPack: 'تغيير نموذج',
        editCategory: 'تغيير الفئة',
        editSoilType: 'تغيير نوع التربة',
        efficiency: 'كفاءة',
        solTexture: 'نسيج التربة',
        name_ar: 'الاسم باللغة العربية',
        name_fr: 'الاسم باللغة الفرنسية',
        name_en: 'الاسم باللغة الإنجليزية',
        hectarMcube: 'متر مكعب / هكتار',
        useful_Reserve: 'احتياطي قابل للاستخدام',
        choiceByDeployedDevice: 'الاختيار حسب الجهاز المنتشر',
        sensibilite: 'حساسية',
        cultureFamily: 'عائلة الزراعة',
        addCultureFamily: 'إضافة عائلة الزراعة',
        editCultureFamily: 'تعديل عائلة الزراعة',
        addCulture: 'إضافة زراعة',
        editCulture: 'تعديل الزراعة',
        humidite_au_point_fletrissement: 'نقطة الذبول',
        humidite_capacite_au_champ: 'طاقة الحقل',
        densite_apparente: 'الكثافة الظاهرية',
        eau_utile: 'مياه صالحة للاستعمال',
        metreJour: 'مم / يوم',
        noDataAvailable: 'لا توجد بيانات متاحة',
        tomorrow: 'غدا',
        Super: 'مشرف',
        recommendedDate: 'التاريخ الموصى به',
        userManagement: 'إدارة المستخدم',
        userSoilManagement: 'إدارة التربة',
        userCultureManagement: 'إدارة المحاصيل',
        userGeoDataManagement: 'إدارة البيانات الجغرافية',
        userEquipmentManagment: 'إدارة المعدات',
        userCatalogManagement: 'إدارة الكتالوج',
        userIrrigationManagement: 'إدارة أنواع الري',
        activesince: "تاريخ بداية العمل",
        city: "المدينة",
        country: "البلد",
        mobile: "رقم الهاتف",
        address: "العنوان",
        diploma: "الشهادة",
        kc: 'KC',
        windUnit: 'كم/ساعة',
        windSpeedUnit: 'م / ث',
        precipitationUnit: 'لتر/متر مربع',
        statistics: 'إحصائيات',
        emailCheckAccount: 'يرجى التحقق من بريدك الالكتروني للتحقق من حسابك',
        emailCheckPassword: 'يرجى التحقق من بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
        emailCheckPasswordChanged: 'تم تغيير كلمة المرور بنجاح ، يرجى التحقق من حسابك',
        checkAccessCode: 'يرجى التحقق من رموز الوصول الخاصة بك',
        accessGranted: 'لقد تم منح الوصول!',
        errorOccurred: 'لقد حدث خطأ ',
        rainPrecipitation: 'هطول الأمطار',
        snowPrecipitation: 'تساقط الثلوج',
        chapriority: 'أولوية',
        maximumHeat: 'درجة الحرارة القصوى ',
        minimumHeat: 'درجة الحرارة الدنيا ',
        temperature: 'درجة الحرارة',
        temperatureApparent: 'درجة الحرارة المحسوسة',
        humidity: 'معدل الرطوبة',
        soilType: 'نوع التربة',
        soilStructure: 'هيكل التربة في المنطقة المختارة',
        doubleClickToShowAreaDetail: 'يرجى النقر مرتين على المنطقة المعروضة لتحميل التفاصيل ...',
        soilFamily: 'عائلة التربة ',
        deviceName: 'اسم الجهاز',
        phase: 'مرحلة',
        deviceFamily: 'عائلة الجهاز',
        deviceCategory: 'فئة الجهاز',
        deviceModel: 'طراز الجهاز',
        windSpeed: 'سرعة الرياح',
        windDirection: 'اتجاه الرياح',
        siteGateway: 'مدخل',
        evapotranspiration: 'مرجع التبخر ',
        waterRequirement: 'التبخر الفعلي',
        delay: 'الوقت',
        cycleDays: 'عدد الأيام منذ بداية الدورة',
        unity: 'وحدة القيس',
        operatorLabel: 'عامل رياضيات',
        SoilMoisture: 'رطوبة التربة',
        scEquation: 'معادلة',
        irrigationType: 'نوع الري ',
        addIrrigationType: 'إضافة نوع الري',
        editIrrigationType: 'تعديل نوع الري',
        irrigationFamily: 'عائلة الري',
        addIrrigationFamily: 'إضافة عائلة الري',
        editIrrigationFamily: 'تعديل عائلة الري',
        irrigationQantity: 'كمية الري  ',
        editParcel: 'تعديل قطعة الأرض',
        editSector: 'تعديل القطاع ',
        automatique: 'التشغيل الآلي ',
        manuell: 'تشغيل يدوي',
        fieldRequired: 'إلزامي',
        defaultCalculation: 'الحساب الافتراضي',
        speciality: "الإختصاص",
        experience: "الخبرة",
        region: "المنطقة",
        skills: "المهارات",
        signup: "التسجيل",
        required: "هذه الخانة ضرورية",
        firstname: "الاسم",
        lastname: "اللقب",
        email: "البريد الإلكتروني",
        password: "كلمة السر",
        c_password: "تأكيد كلمة السر",
        Farmer: "مزارع",
        Expert: "خبير",
        startDate: 'تاريخ البدء  ',
        organization: "منظمة",
        Institution: 'مؤسسة',
        agent: 'وكيل',
        ipv4: " IPv4 عناوين ",
        name: "اسم المحطة",
        monitoring: 'مراقبة',
        gateway: 'بوابة',
        nextpage: "الصفحة التالية",
        lastpage: "آخر صفحة",
        previouspage: "الصفحة السابقة",
        firstpage: "الصفحة الأولى",
        confirmDelete: 'تأكيد الحذف',
        eventsList: "قائمة الأحداث",
        Events: "أحداث",
        formules: "الصيغ",
        addSector: 'إضافة القطاع',
        forgetPassword: 'نسيت كلمة مرورك؟ انقر هنا.',
        resetPassword: 'إعادة تعيين كلمة المرور',
        checkYourEmail: 'تحقق من بريدك الالكتروني',
        newpassword: 'كلمة السر الجديدة',
        government: "حكومي",
        next: "التالي",
        previous: 'السابق',
        dewPoint: 'درجة  حرارة تكون الندى',
        signin: "تسجيل الدخول",
        farmers: "المزارعين",
        experts: "الخبراء",
        viewMore: "عرض المزيد",
        seeProfile: "عرض الصفحة الشخصية",
        connect: "ارتبط",
        connectionSuccessful: "تم الاتصال بنجاح",
        connectionFailed: "فشل الاتصال",
        sites: "المواقع",
        parcelsNumber: "عدد القطع",
        parcels: "قطع الأرض",
        titleLabel: "العنوان",
        size: "المساحة ",
        site: "الموقع ",
        area: "المنطقة",
        sousArea: 'المناطق الفرعية',
        sector: "المنطقة",
        areas: "المناطق",
        cancel: "إلغاء",
        stations: "المحطات",
        identifier: "المعرف",
        reference: "المرجع",
        title: "العنوان",
        description: "التفاصيل",
        position: "الموقع الجغرافي",
        changePriority: 'تغيير الأولوية',
        type: "النوع",
        addStation: "إضافة محطة",
        mode: 'طريقة',
        plantType: "النوع",
        harvestDate: "تاريخ الحصاد",
        home: "الصفحة الرئيسية",
        agree: 'اتفق',
        disagree: 'لا أوافق',
        start: "بداية",
        startAction: "بدء العمل",
        endAction: "نهاية العمل",
        day_number: 'عدد الأيام منذ الزرع',
        end: "النهاية",
        editLabel: 'تعديل',
        duration: "المدة الزمنية",
        station: "محطة",
        ChooseStatus: 'اختيار الحالة',
        stationName: 'اسم المحطة',
        selectGateway: "اختر بوابة",
        automationPlan: "خطط التشغيل الآلي",
        cycles: "الدورات",
        hectar: 'هكتار',
        expenses: "النفقات",
        revenues: "العائدات",
        categories: "الفئات",
        activityTypes: "أنواع النشاط",
        activities: "الأنشطة",
        profile: "الملف الشخصي",
        settings: "الإعدادات",
        logout: "الخروج",
        newSearch: "بحث جديد",
        open: "افتح",
        close: "أغلق",
        end_cycle: 'نهاية الدورة ',
        openCycle: 'بداية الدورة',
        NoData: "لا تتوافر بيانات",
        assistedMode: "الوضع بمساعدة",
        automaticMode: "الوضع التلقائي",
        AutomationPlan: "خطة الري",
        AssistedAction: "ساعد العمل",
        findEvents: "البحث عن الأحداث",
        addExpense: "إضافة حساب",
        created_at: "أنشئت في",
        reset: "أعاد للوضع السابق",
        confirmDeleteText: 'هل أنت متأكد من حذف المحطة',
        date: "التاريخ",
        noModelYet: 'لا يوجد نموذج حتى الآن',
        updateStation: 'تحديث محطة',
        item: "المنتج",
        planning: "التخطيط",
        addRevenue: "إضافة العائدات",
        total: "المجموع",
        quantity: "الكمية",
        price: "السعر",
        itemCategory: "فئة المنتج",
        formulasList: "قائمة الصيغ",
        reason: "السبب",
        culture: "زراعة",
        deleteCulture: 'حدف الزراعة',
        Culture_Name: 'إسم الزراعة  ',
        activityType: "نوع النشاط",
        cycle: "الدورة",
        endDate: "تاريخ الانتهاء",
        addActivity: "إضافة نشاط",
        automationPlans: "خطط التشغيل الآلي",
        AddAutomationPlan: "إضافة تشغيل الآلي",
        editAutomationPlan: 'تعديل التشغيل الالي',
        status: "الحالة",
        addCronJob: "إضافة مجدول أعمال‎",
        selectActionNode: "إختيار محطة التحكم ",
        chooseTheStatus: "اختيارالحالة",
        port: "المنفذ",
        from: "من",
        to: "إلى",
        areasList: 'قائمة المناطق',
        formulaDisplay: "هذه هي صيغتك",
        myformulas: "الصيغة الخاصة بي",
        allFormulas: "جميع الصيغ",
        selectedMonthDays: "اختيار أيام الشهر",
        selectedWeekDays: "اختيار أيام الأسبوع",
        owner: "مالك",
        delete: "حذف",
        category: "الفئة",
        categoryName: 'اسم الفئة',
        depth: '(العمق (بالمتر',
        addCategory: "إضافة فئة",
        addActivityType: "إضافة فئة",
        color: "أختراللون",
        sensorType: "نوع المستشعر",
        model: "النموذج",
        addNode: "إضافة محطة",
        Settings: "الإعدادات",
        mismatch: "كلمة التأكيد غير متطابقة",
        enabled: "يعمل",
        disabled: "لا يعمل",
        freespace: "مساحة فارغة",
        seeFormula: "انظر الصيغة",
        formula: "الصيغة",
        add: 'إضافة',
        addMarker: 'إضافة علامة',
        Logs: "السجلات",
        findLogs: "البحث عن سجلات",
        dataLogs: "سجلات البيانات",
        selectNode: "اختر محطة",
        nodeType: "نوع المحطة",
        captureTime: "وقت الالتقاط",
        remove: 'حذف',
        editArea: "تحيين المنطقة ",
        removeSite: "حذف الموقع",
        removeParcel: "حذف قطعةالأرض ",
        addArea: "إضافة منطقة",

        stopEditing: 'توقف عن التعديل',
        selectedArea: "المنطقة المختارة",
        addSite: "إضافة موقع",
        addParcel: "إضافة قطعة أرض",
        lines: "خطوط",
        drippers: "المنقطات",
        stationEquipments: 'معدات ',
        lastPing: "آخر بينغ",
        Ipv4: "Ipv4",
        freeSpace: "مساحة فارغة",
        seabexModelType: "نوع نموذج ",
        seabexModelCategory: 'فئة النموذج  ',
        seabexCategoryStation: 'محطة النموذج ',
        datatype: "نوع البيانات",
        weatherStations: "محطات الطقس",
        AgricultureArea: "المناطق الفلاحية",
        save: "حفظ",
        editSite: "تحيين بيانات قطعة الأرض",
        contactOrganization: 'اتصل بالمنظمة',
        ok: 'حسنا',
        scenarioLabel: "قواعد التشغيل الآلي",
        conditions: "ضبط الشروط",
        conditionsList: "قائمة الشروط ",
        deviceID: "معرف الجهاز",
        sensor: "المستشعر",
        pin: "الموقع",
        operator: "عامل رياضيات <>=",
        value: "القيمة",
        actions: "الإجراءت",
        actionsList: "قائمة الإجراءت",
        action: "الحدث",
        deleteAutomationPlan: "Delete Automation Plan",
        dayLabel: 'يوم',
        fullName: "الاسم الكامل",
        otherInformation: "المعلومات الأخرى",
        everyMonth: 'كل شهر',
        january: 'جانفي',
        february: 'فيفري',
        mars: 'مارس',
        april: 'أفريل',
        may: 'ماي',
        june: 'جوان',
        july: 'جويلية',
        august: 'أوت',
        september: 'سبتمبر',
        october: 'أكتوبر',
        november: 'نوفمبر',
        december: 'ديسمبر',
        everyMonthDay: 'كل أيام الشهر',
        everyDay: 'كل يوم',
        monday: 'الإثنين',

        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
        sunday: 'الأحد',
        addIrrigationPlan: 'إضافة خطة الري',
        selectStation: 'اختر المحطة',
        selectStatus: 'وضع البداية',
        choiceBySite: 'الاختيار حسب الموقع',
        selectActuator: "إختيار المنفذ ",
        newFormula: "صيغة جديدة",
        selectedMonths: 'الشهور المختارة',
        confirmBtn: 'تأكيد',
        cancelBtn: 'إلغاء',
        delayInMinute: 'الوقت بالدقائق',
        equation: 'معادلة',
        automatic: 'تلقائي',
        manuel: 'يدوي',
        soilSensibilite: 'حساسية التربة',
        FormulaName: "اسم الصيغة",
        AdvicesService: "خدمة النصائح والتنبيهات",
        unfollow: 'الغاء المتابعة',
        followers: 'متابعون',
        planification: 'تخطيط',
        nbRecievedNotifs: 'عدد الإخطارات المستلمة',
        lastNotifs: 'أحدث الإخطارات',
        sendingDate: 'تاريخ الارسال',
        notificationType: 'نوع إعلام',
        SubscriptionType: 'نوع الاشتراك',
        memberSince: 'عضو منذ',
        newFollower: 'تابع جديد',
        ville: 'مدينة',
        alert: 'تحذير',
        userList: 'قائمة المستخدمين',
        advice: 'النصيحة',
        sms: 'رسالة قصيرة',
        application: 'تطبيق',
        content: 'محتوى',
        newNotification: 'إشعاع جديد',
        nbbeneficiers: 'تحقق عدد المستفيدين',
        send: 'إرسال',
        priority: 'أولية',
        priorityLimit: 'من 1 إلى 50',
        Statistics: 'الإحصاء',
        agents: 'العمال',
        fullname: 'الاسم الكامل',
        SentNotifNumber: 'عدد الإخطارات المرسلة',
        newAgent: 'وكيل جديد',
        organizationName: 'اسم المنظمة',
        nbFollowers: 'عدد المتابعين',
        pricePerMonth: 'سعر شهريا/TND',
        submit: 'سجل',
        newAffiliatedorganization: 'منظمة تابعة جديدة',
        editAffiliatedorganization: 'تعديل منظمة تابعة',
        enterPhoneNumber: 'أدخل رقم الهاتف',
        requiredPhoneNumber: 'رقم الهاتف مطلوب',
        invalidPhoneNumber: 'رقم الهاتف غير صحيح',
        clientSeabex: 'عضو SEABEX',
        organizationcity: 'مدينة المنظمة ',
        emailNotValid: 'بريد إلكتروني خاطئ',
        nbFollowersSeabex: 'عدد أعضاء SEABEX',
        others: 'الأخرون',
        sentByAgent: 'أرسلت بواسطةالوكيل',
        warningAction: 'يجب تقديم إجراء واحد على الأقل',
        warningCondition: 'يجب توفير شرط واحد على الأقل',
        name_label: 'الاسم',
        Temperature: 'درجة الحرارة',
        waterInSoil: 'كمية المياه  في التربة  ',
        totalPrecipitation: 'هطول الأمطار الكلي ',
        cloudCover: 'كثافة الغيوم',
        uvIndex: 'مؤشر الأشعة فوق البنفسجية‎ ',
        nextWeekWeather: 'طقس الأيام القادمة',
        Evolution24H: "توقعات الطقس اليوم على مدار 24 ساعة",
        WeatherToday: "الطقس اليوم",
        addNewUser: 'إضافة مستخدم جديد',
        EditUser: 'تعديل مستخدم جديد',
        delegations: 'المعتمدية',
        productionCycle: 'دورة الإنتاج',
        rootingDepth: 'عمق الجذور ',
        cycleStatus: 'الجاري',
        addSubscription: 'إضافة اشتراك',
        editSubscription: 'تعديل الاشتراك',
        smsPrice: 'سعر الرسائل النصية القصيرة',
        emailPrice: 'سعر خدمة البريد الإلكتروني',
        appPrice: 'سعر خدمة التطبيقة',
        apiLabel: 'واجهة برمجة تطبيقات ',
        serielNumber: 'رقم تسلسل',
        map: 'خريطة',
        siteMap: 'خريطة الموقع',
        deployedDevices: 'الأجهزة المنتشرة',
        numberDeployedDevices: 'عدد الأجهزة المنتشرة',
        startTime: 'وقت البدء',
        endTime: 'وقت النهاية',
        detailsLabel: 'التفاصيل',
        dontHaveAnAccount: 'إذا كنت لا تملك حساب',
        youCanCreateOne: 'يمكنك إنشاء واحدة هنا',
        privacyPolicy: 'سياسة خاصة',
        termsConditions: 'الأحكام والشروط',
        addGroup: 'إضافة مجموعة',
        editGroup: 'تعديل مجموعة',
        statusCode: 'رمز الحالة',
        error_text: ' الخطأ',
        showAllGroup: 'عرض جميع المجموعات',
        showMyGroup: 'عرض مجموعاتي',
        leaveGroup: 'مغادرة المجموعة',
        disableDelete: 'لا يمكنك حذف هذه المجموعة',
        disableEdit: 'لا يمكنك تعديل هذه المجموعة',
        groupLabel: 'المجموعة',
        groupAdded: 'تمت إضافة المجموعة بنجاح',
        deleteMsg: 'حذف بنجاح',
        updateSucc: 'تم التحديث بنجاح',
        operationSucc: 'لقد تمت العملية بنجاح',
        errorChecked: 'تم فحص الخطأ',
        errorCorrected: 'تم تصحيح الخطأ',
        checkedLabel: 'التحقق',
        correctedLabel: 'تصحيحه',
        registerDone: 'لقد تم التسجيل ',
        subscribe: 'الإشتراك',
        finishLabel: 'إنهاء',
        'Autre': 'آخر',
        noRecordToDisplay: 'لا يوجد سجل للعرض',
        connectedLabel: 'متصل',
        notConnectedLabel: 'غير متصل',
        recommendedSoilType: 'استخدم نوع التربة الموصى به',
        RecommendedProductionCycle: 'استخدم دورة الإنتاج الموصى بها',
        customizeProductionCycle: 'تخصيص دورة الإنتاج الخاصة بك',

    },
    en: {
        // site label
        parcelsNumber: "Number of Parcels",
        areas: "Areas",
        areasList: 'Areas List',
        parcels: "Parcels",
        size: "Size",
        area: "Area",
        addArea: 'Add a new Area',
        addParcel: 'Add a new Parcel',
        addSector: 'Add a new Sector',
        stopEditing: 'Stop Editing',
        editArea: "Edit Area",
        removeSite: "Remove site",
        removeParcel: "Remove Parcel",
        selectedArea: "Selected Area",
        addSite: "Add Site",
        editParcel: "Edit Parcel",
        editSector: 'Edit Sector',
        AgricultureArea: "Agriculture Area",
        editSite: "Edit Site",
        siteMap: 'Site Map',
        site: "Site",
        parcel: "Parcel",
        sector: "Sector",
        mapView: 'Map View',
        map: "Map",
        listView: 'List View',



        //
        relay_status: 'Relay status',
        SoilElecConductivity: "Soil electroconductivity",
        SoilTemperature: 'Soil temperature',
        SoilWaterContent: 'Soil volumic water content',
        //SoilMoisture:"Soil humidity",
        finishCycle: 'Terminate',
        finishLabel: 'Finish',
        minLength: 'must have a minimum length of ',
        apiLabel: 'API',
        passwordDntMatch: "password don't match",
        errorHandler: 'Error Management',
        lowLabel: 'Low',
        endCycle: 'End Cycle',
        andLabel:'and',
        confirmationTitle:'Confirmation',
        warningLabel: 'Warning',
        privacyPolicy: 'Privacy Policy',
        creationCycle: 'create a production cycle',
        error_text: 'Error ',
        emailInvalidLabel: 'Email Invalid',
        veryHighLabel: 'Very high',
        windStatus: 'Wind Status',
        noCycleInSite: 'No production cycle is attributed to this site',
        highLabel: 'Hight',
        extremeLabel: 'Extreme',
        sunset: 'Sunrise & Sunset',
        pressureLabel: 'Atmospheric Pressure',
        waterRequirementLabel: 'Water requirement and water balance',
        tasksLabel: 'Tasks',
        noRecordToDisplay: 'No records to display',
        connectedLabel: 'Connected',
        waterNeedHistory: 'Show Water need history',
        rowsLabel: 'rows',
        moderateLabel: 'Moderate',
        notConnectedLabel: 'Disconnected',
        RecommendedProductionCycle: 'Use Recommended Production Cycle',
        customizeProductionCycle: 'Customize your own Production Cycle',
        irrigationMode: 'Irrigation Mode',
        'Phase initiale': 'Initial Stage',
        'Total': 'Total',
        'Autre': 'Other',
        addGroup: 'Add Group',
        allStepsCompleted: 'All steps completed',
        operationSucc: 'Your operation was successful',
        groupLabel: 'Group',
        LatitudeLabel: 'Latitude',
        LongitudeLabel: 'Longitude',
        realTimeData: 'RealTime',
        cycleExist: 'Culture and area with cycle exist',
        yourSiteLabel: 'Your Sites',
        goToLabel: 'Go to',
        groupAdded: 'Group added successfully',
        addedSuccLabel: 'added successfully',
        disableDelete: 'You cannot delete this group',
        deleteMsg: ' Deleted successfully',
        disableEdit: 'You cannot Edit this group',
        showAllGroup: 'All Groups',
        showMyGroup: 'My Groups',
        subscribeSucces: 'You are registered in the group',
        leftGroup: 'You Left the Group',
        errorList: 'Error List',
        serielNumber: 'Serial Number',
        drawZone: 'Draw a zone',
        navigateTo: 'Navigate to',
        placeToSearch: 'Enter a place to search for',
        registerDone: 'Register Done ',
        groupeManagment: 'Groups Mangement',
        editGroup: 'Edit Group',
        subscribe: 'Subscribe',
        leaveGroup: 'leave the group',
        termsConditions: 'Terms and conditions',
        productionCycle: 'Production Cycle',
        noAttachedEquipment: 'No Equipment attached',
        rootingDepth: 'Rooting depth (metre)',
        youCanCreateOne: 'You can create one here',
        addCycle: 'Add Cycle',
        calculatedValue: 'Calculated Value',
        isoUnit: 'Iso Unit',
        subscriptions: 'Subscriptions',
        editCycle: 'Edit Cycle',
        chooseMySoilType: 'Choose my Soil Type',
        soilStructure: 'Soil Structure in the Selected Area',
        irrigationMangement: 'Irrigation Management',
        addIrrigation: 'Add Irrigation',
        editIrrigation: 'Edit Irrigation',
        findIrrigation: 'Find Irrigation',
        addSubscription: 'Add Subscription',
        editSubscription: 'Edit Subscription',
        accessGranted: 'Access Granted!',
        username: 'Username',
        timeLabel: 'Time',
        sousArea: 'sub-areas',
        startTime: 'Start Time',
        endTime: 'End Time',
        originalValue: 'Original value',
        otherInformations: 'Other Information',
        smsPrice: 'SMS Price',
        emailPrice: 'Email Price',
        doubleClickToShowAreaDetail: 'Please double click a displayed area to load details...',
        appPrice: 'Application Price',
        cycleDetails: 'Cycle Details',
        detailsLabel: 'Details',
        valueLabel: 'Value',
        today: 'Today',
        yesterday: 'Yesterday',
        now: 'Now',
        choiceBySite: 'Choice By Site',
        selectAreaToMonitor: 'Select the area you want to monitor',
        filterAdvice: 'Use filters for more precision',
        loading: 'Loading...',
        searchPlace: 'Search Places ...',
        confirmationLabel: 'Are you sure you want to',
        salinity: 'Salinity',
        userList: 'User List',
        resetSelection: 'Reset Selection',
        initialStage: 'Initial stage',
        durationInitialStage: 'Initial stage Duration',
        search: 'Search',
        governates: 'Governates',
        delegations: 'Delegations',
        public: 'Public',
        deployedDevices: 'Deployed Devices',
        numberDeployedDevices: '# of deployed devices',
        searchCountry: 'Search country',
        specifications: 'Specifications',
        searchGovernorate: 'Search  for Governorate',
        private: 'Private',
        choiceByDeployedDevice: 'Choice By Deployed Device',
        nonProfit: 'Non profit',
        developmentPhase: 'Development phase',
        'Phase de developpement': 'Development phase',
        durationDevelopmentPhase: 'Development phase Duration',
        equipmentType: 'Equipment Family',
        addDataType: 'Add Data Type',
        editDataType: 'Edit Data Type',
        equipmentList: 'Equipment Type',
        addEquipmentType: 'Add Equipment Family',
        addEquipment: 'Add Equipment',
        editEquipmentType: 'Edit Equipment Family',
        editEquipment: 'Edit Equipment',
        deviceCategory: 'Device Category',
        deviceModel: 'Device Model',
        equipmentLabel: 'Equipment',
        addGrowthDuration: 'Add growth duration',
        editGrowthDuration: 'Edit growth duration',
        midSeasonPhase: 'Mid-season phase',
        'Phase mi-saison': 'Mid-season phase',
        durationMidSeasonPhase: 'Mid-season phase Duration',
        phaseArriereSaison: 'Late season',
        'Phase arrière-saison': 'Late season',
        durationPhaseArriereSaison: 'Late season Duration',
        efficiency: 'Efficiency',
        deleteCulture: 'Delete Culture',
        addSoilType: 'Add a soil type',
        editSoilType: 'Edit soil type',
        solTexture: 'soil texture',
        addSoilTexture: 'Add soil Texture',
        editSoilTexture: 'Edit soil texture',
        cultureFamily: 'Culture Family',
        addCultureFamily: 'Add Culture Family',
        editCultureFamily: 'Edit Culture Family',
        deleteCultureFamily: 'Delete Culture Family',
        addCulture: 'Add Culture',
        editCulture: 'Edit Culture',
        temperatureApparent: 'Apparent temperature ',
        noDataAvailable: 'No Available Data',
        tomorrow: 'Tomorrow',
        soilSensibilite: 'Soil sensitivity',
        humidite_capacite_au_champ: 'Field capacity',
        humidite_au_point_fletrissement: 'Wilting point',
        addNewUser: 'New User',
        EditUser: 'Edit User',
        modelPackName: 'Model name',
        addModelPack: 'Add Model',
        editModelPack: 'Edit Model',
        editCategory: 'Edit Categorie',
        name_ar: 'Name in Arabic',
        name_fr: 'Name in French',
        name_en: 'Name in English',
        useful_Reserve: 'Useful Reserve',
        eau_utile: 'Usable water',
        waterVolume: 'Irrigation',
        waterNeedOnDemand: 'Water requirement on demand',
        densite_apparente: 'Apparent density',
        defaultCalculation: 'Default calculation',
        metreJour: 'mm/J',
        warningCondition: 'at least one condition must be provided',
        warningAction: 'at least one action must be provided',
        day_number: 'Number of days since implantation',
        add: 'Add',
        dayLabel: 'days',
        hectarMcube: 'm³/hectare',
        operatorLabel: 'Operator',
        Temperature: 'Temperature',
        hectar: 'hectar',
        phase: 'Phase',
        kc: 'KC',
        statistics: 'Statistics',
        emailCheckAccount: 'Please check your email to verify your account !',
        emailCheckPassword: 'Please check your email to reset your password !',
        emailCheckPasswordChanged: 'Password has been successfully changed, Please verify your account',
        checkAccessCode: 'Please check your access codes',
        errorOccurred: 'An error has occurred',
        errorChecked: 'Error checked',
        errorCorrected: 'Error Corrected',
        checkedLabel: 'Checked',
        correctedLabel: 'Corrected',
        siteGateway: 'Site Gateway',
        nextWeekWeather: 'Next Days Weather',
        maximumHeat: 'maximum temperature ',
        minimumHeat: 'minimum temperature ',
        temperature: 'Temperature',
        humidity: 'Humidity rate  ',
        rainPrecipitation: 'Rain Precipitation ',
        snowPrecipitation: 'Snow Precipitation ',
        uvIndex: 'Ultraviolet Index',
        cloudCover: 'Cloud Cover',
        dewPoint: 'Dew Point',
        evapotranspiration: 'reference evapotranspiration',
        cycleDays: 'The number of days since the start of the cycle',
        totalPrecipitation: 'Precipitation',
        waterInSoil: 'Amount water in Soil',
        changePriority: 'Change Priority',
        recommendedDate: 'Recommended Date',
        depth: 'Depth (m)',
        windSpeed: 'Wind speed  ',
        windUnit: 'km/h',
        windSpeedUnit: 'm/s',
        windDirection: 'Wind direction',
        unity: 'Unit',
        mode: 'Mode',
        waterRequirement: 'Real evapotranspiration',
        deviceName: 'Device Name',
        deviceFamily: 'Device Family',
        refreshData: 'Refresh Data',
        scEquation: 'Equation',
        ChooseStatus: 'Choose the status',
        recommendedSoilType: 'Use Recommended Soil Type',
        irrigationType: 'Irrigation Type',
        addIrrigationType: 'Add Irrigation Type',
        editIrrigationType: 'Edit Irrigation Type',
        irrigationFamily: 'Irrigations Family',
        addIrrigationFamily: 'Add Irrigation Family',
        editIrrigationFamily: 'Edit Irrigation Family',
        irrigationQantity: 'quantity to irrigate ',
        soilType: 'Soil Type',
        soilFamily: 'Soil Family',
        chapriority: 'Priority',
        fieldRequired: 'Required',
        manuell: 'Manuel',
        automatique: 'automatic',
        seabexCategoryStation: 'Model Station',

        seabexModelCategory: 'Model Category',
        end_cycle: 'End Production Cycle',
        openCycle: 'Open Cycle',
        cycleStatus: 'In progress',
        sensibilite: 'Sensitivity',
        activesince: "Active Since",
        city: "City",
        country: "Countries",
        home: "Home",
        sites: "Sites",
        stations: "Stations",
        automationPlan: "Automation Plans",
        cycles: "Cycles",
        expenses: "Expenses",
        name: "Station name",
        name_label: 'Name',
        precipitationUnit: 'L/m²',
        reset: "Reset",
        ipv4: "ipv4",
        lastNotifs: 'latest notifications',
        revenues: "Revenues",
        categories: "Categories",
        activityTypes: "Activity Types",
        profile: "Profile",
        settings: "Settings",
        updateStation: 'Edit station',
        updateSucc: 'Updated successfully',
        addedSucc: 'Added successfully',
        logout: "Logout",
        identifier: "Identifier",
        position: "Position",
        type: "Type",
        ok: 'OK',
        Culture_Name: 'Culture Name',
        culture: 'culture',
        sensorType: "Sensor Type",
        model: "Model",
        addNode: "Add Node",
        Settings: "Settings",
        titleLabel: "Title",
        monitoring: 'Monitoring',
        alreadySubscribe: 'You have already subscribed to the group',
        gateway: 'Gateway',
        findEvents: "Find Events",
        newSearch: "New Search",
        reason: "Reason",
        port: "Port",
        start: "Start",
        open: "Open",
        close: "Close",
        NoData: "No Data Available",
        assistedMode: "Assisted Mode",
        automaticMode: "Automatic Mode",
        AutomationPlan: "Automation Plan",
        AssistedAction: "Assisted Mode",
        startAction: "Start Action",
        endAction: "End Action",
        nbRecievedNotifs: 'number of received notifications',
        end: "End",
        datatype: "Datatype Managment",

        confirmDelete: 'Delete confirmation',
        duration: "Duration",
        station: "Station",
        selectGateway: "Select Gateway",
        categoryName: 'Category Name',
        addActivityType: "Add Activity Type",
        color: "Choose The Color",
        FormulaName: "Formula name",
        nextpage: "Next Page",
        lastpage: "Last Page",
        previouspage: "Previous Page",
        firstpage: "First Page",

        eventsList: "Events list",
        Events: "Events",
        title: "Title",
        description: "Description",
        category: "Category",
        addCategory: "Add Category",
        addCategoryModel: 'Add Model',
        editCategoryModel: 'Edit Model',
        addExpense: "Add Expense",
        quantity: "Quantity",
        addRevenue: "Add Revenue",
        forgetPassword: 'Forgot your password? click here.',
        checkYourEmail: 'Check you email address',
        resetPassword: 'Reset password',
        newpassword: 'New password',
        price: "Price",
        total: "Total",
        date: "Date",
        item: "Item",
        itemCategory: "Item Category",
        cycle: "Cycle",
        addMarker: 'Add Marker',
        planning: "Planning",
        automationPlans: "Automation Plans",
        AddAutomationPlan: "Add Automation Plan",
        editAutomationPlan: 'Edit Automation Plan',
        status: "Status",
        addCronJob: "Add Cron Job",
        selectActionNode: "Select Action Node",
        selectActuator: "Select Actuator",
        chooseTheStatus: "Choose The Status",
        from: "From",
        to: "To",
        created_at: "Created on",
        selectedMonths: "Selected Months",
        selectedMonthDays: "Selected Month Days",
        plantType: "Plant Type",
        startDate: "Start Date",
        harvestDate: "Harvest Date",
        newFormula: "new Formula",
        reference: "Reference",
        formules: "Formulas",
        confirmDeleteText: 'Are you sure to delete the station',
        addStation: "Add Station",

        stationEquipments: 'Equipments',

        save: "Save",
        cancel: "Cancel",
        farmers: "Farmers",
        formula: "Formula",
        myformulas: "My formulas",
        allFormulas: "All formulas",
        experts: "Experts",
        viewMore: "View More",
        seeProfile: "See Profile",
        connect: "Connect",
        connectionSuccessful: "Connection Successful",
        connectionFailed: "Connection Failed",
        mobile: "Telephone number",
        address: "Address",
        diploma: "Diploma",
        speciality: "Speciality",
        experience: "Experience",
        region: "Region",
        agree: 'Agree',
        disagree: '',
        skills: "Skills",
        signup: "Sign up",
        firstname: "First name",
        lastname: "Last name",
        email: "Email",
        stationName: 'Device name',
        password: "Password",
        c_password: "Confirm Password",
        Farmer: "Farmer",
        Super: 'Admin',
        Expert: "Expert",
        next: "NEXT",
        previous: 'PREVIOUS',
        signin: "Sign in",
        organization: "Organizations",
        contactOrganization: 'Contact Organization',
        userManagement: 'User Management',
        userSoilManagement: 'Soil Management',
        userCultureManagement: 'Culture Management',
        userGeoDataManagement: 'GeoData Management',
        userEquipmentManagment: 'Equipment Management',
        userCatalogManagement: 'Catalog Management',
        userIrrigationManagement: 'Irrigation Type Management',
        government: "Government",
        activityType: "Activity Type",
        activities: "Activities",
        endDate: "End Date",
        addActivity: "Add Activity",
        required: "this field is required",
        mismatch: "password mismatch",
        enabled: "Enabled",
        disabled: "Disabled",
        freespace: "freespace",
        delete: "Delete",
        editLabel: 'Edit',
        backLabel: 'Back',
        addLabel: 'Add',
        noModelYet: 'No model yet',
        Institution: 'Institute',
        agent: 'Agent',
        Logs: "Logs",
        dataLogs: "Data Logs",
        findLogs: 'Find Logs',
        selectNode: "Select Station",
        findLabel: 'Find',
        nodeType: "Station Type",
        captureTime: "capture Time",
        remove: "Remove",

        lines: "Lines",
        Drippers: "Drippers",
        formulasList: "Formulas List",

        lastPing: "Last Ping",
        Ipv4: "Ipv4",
        freeSpace: "Free Space",
        seabexModelType: "Seabex Model",
        actions: "Actions",
        astationOrAutomation: 'Station/AutomationPlan',
        owner: "Owner",
        weatherStations: "Weather Stations",
        automatic: 'Automatic',
        manuel: 'Manual',
        formulaDisplay: "This is your formula",

        scenarioLabel: "Scenario",
         conditions: "Conditions",
        conditionsList: "Conditions List",
        deviceID: "Device Identifier",
        sensor: "Sensor",
        pin: "PIN",
        operator: "Operator: <>=",
        value: "Value",
        actionsList: "Actions List",
        action: "Action",

        fullName: "Full Name",
        otherInformation: "Other Information",
        realTimeSensorsAndActuators: "Real Time Sensors And Actuators",
        everyMonth: 'Every Month',

        deleteAutomationPlan: "Delete Automation Plan",

        january: 'January',
        february: 'February',
        mars: 'Mars',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        everyMonthDay: 'Every Month Day',
        everyDay: 'Every Day',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        addIrrigationPlan: 'Add Irrigation Plan',
        selectStation: 'Select Station',
        selectStatus: 'Status de départ',
        Actuator: "Actuator",
        seeFormula: "See formula",
        selectedWeekDays: 'Selected Week Days',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
        drippers: "Drippers",
        delay: 'Delay',
        delayInMinute: "Delay in minutes",
        equation: 'Equation',
        AdvicesService: 'Notifications Service',
        unfollow: 'Unfollow',
        followers: 'Followers',
        planification: 'Planning',
        sendingDate: 'Sending date',
        notificationType: 'Notification type',
        SubscriptionType: 'Subscription type',
        memberSince: 'Member since',
        newFollower: 'New follower',
        ville: 'city',
        alert: 'Alert',
        advice: 'Advice',
        sms: 'SMS',
        application: 'Application',
        content: 'Content',
        newNotification: 'New notification',
        nbbeneficiers: 'Check beneficiers number',
        send: 'Send',
        priority: 'Priority',
        priorityLimit: 'From 1 to 50',
        Statistics: 'Statistics',
        agents: 'Agents',
        fullname: 'FullName',
        SentNotifNumber: 'Number of sent notifications',
        newAgent: 'New Agent',
        submit: 'Save',
        organizationName: 'Organization name',
        nbFollowers: 'Number of followers',
        pricePerMonth: 'Price per month/TND',
        newAffiliatedorganization: 'New Affiliated organization',
        editAffiliatedorganization: 'Edit Affiliated organization ',
        enterPhoneNumber: 'Enter phone number',
        requiredPhoneNumber: 'Required phone number',
        invalidPhoneNumber: 'Invalid phone number',
        clientSeabex: 'SEABEX member',
        organizationcity: 'Organization city',
        emailNotValid: 'Invalid email',
        nbFollowersSeabex: 'Number of SEABEX followers',
        others: 'Others',
        sentByAgent: 'Sent by agent',
        SoilMoisture: 'SoilMoisture',
        calculationParam: 'calculation parameter  ',
        Evolution24H: "Today's 24 hour weather forecast",
        WeatherToday: "Weather Today",
        dontHaveAnAccount: "If you don't have an account",
        downloadLabel: 'Download',
        statusCode: 'Code Http',
    },
    fr: {
        relay_status: 'État du relais',
        SoilElecConductivity: "Électroconductivité du sol",
        SoilTemperature: 'Température du sol',
        SoilWaterContent: 'Teneur volumique en eau du sol',
        listView: 'Vue De Liste',
        finishCycle: 'Terminer',
        statusCode: "Code Http",
        lowLabel: 'Faible',
        downloadLabel: 'Télécharger',
        groupLabel: 'Groupe',
        groupAdded: 'Groupe ajouté avec succès',
        dontHaveAnAccount: "Si vous n'avez pas de compte",
        detailsLabel: 'Détails',
        andLabel:'et',
        warningLabel: 'Avertissement',
        endCycle: 'Fin du cycle',
        confirmationTitle:'Confirmation',
        emailInvalidLabel: "Email Invalide",
        passwordDntMatch: 'Le mot de passe ne correspond pas',
        connectedLabel: 'Connecté',
        notConnectedLabel: 'Déconnecté',
        pressureLabel: 'Pression Atmosphérique',
        creationCycle: 'créer un cycle de production',
        highLabel: 'Haut',
        noCycleInSite: 'Aucun cycle de production est attribué a ce site',
        windStatus: 'État du Vent',
        moderateLabel: 'Modérer',
        veryHighLabel: 'Très haut',
        extremeLabel: 'Extrême',
        waterRequirementLabel: 'Besoin en eau et bilan hydrique ',
        RecommendedProductionCycle: 'Utiliser le cycle de production recommandé',
        LatitudeLabel: 'Latitude',
        'Autre': 'Autre',
        sunset: 'Lever et coucher du soleil',
        rowsLabel: 'Lignes',
        waterNeedHistory: "Afficher l'historique des besoins en eau",
        noRecordToDisplay: 'Aucun enregistrement à afficher',
        customizeProductionCycle: 'Personnalisez votre propre cycle de production',
        finishLabel: 'Terminer',
        LongitudeLabel: 'Longitude',
        dayLabel: 'Jours',
        recommendedSoilType: 'Utilisez le type de sol recommandé',
        tasksLabel: 'les tâches',
        errorChecked: "l'Erreur a été vérifiée",
        irrigationMode: "Mode d'irrigation",
        errorCorrected: "l'Erreur a été corrigée",
        checkedLabel: 'vérifié',
        cycleExist: 'La culture et la zone à inserré deja exist',
        realTimeData: 'Données en temps réel -',
        correctedLabel: 'corrigé',
        operationSucc: 'Votre opération a été effectué avec succés',
        disableDelete: 'Vous ne pouvez pas supprimer ce groupe',
        error_text: 'Erreur',
        yourSiteLabel: 'Vos sites',
        backLabel: 'Arrière',
        goToLabel: 'Aller à',
        drawZone: 'Dessiner une zone',
        registerDone: 'Inscription terminée',
        alreadySubscribe: 'Vous êtes  deja inscrit au groupe ',
        deleteMsg: 'Supprimé avec succès',
        noAttachedEquipment: 'Aucun Équipement attaché',
        updateSucc: 'Mis à jour avec succés',
        addedSucc: 'Ajout avec succès',
        waterNeedOnDemand: 'Besoin en eau à la demande',
        deviceFamily: "Famille d'appareils",
        deviceModel: "Modèle d'appareil",
        disableEdit: 'Vous ne pouvez pas modifier ce groupe',
        addGroup: 'Ajouter un Groupe',
        showAllGroup: 'Tous les groupes',
        showMyGroup: 'Mes groupes',
        subscribeSucces: 'Vous êtes inscrit au groupe',
        leaveGroup: 'Quitter le groupe',
        leftGroup: 'Vous avez quitter le  groupe',

        editGroup: 'Modifier un Groupe',
        minLength: 'doit avoir une longueur minimale de ',
        privacyPolicy: 'Politique de confidentialité',
        addCycle: 'Ajouter Cycle',
        errorList: 'Liste des erreurs',
        navigateTo: 'Aller vers',
        placeToSearch: 'Entrez un lieu à rechercher',
        subscribe: "S'Abonner",

        groupeManagment: 'Gérer des groupes',
        youCanCreateOne: 'vous pouvez en créer un ici',
        errorHandler: 'Gestion des erreur',
        WeatherToday: "Météo d'aujourd'hui",
        addCategoryModel: 'Ajouter Model',
        'Total': 'Totale',
        termsConditions: 'Termes et conditions',
        editCategoryModel: 'Modifer Model',
        Evolution24H: "Prévisions météo actuelles sur 24 heures",
        serielNumber: 'numéro de série',
        smsPrice: 'Le prix du SMS',
        isoUnit: 'Iso Unit',
        mapView: 'vue de la carte',
        refreshData: 'Actualiser les données',
        irrigationMangement: "Gestion d'Irrigation",
        soilStructure: 'Structure du sol dans la zone sélectionnée',
        chooseMySoilType: 'Choisissez mon type de sol',
        findIrrigation: 'Trouver Irrigation',
        addIrrigation: 'Ajouter Irrigation',
        editIrrigation: "Modifier l'Irrigatoin",
        calculatedValue: 'Valeur calculée',
        editSubscription: "Modifier l'Abonnement",
        appPrice: "Le prix de l'Application",
        apiLabel: 'API',
        startTime: "Le Temps de début",
        endTime: "Temps de Fin",
        sousArea: 'sous-zones',
        doubleClickToShowAreaDetail: 'Veuillez double-cliquer sur une zone affichée pour charger les détails ...',
        otherInformations: 'Autres Renseignements',
        siteMap: 'La carte du Site',
        waterVolume: "Irrigation",
        cycleDetails: 'Détails du cycle',
        deleteCulture: 'Supprimer la Culture',
        emailPrice: "Le prix du l'Email",
        cycleStatus: 'En cours',
        choiceBySite: 'Choix par Site ',
        originalValue: "Valeur oroginal",
        choiceByDeployedDevice: 'Choix Par Appareil Déployé',
        editCycle: 'Modifier Cycle',
        addSubscription: 'Ajouter un Abonnement',
        editSubcription: 'Modifier un Abonnement ',
        subscriptions: 'Abonnements',
        rootingDepth: "Profondeur d'enracinement (mètre)",
        productionCycle: 'Cycle de Production',
        accessGranted: 'Accès Autorisé !',
        username: "Nom d'utilisateur",
        astationOrAutomation: 'Station/AutomationPlan',
        timeLabel: 'Temps',
        valueLabel: 'Valeur',
        today: "Aujourd'hui",
        delegations: 'Délégations',
        noDataAvailable: 'Pas de données disponibles',
        yesterday: 'Hier',
        searchPlace: 'Rechercher des lieux...',
        public: 'Publique',
        private: 'Privée',
        resetSelection: 'réinitialiser la sélection',
        selectAreaToMonitor: 'Sélectionnez la zone que vous souhaitez surveiller',
        filterAdvice: 'Utilisez des filtres pour plus de précision',
        loading: 'Chargement...',
        confirmationLabel: 'Êtes-vous sûr de ',
        searchCountry: 'Recherche pays',
        searchGovernorate: 'rechercher gouvernorat',
        search: 'Rechercher',
        specifications: 'Caractéristiques',
        governates: 'Gouvernorats',
        areasList: 'Liste des zones',
        nonProfit: 'non lucratif',
        selectGateway: 'sélectionnez Gateway',
        defaultCalculation: 'Calcul par défaut',
        equipmentType: "Type d'équipement",
        equipmentList: "Liste des types d'équipement",
        addDataType: 'Ajouter un Type de données',
        editDataType: 'Modifier le type de données',
        addEquipment: 'Ajouter un équipement',
        addEquipmentType: "Ajouter un type d'équipement",
        editEquipmentType: "Modifier un type d'équipement",
        editEquipment: "Modifier un équipement",
        equipmentLabel: 'Equipment',
        salinity: 'Salinité',
        modelPackName: 'Nom du Modéle',
        addModelPack: 'Ajouter un Modéle',
        editModelPack: 'Modifier un Modéle',
        addSoilType: 'Ajouter un type de sol',
        editSoilType: 'Modifier le type de sol',
        'Phase initiale': 'Phase initiale',
        initialStage: 'Phase initiale',
        durationInitialStage: 'La Durée de la Phase initiale',
        developmentPhase: 'Phase de developpement',
        'Phase de developpement': 'Phase de developpement',
        durationDevelopmentPhase: 'La Durée de la Phase de developpement',
        midSeasonPhase: 'Phase mi-saison',
        'Phase mi-saison': 'Phase mi-saison',
        durationMidSeasonPhase: ' La Durée de la Phase mi-saison',
        phaseArriereSaison: 'Phase arrière-saison',
        'Phase arrière-saison': 'Phase arrière-saison',
        durationPhaseArriereSaison: 'La Durée de la Phase arrière-saison',
        now: 'maintenant',
        addSoilTexture: 'Ajouter la texture du sol',
        editSoilTexture: 'Modifier la texture du sol',
        cultureFamily: 'Famille du Culture',
        addCultureFamily: 'Ajouter famille de culture',
        editCultureFamily: 'Modifier famille de culture',
        deleteCultureFamily: 'Supprimer Famille de Culture',
        addCulture: 'Ajouter une culture',
        editCulture: 'Modifier une culture',
        solTexture: 'Texture du sol',
        metreJour: 'mm/J',
        sensibilite: 'Sensibilité',
        humidite_capacite_au_champ: 'Capacité au champ',
        addNewUser: 'Nouvel utilisateur',
        userList: 'Liste des Utilisateurs',
        humidite_au_point_fletrissement: `Point de flétrissement`,
        Super: 'Administrateur',
        name_ar: 'Nom en Arabe',
        name_fr: 'Nom en Français',
        name_en: 'Nom en Anglais',
        editCategory: 'Modifier Catégorie',
        densite_apparente: 'Densité apparente',
        useful_Reserve: 'Réserve Utilisable',
        eau_utile: 'Eau utilisable',
        userManagement: "Gestion d'utilisateur",
        userSoilManagement: 'Gestion des sols',
        userCultureManagement: 'Gestion des cultures',
        userGeoDataManagement: 'Gestion des données géographiques',
        userEquipmentManagment: 'Gestion des équipements',
        userCatalogManagement: 'Gestion des catalogue',
        userIrrigationManagement: "gestion du type d'irrigation",
        tomorrow: 'Demain',
        hectarMcube: 'm³/hectare',
        Culture_Name: 'Nom de culture',
        name_label: 'Nom',
        Culture: 'culture',
        statistics: 'Statistiques',
        depth: 'profondeur(m)',
        windUnit: 'km / h',
        windSpeedUnit: 'm/s',
        precipitationUnit: 'L/m²',
        nextWeekWeather: 'météo des prochains jours ',
        maximumHeat: 'Temperature maximale ',
        minimumHeat: 'température minimale',
        temperature: 'Température ',
        temperatureApparent: 'température apparente ',
        operatorLabel: 'Operateur',
        selectParcel: 'Sélectionnez la passerelle',
        Temperature: 'Temperature',
        hectar: 'hectar',
        home: "Accueil",
        addGrowthDuration: 'Ajouter une durée de croissance',
        editGrowthDuration: 'Modifier la durée de croissance',
        emailCheckAccount: 'Veuillez vérifier votre e-mail pour vérifier votre compte !',
        emailCheckPassword: 'Veuillez vérifier votre e-mail pour réinitialiser votre mot de passe ',
        emailCheckPasswordChanged: ' Mot de passe a était changé avec succès, Veuillez vérifier  votre compte',
        checkAccessCode: "Veuilliez verifier vos codes d'accès ",
        errorOccurred: 'Une erreur est survenue',
        humidity: 'Taux humidité',
        rainPrecipitation: 'Précipitations de pluie ',
        snowPrecipitation: 'Précipitations de neige ',
        uvIndex: 'Indice Ultraviolet',
        phase: 'Phase',
        siteGateway: 'passerelle de site',
        dewPoint: 'point de rosée ',
        cloudCover: 'couverture nuageuse ',
        calculationParam: 'parametre de calcul',
        evapotranspiration: 'Èvapotranspiration de référence',
        cycleDays: 'Le nombre de jours depuis le début du cycle',
        totalPrecipitation: 'Précipitations ',
        kc: 'KC',
        waterInSoil: "Quantité d'eau dans le sol",
        delay: 'délai',
        windSpeed: 'Vitesse du vent ',
        windDirection: 'direction du vent',
        waterRequirement: 'Evapotranspiration Réelle',
        deviceName: "nom de l'appareil",
        editParcel: 'Modifier la Parcel',
        editSector: 'Modifier le Secteur',
        mode: 'Mode',
        unity: 'Unité',
        ChooseStatus: 'Choisissez le statut',
        scEquation: 'équation',
        irrigationFamily: "Famille d'irrigation",
        irrigationType: "Type d'irrigation",
        addIrrigationType: 'Ajouter Irrigation Type',
        editIrrigationType: 'Modifier Irrigation Type',
        addIrrigationFamily: "Ajouter Famille d'irrigation",
        editIrrigationFamily: "Modifier Famille d'irrigation",
        efficiency: 'Efficacité',
        irrigationQantity: 'Quantité a irriguer',
        soilType: 'Type de sol',
        soilFamily: 'Famille de sol',
        changePriority: 'Changer La Priorité',
        chapriority: 'Priorité',
        fieldRequired: 'Champs obligatoires ',
        manuell: 'Manuelle',
        automatique: 'Automatique',
        recommendedDate: 'Date de fin du cycle recommandée',
        end_cycle: 'Fin du cycle de production',
        openCycle: 'Ouvrir Cycle ',
        day_number: "Nombre de jours depuis l'implantation",
        warningCondition: 'au moins une condition doit être fournie',
        warningAction: 'au moins une action doit être fournie ',
        add: 'Ajouter',
        SoilMoisture: 'Humidité du sol',
        sector: "Secteur",
        activesince: "Actif depuis",
        city: "Ville",
        country: "Pays",
        soilSensibilite: 'sensibilité du sol',
        seabexCategoryStation: 'catégorie du station',
        seabexModelCategory: ' catégorie du model',
        editLabel: 'Modifier',
        mismatch: "confirmation ne correspond pas",
        farmers: "Fermiers",
        experts: "Experts",
        viewMore: "Voir Plus",
        seeProfile: "Voir Profil",
        connect: "Se Connecter",
        connectionSuccessful: "La connexion a réussi",
        connectionFailed: "La connexion a échoué",
        mobile: "Numéro de télèphone",
        address: "Adresse",
        diploma: "Diplôme",
        speciality: "Spécialité",
        experience: "Expérience",
        nbRecievedNotifs: 'nombre de notifications reçues',
        name: "Nom du station",
        ipv4: "ipv4",
        FormulaName: "Nom de la formule",
        region: "Région",
        skills: "Les compétences",
        signup: "S'inscrire",
        stationEquipments: 'les équipements',
        required: "ce champ est obligatoire",
        firstname: "Nom",
        lastname: "Prénom",
        email: "Email",
        password: "Mot de passe",
        c_password: "Confirmation du mot de passe",
        Farmer: "Fermier",
        Expert: "Expert",
        next: "SUIVANT",
        lastNotifs: 'dernières notifications',
        selectedArea: "Zone sélectionnée",
        datatype: "Types de données",
        signin: "Se connecter",
        organization: "Organisations",
        government: "Gouvernement",
        sites: "Sites",
        parcelsNumber: "Nombre de parcels",
        area: "Région",
        findEvents: "Trouver des événements",
        reason: "Raison",
        port: "Port",
        start: "Début",
        startAction: "Action début",
        endAction: "Action de fin",
        end: "Fin",
        NoData: "pas de données disponibles",
        monitoring: 'Surveillance',
        gateway: 'Passerelle',
        noModelYet: 'Pas encore de modèle',
        assistedMode: "mode assisté",
        automaticMode: "Mode automatique",
        AutomationPlan: "Plan d'irrigation",
        AssistedAction: "Action Assistée",
        open: "Ouverture",
        close: "Fermeture",
        ScenarioLabel: 'Scenario',
        duration: "Durée",
        station: "Station",
        deployedDevices: 'Appareils déployés',
        numberDeployedDevices: "Nombre d'appareils déployés",
        myformulas: "Mes formules",
        allFormulas: "Toutes les formules",
        SelectParcel: "Sélectionnez la passerelle",
        save: "Sauvegarder",
        formulaDisplay: "C'est ta formule",
        ok: 'OK',
        created_at: "Créé le",
        seeFormula: "Voir formule",
        cancel: "Annuler",
        parcels: "Parcelles",
        size: "Superficie",
        newSearch: 'Nouvelle recherche',
        areas: "Surfaces",
        stations: "Stations",
        identifier: "Identifiant",
        reference: "Référence",
        titleLabel: "Titre",
        site: "Site",
        parcel: "Parcelle",
        type: "Type",
        updateStation: 'Modifier station',
        deviceCategory: "Catégorie d'appareil",
        addStation: "Ajouter Station",
        automationPlans: "Plans d'Automatisation",
        AddAutomationPlan: "Ajouter Plan d'Automatisation",
        editAutomationPlan: "Modifier Plan d'Automatisation",
        addMarker: 'Ajouter un marqueur',
        title: "Titre",
        description: "Description",
        status: "Etat",
        addCronJob: "Ajouter un Cron Job",
        selectActionNode: "Sélectionner la station d'action",
        selectActuator: "Sélectionner le port",
        nodeType: "Type de Station",
        chooseTheStatus: "Choisir l'état",
        from: "De",
        confirmDelete: 'Confirmation de suppression',
        confirmDeleteText: 'Etes-vous sûr de supprimer la station',
        to: "A",
        stationName: 'Nom de la dispositif',
        Logs: "Journaux",
        findLogs: 'trouver Logs',
        plantType: "Type de Plante",
        startDate: "Date de début",
        harvestDate: "Date de récolte",
        endDate: "Date de fin",
        cycles: "Cycles",
        culture: "Culture",
        expenses: "Dépenses",
        addExpense: "Ajouter des dépenses",
        reset: "Réinitialiser",
        allStepsCompleted: 'Toutes les étapes terminées',
        total: "Total",
        quantity: "Quantité",
        item: "Article",
        planning: "Planification",
        revenues: "Revenues",
        addRevenue: "Ajouter Revenue",
        EditUser: 'Modifier un Utilisateur',
        price: "Prix",
        date: "Date",
        itemCategory: "Catégorie d'article",
        cycle: "Cycle",
        category: "Catégorie",
        addCategory: "Ajouter Catégorie",
        categories: "Catégories",
        categoryName: 'Nom de la Catégorie',
        addActivityType: "Ajouter un Type d'activité",
        activityTypes: "Type d'activité",
        color: "Choisir la Couleur",
        activityType: "Type d'acctivité",
        activities: "Activités",
        addActivity: "Ajouter Activité",
        position: "Position",
        addArea: 'Ajouter une nouvelle zone',
        addParcel: 'Ajouter une nouvelle parcelle',
        stopEditing: "Arrêter l'édition",
        forgetPassword: 'Mot de passe oublié? cliquez-ici.',
        resetPassword: 'réinitialiser le mot de passe',
        checkYourEmail: 'Vérifier votre boite mail',
        newpassword: 'Nouveau mot de passe',
        sensorType: "Type de Capteur",
        model: "Modèle",
        addNode: "Ajouter Noeud",
        Settings: "Paramètres",
        automationPlan: "Plans d'irrigation",
        settings: "Paramètres",
        logout: "Se déconnecter",
        enabled: "Activé",
        disabled: "Désactivé",
        freespace: "espace libre",
        delete: "Supprimer",
        formula: "Formule",
        owner: "propriétaire",
        nextpage: "Page suivante",
        lastpage: "Dernière page",
        previouspage: "Page précédente",
        firstpage: "Première page",

        eventsList: "Liste des événements",
        Events: "Événements",
        dataLogs: "Historique de données",
        selectNode: "Sélectionner une Station",
        captureTime: "Temps de capture",
        automatic: 'Automatique',
        manuel: 'Manual',
        editArea: "Modifier la zone ",
        remove: "Supprimer",
        removeSite: "Supprimer le site",
        removeParcel: "Supprimer la parcelle ",
        addSite: "Ajouter un Site",
        addSector: "Ajouter un secteur",
        lines: "Les lignes",
        Drippers: "les goutteurs",
        agree: 'Accepter',
        disagree: "N'accepte pas",
        lastPing: "Dernier Ping",
        Ipv4: "Ipv4",
        freeSpace: "Espace libre",
        seabexModelType: "modèle Seabex",
        actions: "Actions",
        weatherStations: "Stations Meteos",
        realTimeSensorsAndActuators: "Capteurs temps réel et Monitoring",
        AgricultureArea: "Les Zones Agricoles",
        editSite: "Modifier Site",
        scenarioLabel: "Les règles d'automatisation",
        conditions: "Les conditions",
        conditionsList: "Liste des conditions",
        deviceID: "Identifiant de l'appareil",
        sensor: "Capteur",
        pin: "localisation",
        operator: "Facteur:<>=",
        value: "Valeur",
        actionsList: "Liste Des Actions",
        action: 'Action',

        profile: "Profile",
        fullName: "Nom Complet",
        otherInformation: "Autres renseignements",
        deleteAutomationPlan: "Delete Automation Plan",

        january: 'Janvier',
        february: 'Fevrier',
        mars: 'Mars',
        april: 'Avril',
        may: 'May',
        june: 'Juin',
        july: 'Juillet',
        august: 'Aout',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Decembre',
        everyMonthDay: 'Chaque Jour du Mois ',
        everyDay: 'Chaque Jour',
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
        addIrrigationPlan: 'Ajouter Un Plan',
        selectStation: 'Selectionner la Station',
        selectStatus: 'Début  le Statut',
        selectedMonths: 'Les Mois séléctionné',
        selectedMonthDays: 'Les jours du mois séléctionnés',
        selectedWeekDays: 'Les jours de la semaine séléctionnés ',
        confirmBtn: 'Confirmer',
        cancelBtn: 'Annuler',
        drippers: "Drippers",
        delayInMinute: "Délai en minutes",
        equation: 'Equation',
        formules: "Formules",
        formulasList: "Liste des formules",
        newFormula: "nouvelle formule",
        AdvicesService: "Service de notifications",
        followers: 'Abonnés',
        planification: 'Planification',
        sendingDate: "Date d'envoi",
        notificationType: 'Type notification',
        SubscriptionType: "Type d'abonnement",
        memberSince: 'Membre depuis',
        newFollower: 'Nouveau abonnée',
        ville: 'Ville',
        alert: 'Alerte',
        advice: 'Conseil',
        sms: 'SMS',
        application: 'Application',
        content: 'Contenu',
        newNotification: 'Nouvelle notification',
        nbbeneficiers: 'Vérifiez le nombre de bienfaiteurs',
        send: 'Envoyer',
        unfollow: 'Désabonner',
        priority: 'Priorité',
        priorityLimit: 'De 1 à 50',
        Statistics: 'Statistiques',
        agents: 'Agents',
        fullname: 'Nom',
        SentNotifNumber: 'Nombre de notifications envoyées',
        newAgent: 'Nouvel agent',
        Institution: 'Institut',
        agent: 'Agent',
        submit: 'Enregistrer',
        organizationName: "Nom de l'organisme",
        nbFollowers: "Nombre d'abonnés",
        pricePerMonth: 'Prix par mois/TND',
        newAffiliatedorganization: 'Nouvelle organisation affiliée',
        editAffiliatedorganization: 'modifier organisation affiliée',
        enterPhoneNumber: 'Entrer votre numéro de téléphone',
        requiredPhoneNumber: 'numéro de téléphone requis',
        invalidPhoneNumber: 'Numéro de téléphone invalide',
        clientSeabex: 'Membre SEABEX',
        organizationcity: "Ville de l'organisme",
        emailNotValid: 'Email non valid',
        nbFollowersSeabex: 'Membres SEABEX',
        others: 'Autres',
        sentByAgent: "Envoyé par l'agent",
        previous: 'précédent',
        contactOrganization: "Contacter l'Organization",
        map: "Carte",
    },

});
*/

