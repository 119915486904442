/* eslint-disable*/

import * as types from '../actions/actionTypes';
import { createCookie, eraseCookie } from '../utils';
import { removeCookie, setCookie } from 'tiny-cookie'


const appReducer = (state = {
    isDrawerOpen: false,
    // i used the same var alert.show for displaying errror or sucess message so to hide the popup show value must be null
    alert: { show: null, message: '' },
    appLoading: false,
    userid: '',
    username: '',
    userrights: '',
    company_name: '',
    token: null,
    impersonate: undefined,
    organizations: [],
    currentPage: '',
    currentLanguage: 'en',
    // config: {
    //     // dateFormat: 'DD/MM/YYYY',
    //     // clientId: '6xhD55bBYls1YlawMiYREhsGGSyw',
    //     url: types.GLOBAL_URL + '/api',
    // },
    disconnect: false,
    profile: null,
    LeafletFgRef: null,
    registredAt: null

}, action = null) => {

    switch (action.type) {
        case types.TOGGLE_DRAWER:
            return { ...state, isDrawerOpen: action.payload };
        case types.APP_LOADING:
            return { ...state, appLoading: action.payload };
        case types.TOGGLE_ALERT:
            return { ...state, alert: { show: action.etat, message: action.payload } };
        case types.USER_TYPE:
            return { ...state, userrights: action.payload };


        case 'profile':
            return { ...state, profile: action.payload };

        case 'disconnect':
            localStorage.setItem('login_Token', null)
            localStorage.removeItem('login_Token')
            localStorage.removeItem('userid')
            localStorage.removeItem('userrights')
            localStorage.removeItem('company_name')
            localStorage.removeItem('username')


            return { ...state, login_Token: null, userid: null, userrights: null, company_name: null, username: null, currentLanguage: null, disconnect: true }

        case 'app_session' + types.CREATE_ITEM:
            // we are calling this  reducer from many action (login, singup, setToken) !!!!!

            if (action.iserror) { return state; }

            if (action.payload.token) {
                localStorage.setItem('login_Token', action.payload.token);
            } else {
                localStorage.removeItem('login_Token')
                localStorage.removeItem('userid')
                localStorage.removeItem('userrights')
                localStorage.removeItem('company_name')
                localStorage.removeItem('username')
                return { ...state, login_Token: null, userid: null, userrights: null, company_name: null, username: null, currentLanguage: null, disconnect: true }
            }
            if (action.payload.userid) {
                localStorage.setItem('userid', action.payload.userid);
            }
            // if (action.payload.created_at) {             
            //     localStorage.setItem('created_at', JSON.stringify(action.payload.created_at));
            // }
            if (action.payload.user_type) {
                localStorage.setItem('userrights', action.payload.user_type);
            }
            if (action.payload.company_name) {
                localStorage.setItem('company_name', action.payload.company_name);
            }
            if (action.payload.username) {
                localStorage.setItem('username', action.payload.username);
            }
            if (action.payload.currentLanguage) {
                localStorage.setItem('preferred_language', action.payload.currentLanguage);
            }

            // }

            return {
                ...state,
                token: action.payload.token ?? null,
                // we are calling this  reducer from many action (login, singup, setToken) setToken is not sending userid
                userid: action.payload.userid ?? null,
                registredAt: action.payload.created_at ? action.payload.created_at['date'] : null,
                username: action.payload.username,
                // userrights: (action.payload.userrights) ? action.payload.userrights.split(',') : [],
                created_at: action.payload.created_at,
                userrights: action.payload.user_type,
                company_name: action.payload.company_name,
                disconnect: false

            };


        case 'app_session' + 'set_userid':
            return {
                ...state,
                userid: action.payload.userid,
            };

        case 'app_session' + types.DELETE_ITEM:
            if (action.iserror) { return state; }
            eraseCookie('login_Token');
            eraseCookie('userid');
            return { ...state, token: '', userid: '', username: '', userrights: '' };
        case 'SET_CURRENTPAGE':
            return { ...state, currentPage: action.payload };
        case 'SET_CURRENTLANGUAGE':
            localStorage.setItem('currentLanguage', JSON.stringify(action.payload));
            return {
                ...state,
                currentLanguage: action.payload,


            };

        case 'LeafletFgRef':
            return { ...state, LeafletFgRef: action.payload };

        case state.n + types.GET_ITEMS:
            return { ...state, organizations: action.payload }
        case types.START_IMPERSONATING:
            return {
                ...state,
                impersonate: {
                    ...state.impersonate,
                    ...action.payload.impersonate
                },
            };
        case types.LEAVE_IMPERSONATING:
            return {
                ...state,
                impersonate: undefined,
            };
        default:
            return state;

    }

}

export default appReducer