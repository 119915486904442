import React from "react";

export default (props) => {

    const {children, style,  ...properties} = props

    const styles = {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#efefef",
        minHeight: "100vh",
        ...style
    }

    return (
        <div {...properties} style={styles}>
            {children}
        </div>
    )

}

