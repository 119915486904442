import * as types from '../actions/actionTypes';
// import { momentizeDates } from '../utils';
var _ = require('lodash');
export default function (state = {
    n: 'dashboard',
    data: [],
    localNodeList: [],
    indexRowsToUpdate: [],
    activeItem: null,
    error: null,
    dataKeys: [],
    min:null,
    max:null,
    currentPage:0,
    stationGateway: [],
}, action = null) {
    switch (action.type) {
        case state.n + types.GET_ITEMS:
            let dataKeys = []
            let min = ''
            let max = ''
            action.payload=action.payload.FiltredData
            if (action.payload[0]) {
                // ***this treatment is for aquisition node logs
                if (action.payload[0].sensor) {

                    dataKeys.push(action.payload[0].datatype);
                    

                    for (let index = 0; index < action.payload.length; index++) {
                        for (let i = 0; i < dataKeys.length; i++) {

                            action.payload[index][dataKeys[i]] = action.payload[index].value 
                        }
                    }
                    
                    max = _.maxBy(action.payload,function (o) {
                        return _.toNumber(o.value);
                    });
                    min = _.minBy(action.payload,function (o) {
                        return _.toNumber(o.value);
                    });
                    var maxValue;
                    var minValue;
                    if (max!=null && min!=null) {
                        maxValue = _.toNumber(max.value) + (_.toNumber(max.value)-_.toNumber(min.value))*0.1;
                        minValue = _.toNumber(min.value) - (_.toNumber(max.value)-_.toNumber(min.value))*0.1;
                    } else {
                        maxValue = 100;
                        minValue = 0;    
                    }

                    

                }


                // ***this treatment is for action node logs
                else {

                    for (let i = 0; i < action.payload.length; i++) {

                        for (let j = 0; j < action.payload[i].data.length; j++) {
                            action.payload[i]["Port " + action.payload[i].data[j].Port] = action.payload[i].data[j].Status === "1" ? "On" : "Off";
                            if (i === 0) {
                                dataKeys.push("Port " + action.payload[i].data[j].Port)
                            }

                        }
                    };
                }


            }
            // dataKeys is an array of costum colones 
            return { ...state, data: action.payload, error: action.iserror, dataKeys: dataKeys, min: minValue, max:maxValue };
        case state.n + types.SELECT_ITEM:
            return { ...state, activeItem: action.payload };
        case state.n + types.CREATE_ITEM:
            // we are dispatching to this reducer in the two cases sucess and error (look crud postData)
            if (action.payload) {
                // state.data.pop();
                if (action.payload.title)
                    action.payload.alphabetic = action.payload.title.charAt(0).toUpperCase();
                state.data.push(action.payload);
            }
            else {
                state.data.pop();
            }
            return state;
        case state.n + types.UPDATE_ITEM:
            if (action.payload.title)
                action.payload.alphabetic = action.payload.title.charAt(0).toUpperCase();
            let s1 = { ...state, data: state.data.map(i => i.id === action.id ? action.payload : i) };
            state.data.map(i => i.id === action.id ? action.payload : i)
            return { ...s1, activeItem: action.payload, indexRowsToUpdate: [] };
        case state.n + types.DELETE_ITEM:
            return { ...state, data: state.data.filter(i => i.id !== action.id) };
        case state.n + types.UPDATE_DATA:
            return { ...state, data: action.payload };

        case state.n + "GET_LOCAL_NODE":
            return { ...state, localNodeList: action.payload };

        case state.n +"GET_STATION_GATEWAY":
            return {...state, stationGateway: action.payload}
        default:
            return state;
    }
}