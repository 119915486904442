import React from "react";
import logo from './SeabexWelcomeScreenByCa.png';

export default (props) => {

    const {styles, ...properties} = props

    let style= {
        margin: 8,
        ...styles,
    }

    return (
        <img {...properties} src={logo} style={style} alt="Seabex By CA" />
    )

}
