import React from 'react'
import { Close } from '@material-ui/icons/';
import { Typography, IconButton } from '@material-ui/core';

const StyledModalHeader = React.forwardRef((props, ref) => {

    const {title, styles, className, direction='ltr', onClickFn} = props

    let style = {...styles, 
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 16,
                    borderBottom: `solid 1px lightgrey`,
                    position: 'relative'
                }

    return (
            <div style={style} ref={ref} className={className}>
                <Typography variant="h5" style={{alignSelf:'flex-start', marginInlineEnd:40}}>{title}</Typography>
                <IconButton style={{ position:'absolute', 
                            top:4, 
                            margin:4,
                            right:(direction==="ltr" ? 4 : undefined), 
                            left:(direction==="ltr" ? undefined : 4) }} onClick={onClickFn} >
                    <Close />
                </IconButton>
            </div>
    )
})


export default StyledModalHeader

