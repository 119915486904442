// import { auto } from "async";

import { makeStyles } from "@material-ui/styles";
import "@fontsource/roboto-condensed";
// import { useTheme } from "@material-ui/core";

const drawerWidth = 300;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// const theme = useTheme();
export const stylesDashboard = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& svg': {
      color: 'white'
    },
    '& hr': {
      backgroundColor: '#303030'
    },
    '& a:hover': {
      '& div': {
        color: 'white',
      },
      '& svg': {
        fill: 'white'
      },
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
   // display: 'flex',
    alignItems: 'center',
   // justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarImg: {
     display: 'flex',
     alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
   // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 64
  },

  scrollbars: {
    overflow:'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarColor: '#D62929',
      scrollbarWidth:10,
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        backgroundColor: 'rgba(200,200,200,.5)',
        outline: '1px solid slategrey',
        borderRadius:6,
        height:30
      }
    }
    // '--webkit-scrollbar-track': `{  
    //   --webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    //   border-radius: 10px; 
    //   background-color: #F5F5F5
    // }`,

    // '--webkit-scrollbar': `{
    //     width: 12;
    //     background-color: '#F5F5F5'
    // }`,

    // '--webkit-scrollbar-thumb':`{
    //     border-radius: 10;
    //     --webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    //     background-color: #D62929
    // }`
  }
}), {index: 1});

export const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
    // paddingTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-around',

  },
  button: {
    margin: theme.spacing(1),
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
    color: "red",
  },

  extendedIconDisabled: {
    marginRight: theme.spacing(1),
    color: "grey",
  },
  grid: {
    // width: 'auto',
    flexGrow: 1,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  grow: {
    flexGrow: 1,
  },

  displayFlex: {
    display:'flex',
  },
  
  flexAndGrow: {
    display:'flex',
    flexGrow: 1,
  },

  chip: {
    margin: theme.spacing(1),
  },

  // DIV CONTAINER

  container: {
    flexGrow: 1,
    backgroundColor: '#DDD',
    display: 'flex',
    // flexWrap: 'wrap',

  },

  // PAPER

  paper: {
    margin: '16px',
    flexGrow: 1,
  },

  // PAGINATION

  paginationIcon: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },

  // FAB BUTTON

  fab: {
    margin: 0,
    // top: 'auto',
    right: 20,
    bottom: 20,
    // left: 'auto',
    position: 'fixed',
  },



  menuButton: {
    justifyContent: 'flex-start',
    border:'1px solid red'
  },

  Hidden: {
    display: 'none',
  },

  title: {
    flexGrow: 1,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerClose: {
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    flexShrink: 0,
  },



  content: {
    flexGrow: 1,
    marginTop: 64,
    // marginTop:3,
    padding: theme.spacing(3),
    height: '105vh',
    // overflow: 'auto',
  },

  chartContainer:     { marginLeft: -22,                      },
  tableContainer:     { height: 320,                          },
  h5:                 { marginBottom: theme.spacing(2),       },

  // COLOR STYLES 
  colorMonitoring:    { borderColor: "#7B1FA2",               },
  monitoringTextColor:{ color:"#7B1FA2"                       },

  colorGateway:       { borderColor: "#3f51b4",               },
  gatewayTextColor:   { color: "#3f51b4",                     },
  colorAssistedAction:{ borderColor: "#e30425",               },
  assistedTextColor:  { color:"#e30425", fontWeight:'bold'    },

  colorAutomationplan:{ backgroundColor: "#50BB22",           },

  colorAutoMode:      { backgroundColor: "#696969",           },
  colorEroorType:     { borderColor: "#c62828",               },
  textErrorColor:     { color:"#c62828",fontWeight:'bold'     },

  colorLockOpenType:  { borderColor: "#43a047",               },
  textLockOpenColor:  { color:"#43a047", fontWeight:'bold'    },

  colorConnectType:   { borderColor: "#03465b",               },
  textConnectColor:   { color:"#03465b", fontWeight:'bold'    },

  colorCommandType:   { borderColor: "#3f51b5",               },
  textCommandColor:   { color:"#3f51b5", fontWeight:'bold'    },

  colorExcutionType:  { borderColor: "#1b5e20",               },
  textExcutionColor:  { color:"#1b5e20", fontWeight:'bold'    },

  colorAutomationPlanExecution: { borderColor: "#03465b",     },
  textAutomationPlanExecution:  { color:"#03465b", fontWeight:'bold'  },

  colorAction:        { backgroundColor: "#388E3C",           },
  colorTextChips:     { color: "#FFFFFF",                     },

  MenuProps : {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,

  },

  chipSite :          { backgroundColor: '#C2185B'            },
  chipParcel :        { backgroundColor: '#009688'            },
  chipSector :        { backgroundColor: '#795548'            },
  chipBlue :          { backgroundColor: '#2196f3', color: 'white', fontSize: 12 },
  chipRed :           { backgroundColor: '#b71c1c', color: 'white', fontSize: 12 },
  ValueTable:         { fontSize:12,                          },
  ValueTableCell:     { padding: 6, lineHeight: 'normal',     },

  // MONITORING PAGE SECTION

  openStatusChip:     { color: "green", borderColor: "green" },
  closeStatusChip:    { color: "red" , borderColor: "red"    },


});

export const stylesThemed = makeStyles(styles)

export const globalFlex = {
  display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150
}
export const graphicalCharter = { base:'#333333', 
                                  platformTextColor: '#03465B', 
                                  platformBackgroundColor: '#D64726', 
                                  dailyTextColor: '#CB9B2B', 
                                  dailyBackgroundColor: '#013427',
                                }

export const primaryColor = { light: '#54caca', dark: '#006a6b', main: graphicalCharter.platformTextColor, contrastText: '#fff' };
export const secondaryColor = { light: '#ffbcaf', dark: '#c85a54', main: graphicalCharter.platformBackgroundColor, contrastText: '#fff' };
export const lookAndFeel = { cardElevation: 8 }

export const globalStyling = {
  
  '@global': {
    body: {
      scrollbarColor: '#D62929',
      scrollbarWidth:10,
      height:`100%`,
      '&::-webkit-scrollbar': {
        width: '0.6em',
        height: '0.6em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 3px '+graphicalCharter.platformTextColor,
        backgroundColor: 'rgba(200,200,200,.5)',
        outline: '1px solid slategrey',
        // borderRadius:6,
        height:30
      }
    },  
  },
  
}

export const customTheme = {
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    inactive: { main: '#eee', contrastText: '#fff' },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: graphicalCharter.platformTextColor+"77",
      hoverOpacity: 0.08,
      selected: "rgba(53, 127, 230, 0.3)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)"
    },
  },


  typography: {
    useNextVariants: true,
    fontSize: 14,


  },
   

  overrides: {


    // Name of the component ⚛️ / style sheet
    MuiList: {
      root: {
        '& a': {
          textDecoration:'none',
          '& a:hover': {
            textDecoration:'none',
          },   
        },
      },
    },
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        /*         background: green[500],
                color: 'white', */
      }
    },
    MuiTypography: {
      body1: {
        fontSize: 12,
      }
    }, 
    MuiFormControl: {
      root: {
        minWidth: 210,
      }
    },
    MuiInputBase: {
      root: {
        minWidth: 210,
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
              color: 'white',
              backgroundColor:'rgba(75, 155, 26, 0.4)',    
              borderLeft: `solid 4px`,
              paddingLeft: 12,
              border: 'none',
            
            '& span': {
              fontWeight: 'bold',
            },
            '& svg': {
              fill: 'white',
            },
          },
          '& svg': {
            fill: '#a2a2a2',
          },
          color: 'black',
        },

    },
    MuiDrawer: {
      root: {
      },
      paper: {
        backgroundColor: '#001e27',
      }
    },

    MuiOutlinedInput: {
      root: {
          borderRadius: 15,
      }
    },

    MuiTableCell: {
      head: {
        backgroundColor: "rgba(0,0,0,0.04)"
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.04)',
        borderBottom: 'solid 1px #dddddd',
      }
    }
  },
  body1: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: "0.00938em",
  },

}

