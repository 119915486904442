import { vocabulary } from "./Strings"


export const DEV_ENVIRONMENT = 'dev',
    PROD_ENVIRONMENT = 'prod',
    NETIRRIG_ENVIRONMENT = 'netirrig',
    DEV_NETIRRIG_ENVIRONMENT = 'devnetirrig',
    CA_ENVIRONMENT = 'ca'

export const TUNISIA_COUNTRY_ID = '8a0acadf-4de6-4030-b0c5-1fff1ead1a09'
export const PAYMENT_PROVIDER_STRIPE = 'STRIPE'
export const PAYMENT_PROVIDER_PAYMEE = 'PAYMEE'
export const PAYMENT_PROVIDER_VOUCHER = 'VOUCHER'
export const REQUEST_TYPE_GET_TOKEN = 'GET_TOKEN'

export const SOURCE_WEATHER_MEASURES = 'WEATHER_MEASURES', SOURCE_SEABEX_DEFAULT = 'SEABEX_DEFAULT';

export const SOIL_TEXTURE_TYPE_SOILGRID = "soilgrid";
export const SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON = "custom_texture_by_horizon";
export const SOIL_TEXTURE_TYPE_NETIRRIG = "netirrig";
export const SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE = "custom_one_choice";

export const CONSTS = {
    STATUS_TYPES: [
        { title: 'Enabled', value: 'Enabled' },
        { title: 'Disabled', value: 'Disabled' },
        { title: 'Accepted', value: 'Accepted' },
        { title: 'Proposed', value: 'Proposed' },
    ]
};

export const CYCLE_LENGTH = "Total",
    CYCLE_PHASE_INITIALE = "Phase initiale",
    CYCLE_PHASE_DEVELOPPEMENT = "Phase de developpement",
    CYCLE_PHASE_MI_SAISON = "Phase mi-saison",
    CYCLE_PHASE_ARRIERE_SAISON = "Phase arrière-saison"


export const TYPE_SITE = "site",
    TYPE_PARCEL = "parcel",
    TYPE_SECTOR = "sector"

export const INITIAL_STEP = "INITIAL_STEP",
    CURRENT_STEP = "CURRENT_STEP",
    IS_ENABLED = "IS_ENABLED"


export const IntervalUnit = [
    {
        name: 'Minute',
        id: 'minute'
    },
    {
        name: 'Hour',
        id: 'hour'
    },
    {
        name: 'Day',
        id: 'day'
    },
    {
        name: 'Month',
        id: 'month'
    }
]

export const ASPERSION_IRRIGATION = "ASPERSION_IRRIGATION",
    LOCAlIZED_IRRIGATION = "LOCAlIZED_IRRIGATION",
    SUBMERSION_IRRIGATION = "SUBMERSION_IRRIGATION",
    PIVO_IRRIGATION = "PIVO_IRRIGATION",
    MICRO_ASPERSION_IRRIGATION = "MICRO_ASPERSION_IRRIGATION",
    DRIP_IRRIGATION = "DRIP_IRRIGATION"


export const pennylaneCountryList = [
    {
        "value": "AT",
        "label": "Autriche"
    },
    {
        "value": "BE",
        "label": "Belgique"
    },
    {
        "value": "BG",
        "label": "Bulgarie"
    },
    {
        "value": "CY",
        "label": "Chypre"
    },
    {
        "value": "CZ",
        "label": "Tchéquie"
    },
    {
        "value": "DE",
        "label": "Allemagne"
    },
    {
        "value": "DK",
        "label": "Danemark"
    },
    {
        "value": "EE",
        "label": "Estonie"
    },
    {
        "value": "ES",
        "label": "Espagne"
    },
    {
        "value": "FI",
        "label": "Finlande"
    },
    {
        "value": "FR",
        "label": "France"
    },
    {
        "value": "GR",
        "label": "Grèce"
    },
    {
        "value": "HR",
        "label": "Croatie"
    },
    {
        "value": "HU",
        "label": "Hongrie"
    },
    {
        "value": "IE",
        "label": "Irlande"
    },
    {
        "value": "IT",
        "label": "Italie"
    },
    {
        "value": "LT",
        "label": "Lituanie"
    },
    {
        "value": "LU",
        "label": "Luxembourg"
    },
    {
        "value": "LV",
        "label": "Lettonie"
    },
    {
        "value": "MT",
        "label": "Malte"
    },
    {
        "value": "NL",
        "label": "Pays-Bas"
    },
    {
        "value": "PL",
        "label": "Pologne"
    },
    {
        "value": "PT",
        "label": "Portugal"
    },
    {
        "value": "RO",
        "label": "Roumanie"
    },
    {
        "value": "SE",
        "label": "Suède"
    },
    {
        "value": "SI",
        "label": "Slovénie"
    },
    {
        "value": "SK",
        "label": "Slovaquie"
    },
    {
        "value": "GB",
        "label": "Royaume-Uni"
    },
    {
        "value": "MC",
        "label": "Monaco"
    },
    {
        "value": "CH",
        "label": "Suisse"
    }
];


export const VEGETATION_INDEX = {
    CCC: 'CCC',
    CWC: 'CWC',
    EVI: 'EVI',
    FAPAR: 'FAPAR',
    IRECI: 'IRECI',
    LAI: 'LAI',
    MNDWI: 'MNDWI',
    MSAVI2: 'MSAVI2',
    NDMI: 'NDMI',
    NDRE: 'NDRE',
    NDREX: 'NDREX',
    NDVI: 'NDVI',
    NDWI: 'NDWI',
    NMDI: 'NMDI',
    NMDI_SOIL: 'NMDI_SOIL',
    NMDI_VEG: 'NMDI_VEG',
    SOC_SWIR: 'SOC_SWIR',
    SOC_VIS: 'SOC_VIS',
    WIW: 'WIW'
};


