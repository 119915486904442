import React from 'react'

import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import StyledModalHeader from './StyledModalHeader';
import Draggable from 'react-draggable';


function StyledModal({styles, title, children,actions,modalContext,height,width, open, closeFn,withoutContext=false, modalContrainerStyles=null, ...properties}) {

    let heightElement = height ? height : undefined;
    let widthElement = width ? width : undefined;

    
    const useStyles = makeStyles((theme) => ({
        paper: {
        //   position: 'absolute',
          maxWidth: `calc( 80% )`,
          maxHeight: `calc( 80% )`,
          backgroundColor: 'white',
          border: '1px solid #333',
          boxShadow: theme.shadows[5],
          margin: '0 auto',
        //   top: `calc( 50% - `+(heightElement/2)+`px )`,
        //   left: `calc( 50% - `+(widthElement/2)+`px )`,
          borderRadius: 10,
          height:heightElement,
          width:widthElement,
          display:'flex',
          flexDirection:'column',
        //   alignItems:'center'
        },
        content: {
            padding: theme.spacing(2, 4, 3),
            overflow:'auto',
            flexGrow: 1, 
             //display:'flex',
             ...modalContrainerStyles
        },
        modal: {
            position: 'fixed',
            zIndex: 2100,
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center'
        }
      }));
    
    const classes = useStyles();


    return (
            <Modal  style={styles} 
                    {...properties} 
                    open={withoutContext ? (open!==undefined ? open : true) : modalContext ? modalContext.open : true }
                    aria-labelledby="header"
                    aria-describedby="content"
                    className={classes.modal}
                    >
                      <>
                    <Draggable handle=".handle">
                      <div className={classes.paper}>
                          <StyledModalHeader id="draggable-header" className="handle" 
                                      title={title} 
                                      style={{}} 
                                      onClickFn={()=> {if (closeFn===undefined) { modalContext && modalContext.changeModalStatus(false)} else {closeFn()}}} />
                          <div id="content" className={classes.content}>
                              {children}
                          </div>
                      </div>
                    </Draggable>
                    </>
            </Modal>
    )
}

export default StyledModal
