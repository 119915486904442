import React from "react";

export default (props) => {

    const {children, bottom, top, left, right, ...properties} = props

    const style = {
        position:'absolute', 
        bottom:bottom, 
        top:top, 
        left:left, 
        right:right,
        fontSize:14,
        display:'flex',
        flexDirection:'Column',
        alignItems:'center'
    }

    return (
        <div {...properties} style={style}>
            {children}
        </div>
    )

}