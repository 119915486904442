
import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import './App.css';
import { vocabulary } from './App/Strings';

// import application from './App/application'
// import Register from './App/register'

import { syncAuth } from './actions'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { readCookie } from './utils';
// to import a component that was exported by "export default" -> import componentName from 'path'
// to import a component that was exported by "export const" -> import {componentName} from 'path'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NotificationAndSnackbar from './App/Components/notificationAndSnackbars'

import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { globalStyling } from './App/globalStyle';
import { withStyles } from '@material-ui/styles';
import GuideContextProvider from './ui/layout/contextLayout/GuideContext';
// import MqttController, { mqttEnd, mqttSubs } from './App/MqttController/MqttController';
import ReactGA from 'react-ga'
import { CA_ENVIRONMENT, DEV_ENVIRONMENT, DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT } from './App/globalConsts';

import NetirrigSignin from './App/_partners/_Netirrig/NetirrigSignin';
import CA_Signin from './App/_partners/_CA/CA_Signin';
import { REACT_APP_ENV_VERSION } from './config';
import StyledCircularProgress from './ui/display/StyledCircularProgress';

// import GlobalContextProvider from './globalsTools/GlobalContext';

const SingupUser = lazy(() => import('./App/SignupUser'));
const Signin = lazy(() => import('./App/signin'));
const ResetPassword = lazy(() => import('./App/resetpassword'));
const Dashboard = lazy(() => import('./App/drawer/Dashboard'));
const Forgetpassword = lazy(() => import('./App/forgetpassword'));
const ActivationSuccessful = lazy(() => import('./App/ActivationStatus'));



function App() {

 

  // localStorage.setItem('currentLanguage', 'en')
  let currentLanguage = localStorage.getItem('preferred_language') ?? "fr";

  const dispatch = useDispatch()



  const GlobalCss = withStyles(globalStyling)(() => null);

  const accessToken = localStorage.getItem('login_Token')
  const userRight = localStorage.getItem('userrights');
  const userId = localStorage.getItem('userid');
  const userName = localStorage.getItem('username');
  const company_name = localStorage.getItem('company_name');

  const userIdState = useSelector(state => state.app.userid)


  useEffect(() => {
    if (userIdState) {
      ReactGA.initialize(REACT_APP_ENV_VERSION !== DEV_ENVIRONMENT ? 'UA-213463104-1' : 'UA-213463104-2', {

        debug: false,
        gaOptions: {
          userId: userIdState
        }
      });
    }
  }, [userIdState])

  // let currentLanguage = localStorage.getItem('currentLanguage');



  useEffect(() => {
    // dispatch(setGlobalLanguage(currentLanguage))
    vocabulary.setLanguage(currentLanguage)
    if (userRight && userName && accessToken && userId) {
      dispatch(syncAuth(company_name, accessToken, userId, userRight, userName, currentLanguage));
      // mqttSubs(userId);
    }
    return () => {
      if (userId != null) {
        // mqttEnd(JSON.parse(userId));
      }
    }
  }, [])


  return (
    <>

      {/* <NotificationAndSnackbar /> */}

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider>
          <GlobalCss />
          <CssBaseline />
          {/* <MqttController /> */}
          {/* <GlobalContextProvider> */}
          <GuideContextProvider>
            <BrowserRouter>
              <Suspense fallback={<StyledCircularProgress size={50} label={vocabulary.loading} />}>
                <Switch>
                  {/* for arabic language right to left */}
                  {/* <div style={{direction: 'rtl'}}> */}

                  {/* <Route  path="/register" component={Register} /> */}
                  <Route exact path="/" component={(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? NetirrigSignin
                    : (REACT_APP_ENV_VERSION === CA_ENVIRONMENT ? CA_Signin
                      : Signin)} />
                  <Route exact path="/signup" component={SingupUser} />

                  {/* Forget and reset password routes  */}
                  <Route exact path="/forgetpassword" component={Forgetpassword} />
                  <Route exact path="/resetPassword/:token" component={ResetPassword} />

                  {/* exact: bool-> When true, the active class/style will only be applied if the location is matched exactly. */}
                  {/* Be carefull !!! : when using navigation drawer for example exact has to be false otherwise the drawer will not be displayed in sub Page path ( example /drawer/sites -> will return blank page) */}
                  <Route path="/app/" component={Dashboard} />

                  <Route exact path="/activation" component={ActivationSuccessful} />


                  {/* <Route path="/drawer" component={application} /> */}

                </Switch>
              </Suspense>
            </BrowserRouter>
          </GuideContextProvider>
          {/* </GlobalContextProvider> */}
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </>

  );

}


export default App;

