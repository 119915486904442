import * as types from '../actions/actionTypes';
import { logger } from '../globalsTools/utilities';

export default function (state = {
    n: 'cycle',
    data: [],
    cycleDetails: [],
    recalculatingCycles: [],
    families: [],
    indexRowsToUpdate: [],
    activeItem: null,
    error: null
    }, action = null) {
    switch (action.type) {
        case types.UPDATE_RECALCULATING_CYCLES:
            return { ...state, recalculatingCycles: [...state.recalculatingCycles, action.payload.id] };
        case types.REMOVE_RECALCULATING_CYCLE:
            return { ...state, recalculatingCycles: state.recalculatingCycles?.filter(el => el !== action.payload) };

        // case state.n + types.GET_ITEMS:
        //     return { ...state, data: action.payload, error: action.iserror }
        // case state.n + types.SELECT_ITEM:
        //     return { ...state, activeItem: action.payload };
        // case state.n + types.CREATE_ITEM:
        //     return { ...state, data: [...state.data, action.payload] }
        // case state.n + types.UPDATE_ITEM:
        //     let s1 = { ...state, data: state.data.map(i => i.id === action.id ? action.payload : i) };
        //     state.data.map(i => i.id === action.id ? action.payload : i)
        //     return { ...s1, activeItem: action.payload, indexRowsToUpdate: [] };
        // case state.n + types.DELETE_ITEM:
        //     return { ...state, data: state.data.filter(i => i.id !== action.id) };
        // case state.n + types.UPDATE_DATA:
        //     return { ...state, data: action.payload };

        // case state.n + 'FAMILIES':
        //     return { ...state, families: action.payload };

        default:
            return state;
    }
}