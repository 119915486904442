import React from "react";
import { graphicalCharter } from '../../App/globalStyle'

export default (props) => {

    const {children, styles, width,height, backgroundColor, justifyContent, alignItems, padding, margin, color, ...properties} = props


    const style = {  ...styles, 
                        width:width, 
                        height:height, 
                        backgroundColor:backgroundColor!=null ? backgroundColor: graphicalCharter.platformBackgroundColor,   
                        display: "flex",
                        flexDirection: "column",
                        ...(justifyContent && {justifyContent: justifyContent}),
                        alignItems: alignItems,
                        position:'relative',
                        padding:padding ? padding : 40,
                        margin:margin ? margin : 0,
                        color: color!=null ? color : 'white'
                    }

    return (
        <div {...properties} style={style}>
            {children}
        </div>
    )

}