import * as types from '../actions/actionTypes';

export default function (state= {
    n: 'meteoDay',
    weatherDataArea : [],
    data: [],
    indexRowsToUpdate: [],
    activeItem: null,
    error: null
    },action=null){

        switch (action.type) {
            case types.GET_WEATHER_DATA:
                return Object.assign({}, state, {
                    weatherDataArea: action.payload
                });
            // case 'meteoDay'+types.GET_ITEMS:
            //     return { ...state, data: action.payload, error: action.iserror }
            // case state.n + types.CREATE_ITEM:

            //     return { ...state, data: [...state.data, action.payload] }
            // case state.n + types.DELETE_ITEM:  
            //     return { ...state, data: state.data.filter(i => i.id !== action.id) };
            // case state.n + types.UPDATE_ITEM:
            //     let s1 = { ...state, data: state.data.map(i => i.id === action.id ? action.payload : i) };
            //     state.data.map(i => i.id === action.id ? action.payload : i)
            //     return { ...s1, activeItem: action.payload, indexRowsToUpdate: [] };
            default:
                return state;
        }
    
}