// import moment from 'moment';

import moment from "moment";

export const loadUTIF = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = '/UTIF.js'; // Adjust the path as necessary
        script.onload = () => resolve(window.UTIF); // Attach UTIF to the window object
        script.onerror = () => reject(new Error("Failed to load UTIF.js"));
        document.body.appendChild(script);
    });
}

/* eslint-disable */
export const generateUid = () => {
    var r = parseInt(Date.now() / 1000) + 'F-';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyz';
    for (var i = 5; i > 0; --i) r += chars[Math.floor(Math.random() * chars.length)];
    return r;
}
// export const momentizeDates = (obj, dates) => {
//     obj.forEach(function(o) {
//         dates.forEach(function(d) { 
//             o[d] = moment(o[d]); 
//         });    
//     });
// }
// export const vDate = (d, f) => {
//     return moment(d, (f) ? f : 'D/M/YYYY',true).isValid();
// }
export const vNumber = (value) => {
    return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
}
export const vEmail = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(e);
}

// === Cookies ==================================================================
export const createCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export const readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const eraseCookie = (name) => {
    localStorage.setItem(name, "", -1);
}

export const getNameFromJSON = (jsonObject, language) => {
    try {
        let parsedObject = JSON.parse(jsonObject)
        return parsedObject['name_'+language]
    } catch (e) {
        return "N/A"
    }
}


export const parseJSONObject = (jsonObject) => {
    try {
        let parsedObject = JSON.parse(jsonObject)
        return parsedObject
    } catch (e) {
        return undefined
    }
}

export const formatDateStr = (date, currentLanguage) => {
    return currentLanguage == "en"  ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') 
                                    : moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
}

export const formatDateTimeStr = (dateTime, currentLanguage) => {
    return currentLanguage == "en"  ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') 
                                    : moment(dateTime).format('DD-MM-YYYY HH:mm:ss')
}