/* eslint-disable no-useless-concat*/
import axios from "axios";
import https from "https";
import store from "../store";
import * as cruds from "../actions/cruds";
import * as types from "../actions/actionTypes";
import { disconnectSession } from "../actions";
import { useContext } from "react";
import {
  PAYMENT_PROVIDER_PAYMEE,
  PAYMENT_PROVIDER_STRIPE,
} from "../App/globalConsts";
import { logger } from "./utilities";
import { envKeys } from "../config";
// import { GlobalContext } from './GlobalContext';
// import { AREAS_DISABLE, AREAS_ENABLE, DEPLOYED_DEVICES_DISABLE, DEPLOYED_DEVICES_ENABLE, PRODUCTION_CYCLES_DISABLE, PRODUCTION_CYCLES_ENABLE } from "../App/globalConsts";

// var https = require('https-browserify');

var state = store.getState();

// At request level
const agent = new https.Agent({
  rejectUnauthorized: false,
});

//Promise based HTTP client for the browser to consume web service
export const GAxios = (
  token,
  url,
  method = "get",
  data = {},
  headers,
  params,
  userId
) => {
  axios.defaults.withCredentials = true;
  let promise = axios({
    url: url,
    data: data,
    params: params,
    timeout: 1000000,
    method: method,
    responseType: data && data.hasOwnProperty("download") ? "blob" : "json",
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers:
      data && data.hasOwnProperty("File")
        ? {
            ...headers /*'Accept-Encoding': 'gzip, deflate',*/,
            Accept: "*/*",
            // 'content-type': 'multipart/form-data; boundary=<calculated when request is sent>',
            ...(token && { Authorization: "Bearer " + token }),
            ...(userId && { "User-id": userId }),
          }
        : {
            ...headers,
            Accept: "application/json",
            ...(token && { Authorization: "Bearer " + token }),
            ...(userId && { "User-id": userId }),
          },
    httpsAgent: agent,
    withCredentials: true,
  });
  promise
    .then(({ status }) => {
      if (status === 401) {
        logger("forbidden : ", status);
      }
    })
    .catch((r) => {
      // console.log('GAxios Catch', r);
    });

  return promise;
};

/**
 * Isolated request to backend without going through Redux Action/dispatch
 * @param {String} url Data Url without Base URL
 * @param {String} method HTTP request Method
 * @param {JSON} data Data to send with Post/Put
 * @param {JSON} headers headers for the HTTP Request
 * @param {JSON} params params for get Requests
 */
export const IAxios = (url, method, data, headers = {}, params = {}) => {
  state = store.getState();

  const loginToken = state.app.impersonate
    ? state.app.impersonate.token
    : state.app.token;
  const backUrl = envKeys.GLOBAL_URL + "/api";
  const userId = state.app.userid;

  return GAxios(loginToken, backUrl + url, method, data, {}, params, userId);
};

const executeCallback = (callback, response, success, status) => {
  if (typeof callback == "function") {
    callback(response, success, status);
  } else {
    if (Array.isArray(callback)) {
      callback.forEach((element) => {
        element(response, success, status);
      });
    }
  }
};

/**
 * Get Data from an URL without going through redux states
 * @param {*} url
 * @param {*} data
 * @param {*} callback : CallBack function should be of the form (data, success True/False, httpStatus)
 */
export const GetDataNR = (url, params, callback, callbackError = undefined) => {
  return IAxios(url, "get", {}, {}, params)
    .then((response) => {
      if (callback) {
        executeCallback(callback, response.data, true, response.status);
      } else {
        return response;
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(disconnectSession());
        logger("forbidden : ", error);
      } else {
        if (callbackError) {
          callbackError(error);
        }
      }
    });
};

export const PostDataNR = (url, params, callback, headers) => {
  return (
    IAxios(url, "post", params, headers)
      .then((response) => {
        if (callback)
          executeCallback(callback, response, true, response.status);
      })
      // .catch(error => { callback !== null && callback(error, false, error.response);  /*callback(error, false)  */ })
      .catch((error) => {
        executeCallback(callback, error, false);
      })
  );
};
export const PutData = (url, params, callback) => {
  return IAxios(url, "put", params)
    .then((response) => {
      if (callback) executeCallback(callback, response, true, response.status);
    })
    .catch((error) => {
      executeCallback(callback, error, false);
    });
};
export const DeleteDataNR = (url, params, callback) => {
  return IAxios(url + "/" + params, "delete")
    .then((response) => {
      if (callback) executeCallback(callback, response, true, response.status);
    })
    .catch((error) => {
      executeCallback(callback, error, false);
    });
};
/**
 * Get Area Details using the Area ID
 * @param {String} areaId AreaID to get details for it
 * @param {Function} callback Function to be called with the results
 */
export const GetMeteoData = (areaId, callback) => {
  return GetDataNR("/meteosDateWeek?area_id=" + areaId, {}, callback);
};

export const updateUserStats = (user) => updateStatus(user.stats);
export const updateStatus = (user_stats, callback) => {
  if (user_stats) {
    if (user_stats.deployed_devices_number != null) {
      store.dispatch({
        type: types.DEPLOYED_DEVICES_NUMBER,
        payload: user_stats.deployed_devices_number,
      });
    }
    if (user_stats.production_cycles_number != null) {
      store.dispatch({
        type: types.PRODUCTION_CYCLES_NUMBER,
        payload: user_stats.production_cycles_number,
      });
    }
    if (user_stats.production_cycles_right != null) {
      store.dispatch({
        type: types.PRODUCTION_CYCLES_RIGHT,
        payload: user_stats.production_cycles_right,
      });
    }
    if (user_stats.production_cycles_list != null) {
      store.dispatch({
        type: types.PRODUCTION_CYCLES_LIST,
        payload: user_stats.production_cycles_list,
      });
    }
    if (user_stats.areas_number != null) {
      store.dispatch({
        type: types.AREAS_NUMBER,
        payload: user_stats.areas_number,
      });
    }
    if (user_stats.active_services_production_cycles != null) {
      store.dispatch({
        type: types.ACTIVE_SERVICES_PRODUCTION_CYCLES,
        payload: user_stats.active_services_production_cycles,
      });
    }
    if (user_stats.areas_right != null) {
      store.dispatch({
        type: types.AREAS_RIGHT,
        payload: user_stats.areas_right,
      });
    }
    if (user_stats.areas_list != null) {
      store.dispatch({
        type: types.AREAS_LIST,
        payload: user_stats.areas_list,
      });
    }
    if (user_stats.user_right != null) {
      store.dispatch({
        type: types.USER_RIGHTS,
        payload: user_stats.user_right,
      });
    }
    if (user_stats.status != null) {
      store.dispatch({ type: types.USER_STATUS, payload: user_stats.status });
    }
    if (user_stats.user_type != null) {
      store.dispatch({ type: types.USER_TYPE, payload: user_stats.user_type });
    }
    if (user_stats.preferred_language != null) {
      store.dispatch({
        type: types.PREFERRED_LANGUAGE,
        payload: user_stats.preferred_language,
      });
    }
    if (user_stats.payment_provider != null) {
      store.dispatch({
        type: types.PAYMENT_PROVIDER,
        payload: user_stats.payment_provider,
      });
    }
    if (user_stats.currency != null) {
      store.dispatch({ type: types.CURRENCY, payload: user_stats.currency });
    }
    if (user_stats.currency_decimals != null) {
      store.dispatch({
        type: types.CURRENCY_DECIMALS,
        payload: user_stats.currency_decimals,
      });
    }

    if (user_stats.billing_details != null) {
      store.dispatch({
        type: types.BILLING_DETAILS,
        payload: user_stats.billing_details,
      });
    }
    if (user_stats.has_renewal_discount != null) {
      store.dispatch({
        type: types.HAS_RENEWAL_DISCOUNT,
        payload: user_stats.has_renewal_discount,
      });
    }
    if (user_stats.has_renewal_discount_validity != null) {
      store.dispatch({
        type: types.HAS_RENEWAL_DISCOUNT_VALIDITY,
        payload: user_stats.has_renewal_discount_validity,
      });
    }
    if (user_stats.discount_for_all != null) {
      store.dispatch({
        type: types.DISCOUNT_FOR_ALL,
        payload: user_stats.discount_for_all,
      });
    }
    if (user_stats.discount_for_all_enabled != null) {
      store.dispatch({
        type: types.DISCOUNT_FOR_ALL_ENABLED,
        payload: user_stats.discount_for_all_enabled,
      });
    }
    if (user_stats.discount_for_all_validity != null) {
      store.dispatch({
        type: types.DISCOUNT_FOR_ALL_VALIDITY,
        payload: user_stats.discount_for_all_validity,
      });
    }

    if (user_stats.active_services_sencrop_weather_stations != null) {
      store.dispatch({
        type: types.ACTIVE_SERVICES_SENCROP_WEATHER_STATIONS,
        payload: user_stats.active_services_sencrop_weather_stations,
      });
    }
    if (user_stats.sencrop_weather_stations_number != null) {
      store.dispatch({
        type: types.SENCROP_WEATHER_STATIONS_NUMBER,
        payload: user_stats.sencrop_weather_stations_number,
      });
    }
    if (user_stats.canCreateSencropStation != null) {
      store.dispatch({
        type: types.CAN_CREATE_SENCROP_STATION,
        payload: user_stats.canCreateSencropStation,
      });
    }

    store.dispatch({ type: types.STATS_LOADING, payload: false });
  }
  if (callback) {
    callback();
  }
};

export const GetUserStatus = () => {
  return GetDataNR("/connected_user_stats", {}, [
    updateStatus,
    getPlatformAlert(),
  ]);
};

// Global Alerts
export const getPlatformAlert = () => {
  return GetDataNR("/user/alert", {}, (payload) =>
    store.dispatch({
      type: types.TOGGLE_ALERT,
      payload: payload.message,
      etat: payload.show,
    })
  );
};

/**
 *
 * AREAS SECTION
 *
 */

/**
 * Get Area Details using the Area ID
 * @param {String} areaId AreaID to get details for it
 * @param {Function} callback Function to be called with the results
 */
export const GetAreaDetails = (areaId, callback) => {
  return GetDataNR("/Area/" + areaId, {}, callback);
};
export const GetAreaSoil = (areaMapJSON, callback, callbackError) => {
  return GetDataNR(
    "/SolByBoundary",
    { boundary: areaMapJSON },
    callback,
    callbackError
  );
};
export const GetAreaSize = (areaMapJSON, callback) => {
  return GetDataNR("/SizeByBoundary", { boundary: areaMapJSON }, callback);
};
export const GetSatelliteTiles = (area_id, callback) => {
  return GetDataNR("/satelliteTiles/" + area_id, {}, callback);
};
export const GetAreaByUserId = (userId, callback) => {
  return GetDataNR("/showAreasByUserID/" + userId, {}, callback);
};
export const GetChildsByParentId = (area_id, callback) => {
  return GetDataNR("/ChildsArea/" + area_id, {}, callback);
};

/**
 * Get List of Areas
 * @param {Function} callback Function to be called with the results
 */

/**
 *
 * STATIONS SECTION
 *
 */

/**
 * Get List of Stations
 * @param {Function} callback Function to be called with the results
 */
export const GetStationsList = (callback) => {
  return GetDataNR("/DeployedDevice", {}, callback);
};

/**
 * Get List of Gateway Stations
 * @param {Function} callback Function to be called with the results
 */
export const GetGatewaysList = (callback) => {
  return GetDataNR("/DeployedDevice/gateways", {}, callback);
};

/**
 *
 * COUNTRIES, DELEGATIONS, CITIES SECTION
 *
 */

/**
 * Get List of Countries
 * @param {String} language Language of the dataset
 * @param {Function} callback Function to be called with the results
 */
export const GetCountriesList = (language, callback) => {
  return GetDataNR("/country", { lang: language }, callback);
};

/**
 * Get List of Countries
 * @param {String} language Language of the dataset
 * @param {Function} callback Function to be called with the results
 */
export const GetGovernatesList = (language, country_id, callback) => {
  return GetDataNR(
    "/governates/country",
    { lang: language, prm_country_id: country_id },
    callback
  );
};

/**
 * Get List of Countries
 * @param {String} language Language of the dataset
 * @param {Function} callback Function to be called with the results
 */
export const GetDelegationsList = (governate_id, language, callback) => {
  return GetDataNR(
    "/delegations/governate",
    { lang: language, prm_governate_id: governate_id },
    callback
  );
};
export const createAreaItem = (item, cb = null) => {
  return cruds.postData("area", "/Area", item, cb);
};
export const loadItemAndChilds = (id, cb = null) => {
  return cruds.getData("area" + "child", "/Area/" + id, cb);
};
export const loadSitesToExpandable = (f = "", paging = "", cb = null) => {
  return cruds.getData("expandable" + types.GET_ITEMS, "/Areas", cb);
};

export const DeleteArea = (itemId, cb = null) => {
  return DeleteDataNR("/Area", itemId, cb);
};
export const DeleteAreaPermanently = (itemId, cb = null) => {
  return DeleteDataNR("/admin/AreaDestroyPermanently", itemId, cb);
};
export const MassDeleteAreaPermanently = (itemIds, cb = null) => {
  return DeleteDataNR("/admin/MassAreaDestroyPermanently", itemIds, cb);
};
export const EmptySpecificArea = (itemId, cb = null) => {
  return DeleteDataNR("/emptySpecificAreaById", itemId, cb);
};

// TO CHANGE TO BE INDEPENDANT
export const createAreaItemThenLoadArea = (item, cb = null) => {
  return (dispatch, getState) => {
    return dispatch(createAreaItem(item, cb)).then(() => {
      if (item.parent_id) {
        return dispatch(loadItemAndChilds(item.parent_id));
      } else {
        return dispatch(loadSitesToExpandable());
      }
    });
  };
};

// login/logout

export const loginUser = (item, cb = null) => {
  return PostDataNR("/login", item, [updateUserStats, cb]);
};
export const logoutUser = (callback) => {
  return PostDataNR("/logout", {}, callback);
};

export const updateLanguage = (language, cb = null) => {
  return PostDataNR("/UpdateLanguage/" + language, cb);
};

export const newUserPassword = (item, cb = null) => {
  return PostDataNR("/password/reset", item, cb);
};

export const getCultureByFamilyList = (id, callback) => {
  return GetDataNR("/culturesByFamily", { id: id }, callback);
};
export const GetIrrigationTypeList = (id, callback) => {
  return GetDataNR("/PrmIrrigationTypesByFamily", { id: id }, callback);
};

// RELATED TO SEABEX

export const getSeabexCategorieByParentIdList = (parent_id, callback) => {
  return GetDataNR("/SeabexCategories", { parent_id: parent_id }, callback);
};
export const GetAllLogs = (callback) => {
  return GetDataNR("/SeabexDeviceLog", {}, callback);
};
export const GetSeabexDeviceLogByAreaId = (id, callback) => {
  return GetDataNR("/SeabexDeviceLogByArea", { area_id: id }, callback);
};

// CYCLES

export const GetCycleList = (callback) => {
  return GetDataNR("/ProductionCycle", {}, [GetUserStatus, callback]);
};
export const GetProductionCycleWithoutWaterBalance = (callback) => {
  return GetDataNR("/user/productionCyclesWithoutWaterbalance", {}, [
    GetUserStatus,
    callback,
  ]);
};
export const DeleteCycle = (itemId, cb) => {
  return DeleteDataNR("/ProductionCycle", itemId, [GetUserStatus, cb]);
};
export const createCycle = (item, cb = null) => {
  return PostDataNR("/ProductionCycle", item, [GetUserStatus, cb]);
};
export const updateStadesProductionCycle = (itemId, newCycle, cb = null) => {
  return PostDataNR(
    "/ProductionCycle/updateCycle/" + itemId,
    { cycle: newCycle },
    cb
  );
};
export const updateCycle = (item, callback) => {
  return PutData("/ProductionCycle/" + item.id, item, [
    GetUserStatus,
    callback,
  ]);
};
export const EndCycle = (itemId, endDate, callback) => {
  return PutData(
    "/enddate?id=" + itemId + "&end_date=" + endDate,
    null,
    callback
  );
};
export const EndProductionCycle = (itemId, endDate, item, callback) => {
  return PutData(
    "/enddate?id=" + itemId + "&end_date=" + endDate,
    item,
    callback
  );
};
export const GetProductionCycleById = (id, callback) => {
  return GetDataNR("/ProductionCycle/" + id, null, callback);
};
export const GetProductionCycleByAreaId = (areaId, callback) => {
  return GetDataNR("/getAllProductionCycle/" + areaId, null, callback);
};
export const GetProductionCycleByUserId = (callback) => {
  return GetDataNR("/indexProductionUser", {}, callback);
};
export const getTodayWaterBalanceByProductionID = (
  production_cycle_id,
  callback
) => {
  return GetDataNR(
    "/getTodayWaterBalanceByProductionID",
    { production_cycle_id },
    callback
  );
};

// PARAMETERS

export const GetAllCulture = (callback) => {
  return GetDataNR("/PrmCulture", {}, callback);
};
export const GetIrrigationFamilyList = (callback) => {
  return GetDataNR("/PrmIrrigationFamily", {}, callback);
};
export const GetDataTypeByDeviceId = (id, callback) => {
  return GetDataNR("/PrmDataTypeByDeployedDevice/" + id, null, callback);
};
export const GetDataTypeByAreaId = (id, callback) => {
  return GetDataNR("/PrmDataTypeByArea/" + id, null, callback);
};
export const GetAllDataTypes = (callback) => {
  return GetDataNR("/getAllPrmDataTypes", null, callback);
};

// profil
export const GetProfil = (callback) => {
  return GetDataNR("/profil", {}, callback);
};
export const updateFarmerProfil = (item, callback) => {
  return PutData("/updateProfil/" + item.id, item, callback);
};

// DEPLOYED DEVICES

export const getAllDeployedDevices = (callback) => {
  return GetDataNR("/DeployedDevice", {}, [GetUserStatus, callback]);
};
export const GetDeployedDevicebygateway = (id, callback) => {
  return GetDataNR("/deployedDeviceByGateway", { id: id }, [
    GetUserStatus,
    callback,
  ]);
};
export const GetDeployedDeviceGatewayByAreaId = (id, callback) => {
  return GetDataNR("/DeployedDeviceGateway", { area_id: id }, [
    GetUserStatus,
    callback,
  ]);
};
export const createStation = (item, cb = null) => {
  return PostDataNR("/DeployedDevice", item, [GetUserStatus, cb]);
};
export const DeleteStation = (itemId, cb = null) => {
  return DeleteDataNR("/DeployedDevice", itemId, [GetUserStatus, cb]);
};
export const getDeployedDeviceById = (id, callback) => {
  return GetDataNR("/DeployedDevice/" + id, null, [GetUserStatus, callback]);
};
export const getDeployedDeviceByAreaId = (id, callback) => {
  return GetDataNR("/DeployedDeviceByarea?area_id=" + id, null, [
    GetUserStatus,
    callback,
  ]);
};
export const getDevicesListByGatewayIdByType = (id, type, callback) => {
  return GetDataNR(
    "/getDeployedDevicesByGateWayAndType?type=" + type + "&gateway_id=" + id,
    null,
    [GetUserStatus, callback]
  );
};

// Area Deplyed Devices

export const getAreaDeployedDeviceEquipmentByType = (
  type,
  area_id,
  callback
) => {
  return GetDataNR(
    "/user/areaDeployedDeviceEquipment",
    { type, area_id },
    callback
  );
};
export const SendActionToDeployedDeviceEquipment = (item, cb = null) => {
  return PostDataNR("/user/devicesActions", item, cb);
};
export const getDeployedDeviceStatus = (device_id, cb = null) => {
  return PostDataNR("/user/deployedDeviceStatus", { device_id }, cb);
};

// LOGS

export const GetLogsByAreaAndDataType = (
  areaId,
  prmDataTypeId,
  dataSourceId,
  startDate,
  endDate,
  pageSize,
  pageNumber,
  callback
) => {
  return GetDataNR(
    "/showDataByAreaAndDataType/" +
      areaId +
      "/" +
      prmDataTypeId +
      "/" +
      dataSourceId,
    {
      start_date: startDate,
      end_date: endDate,
      per_page: pageSize,
      page: pageNumber,
    },
    callback
  );
};
export const GetLogsByAreaAndMultiDataType = (
  selectedDataSources,
  startDate,
  endDate,
  intervalUnit,
  intervalNumber,
  pageSize,
  pageNumber,
  callback
) => {
  return GetDataNR(
    "/showMultiDataByAreaAndDataType",
    {
      selectedDataSources: JSON.stringify(selectedDataSources),
      start_date: startDate,
      end_date: endDate,
      intervalUnit: intervalUnit,
      intervalNumber: intervalNumber,
      per_page: pageSize,
      page: pageNumber,
    },
    callback
  );
};
export const GetLogsByDeployedDeviceId = (
  deviceId,
  prmDataTypeId,
  startDate,
  endDate,
  pageSize,
  pageNumber,
  callback
) => {
  return GetDataNR(
    "/showDataByDeployedDeviceAndDataType/" + deviceId + "/" + prmDataTypeId,
    {
      start_date: startDate,
      end_date: endDate,
      per_page: pageSize,
      page: pageNumber,
    },
    callback
  );
};

export const DownloadLogs = (item, cb = null) => {
  return PostDataNR("/exportLogs", item, cb);
};

// Events
export const GetEventsByAreaId = (
  areaId,
  startDate,
  endDate,
  type,
  pageSize,
  pageNumber,
  callback
) => {
  return GetDataNR(
    "/EventByAreaID/" + areaId,
    {
      start_date: startDate,
      end_date: endDate,
      type: type,
      per_page: pageSize,
      page: pageNumber,
    },
    callback
  );
};
export const GetEventsByDeployedDeviceId = (
  deviceId,
  startDate,
  endDate,
  type,
  pageSize,
  pageNumber,
  callback
) => {
  return GetDataNR(
    "/EventByDeployedDevice/" + deviceId,
    {
      start_date: startDate,
      end_date: endDate,
      type: type,
      per_page: pageSize,
      page: pageNumber,
    },
    callback
  );
};

// export const GetEventsByAreaId = (areaId, startDate, endDate, type, callback) => { return GetDataNR('/EventByAreaID/' + areaId, { start_date: startDate, end_date: endDate, type: type }, callback)}
export const getEventsById = (id, callback) => {
  return GetDataNR("/Event/" + id, {}, callback);
};
// export const GetEventsByDeployedDeviceId = (deviceId, startDate, endDate, callback) => { return GetDataNR('/EventByDeployedDevice/' + deviceId, { start_date: startDate, end_date: endDate }, callback) }
export const GetEventType = (callback) => {
  return GetDataNR("/TypeEvent", {}, callback);
};
export const getEvents = (callback) => {
  return GetDataNR("/Event", {}, callback);
};

// IrrigationMangement
export const GetIrrigationByAreaId = (
  areaId,
  startDate,
  endDate,
  pageSize,
  page,
  callback
) => {
  return GetDataNR(
    "/IrrigationByArea",
    {
      id: areaId,
      start_date: startDate,
      end_date: endDate,
      per_page: pageSize,
      page: page,
    },
    callback
  );
};

//irrigationFamily

export const GetIrrigationFamily = () => {
  return GetDataNR("/PrmIrrigationFamily", {}, null);
};
export const createIrrigationFamily = (item, cb = null) => {
  return PostDataNR("/PrmIrrigationFamily", item, cb);
};
export const UpdateIrrigationFamily = (item, callback) => {
  return PutData("/PrmIrrigationFamily/" + item.id, item, callback);
};
export const DeleteIrrigationFamily = (itemId, cb = null) => {
  return DeleteDataNR("/PrmIrrigationFamily", itemId, cb);
};

//iriigationType
export const createIrrigationType = (item, cb = null) => {
  return PostDataNR("/PrmIrrigationType", item, cb);
};
export const UpdateIrrigationType = (item, callback) => {
  return PutData("/PrmIrrigationType/" + item.id, item, callback);
};
export const DeleteIrrigationType = (itemId, cb = null) => {
  return DeleteDataNR("/PrmIrrigationType", itemId, cb);
};

// device
export const GetseabexCategorieByParentId = (id, callback) => {
  return GetDataNR("/SeabexCategories?parent_id=" + id, null, callback);
};

export const GetSeabexCategoryModel = (id, callback) => {
  return GetDataNR("/SeabexModels?seabex_category_id=" + id, null, callback);
};

export const GetSeabexCategories = (callback) => {
  return GetDataNR("/SeabexCategories", {}, callback);
};

export const updateStationItem = (item, callback) => {
  return PutData("/DeployedDevice/" + item.id, item, callback);
};

export const updateDeviceStatus = (item, callback) => {
  return PutData("/DeployedDevice/updateStatus/" + item.id, item, callback);
};

// followers
export const GetAllFollowers = (callback) => {
  return GetDataNR("/Followers", {}, callback);
};

//subscription
export const GetSubscriptionList = (callback) => {
  return GetDataNR("/Subscription", {}, callback);
};
export const CreateSubscription = (item, cb = null) => {
  return PostDataNR("/Subscription", item, cb);
};
export const updateSubscription = (item, callback) => {
  return PutData("/Subscription/" + item.id, item, callback);
};
export const deletelSubcription = (itemId, cb = null) => {
  return DeleteDataNR("/Subscription", itemId, cb);
};

//advice
export const GetAllAdviceList = (callback) => {
  return GetDataNR("/Advice", {}, callback);
};
// agent
export const GetOrganisationAgents = (callback) => {
  return GetDataNR("/OrganizationAgents", {}, callback);
};
export const UpdateAgent = (item, Id, callback) => {
  return PutData("/UpdateAgent/" + Id, item, callback);
};
export const CreateAgent = (item, cb = null) => {
  return PostDataNR("/CreateAgent", item, cb);
};

//MyOrganizations
export const GetMyOrganizationsandFollowers = (callback) => {
  return GetDataNR("/getMyOrganizationsandFollowers", {}, callback);
};
export const DisableOrganisme = (item, Id, callback) => {
  return PutData("/Organization/" + Id, item, callback);
};
export const EnableOrganization = (item, Id, callback) => {
  return PutData("/Organization/" + Id, item, callback);
};
export const DeleteOrganization = (itemId, cb = null) => {
  return DeleteDataNR("/Organization", itemId, cb);
};
//equipmentType
export const GetEquipmentsType = (type, callback) => {
  return GetDataNR("/Equipment?type=" + type, null, callback);
};
export const GetEquipmentsByDevice = (deviceId, callback) => {
  return GetDataNR(
    "/getEquipmentByDeplyedDevice?deployed_id=" + deviceId,
    null,
    callback
  );
};

export const DeleteEquipment = (itemId, cb = null) => {
  return DeleteDataNR("/Equipment", itemId, cb);
};
export const createEquipment = (item, cb = null) => {
  return PostDataNR("/Equipment", item, cb);
};
export const UpdateEquipment = (item, callback) => {
  return PutData("/Equipment/" + item.id, item, callback);
};
export const GetEquipmentById = (id, callback) => {
  return GetDataNR("/Equipment/" + id, {}, callback);
};
export const GetEquipmentTypesList = (type, callback) => {
  return GetDataNR("/Equipment", { type: type }, callback);
};
// equipmentFamily

export const DeleteEquipmentFamily = (itemId, cb = null) => {
  return DeleteDataNR("/PrmEquipmentFamily", itemId, cb);
};
export const CreateEquipmentFamily = (item, cb) => {
  return PostDataNR("/PrmEquipmentFamily", item, cb);
};
export const UpdateEquipmentFamily = (item, callback) => {
  return PutData("/PrmEquipmentFamily/" + item.id, item, callback);
};
export const GetEquipmentFamilyList = (callback) => {
  return GetDataNR("/PrmEquipmentFamily", {}, callback);
};

//dataType
export const GetDataTypeList = (callback) => {
  return GetDataNR("/PrmDataType", {}, callback);
};
export const createDataType = (item, cb = null) => {
  return PostDataNR("/PrmDataType", item, cb);
};
export const UpdateDataType = (item, callback) => {
  return PutData("/PrmDataType/" + item.id, item, callback);
};
export const DeletelDataType = (itemId, cb = null) => {
  return DeleteDataNR("/PrmDataType", itemId, cb);
};
export const GetPrmDataTypeByAreaId = (id, callback) => {
  return GetDataNR("/PrmDataTypeArea/" + id, {}, callback);
};
export const GetPrmDataTypeByEquipmentId = (id, callback) => {
  return GetDataNR("/getPrmDatatypeByEquipment/" + id, {}, callback);
};

// cultureFamily
export const GetCultureFamilliesList = (callback) => {
  return GetDataNR("/PrmCultureFamily", {}, callback);
};
export const createCultureFamily = (item, cb = null) => {
  return PostDataNR("/PrmCultureFamily", item, cb);
};
export const UpdateCultureFamily = (item, callback) => {
  return PutData("/PrmCultureFamily/" + item.id, item, callback);
};
export const DeleteCultureFamily = (itemId, cb = null) => {
  return DeleteDataNR("/PrmCultureFamily", itemId, cb);
};

// cultureType
export const createCultureType = (item, cb = null) => {
  return PostDataNR("/Culture", item, cb);
};
export const UpdateCultureType = (item, callback) => {
  return PutData("/Culture/" + item.id, item, callback);
};
export const DeletCultureByFamily = (itemId, cb = null) => {
  return DeleteDataNR("/Culture", itemId, cb);
};

// soilFamily
export const GetSoilFamilliesList = (callback) => {
  return GetDataNR("/PrmTextureSol", {}, callback);
};
export const createSoilFamily = (item, cb) => {
  return PostDataNR("/PrmTextureSol", item, cb);
};
export const UpdateSoilFamily = (item, callback) => {
  return PutData("/PrmTextureSol/" + item.id, item, callback);
};
export const DeleteSoilTextureFamily = (itemId, cb = null) => {
  return DeleteDataNR("/PrmTextureSol", itemId, cb);
};

// soilType
export const GetAllSoilTypes = (callback) => {
  return GetDataNR("/Sol", {}, callback);
};
export const GetSoilTypesList = (id, callback) => {
  return GetDataNR("/SolByTexture", { id: id }, callback);
};
export const GetSoilType = (id, callback) => {
  return GetDataNR("/Sol/" + id, {}, callback);
};
export const createSoilTypeByFamily = (item, cb = null) => {
  return PostDataNR("/Sol", item, cb);
};
export const UpdateSoilTypeByFamily = (item, callback) => {
  return PutData("/Sol/" + item.id, item, callback);
};
export const DeleteSoilType = (itemId, cb = null) => {
  return DeleteDataNR("/Sol", itemId, cb);
};
export const GetRUFromSoilType = (soiltype, cb = null) => {
  return GetDataNR("/getRUFromSoilType/" + soiltype, {}, cb);
};

//Area
export const createArea = (item, cb) => {
  return PostDataNR("/Area", item, [GetUserStatus, cb]);
};
export const updateAreaItem = (item, cb) => {
  return PutData("/Area/" + item.id, item, [GetUserStatus, cb]);
};
export const SyncPartnerAreaBoundries = (areaId, partner_area, cb) => {
  return PutData("/Area/partner/" + areaId, { partner_area }, [
    GetUserStatus,
    cb,
  ]);
};
export const GetAreasList = (callback) => {
  return GetDataNR("/Areas", {}, [GetUserStatus, callback]);
};
export const GetAreasTree = (callback) => {
  return GetDataNR("/AreasTree", {}, [callback]);
};
export const GetPartnerAreasTree = (partnerId, callback) => {
  return GetDataNR("/partnerAreaTree/" + partnerId, {}, [callback]);
};
export const GetAreasListing = (callback) => {
  return GetDataNR("/Area", {}, [callback]);
};
export const GetAreasListWithMap = (callback) => {
  return GetDataNR("/AreasWithMap", {}, [GetUserStatus, callback]);
};
export const CleanDeletedAreas = (callback) => {
  return GetDataNR("/admin/deleted_area_cleaning", {}, [callback]);
};

// automationPlan

export const GetAutomationPlanList = (callback) => {
  return GetDataNR("/AutomationPlan", {}, callback);
};
export const createIrrigationPlan = (item, cb = null) => {
  return PostDataNR("/AutomationPlan", item, cb);
};
export const updateIrrigationPlan = (item, callback) => {
  return PutData("/AutomationPlan/" + item.id, item, callback);
};
export const synchronizeIrrigationPlan = (id, callback) => {
  return GetDataNR(
    "/user/automationPlan/sync",
    { automationPlanId: id },
    callback
  );
};

//signUp
export const signUpUser = (item, cb = null) => {
  return PostDataNR("/register", item, cb);
};
//folowers
export const CreateFollowers = (item, cb = null) => {
  return PostDataNR("/Follower", item, cb);
};
//organisme
export const CreateOrganization = (item, cb = null) => {
  return PostDataNR("/Organization", item, cb);
};
export const updateOrganization = (item, callback) => {
  return PutData("/Organization/" + item.id, item, callback);
};

//groupeList

export const GetGroupeByUserId = (callback) => {
  return GetDataNR("/GroupByUser", {}, callback);
};
export const CreateGroupe = (item, cb) => {
  return PostDataNR("/Group", item, cb);
};
export const GetAllGroupe = (callback) => {
  return GetDataNR("/Group", {}, callback);
};
export const GetGroupeDetailByUserId = (id, callback) => {
  return GetDataNR("/Group/" + id, null, callback);
};
export const updateGroupe = (item, callback) => {
  return PutData("/Group/" + item.id, item, callback);
};

export const FollowGroup = (item, cb) => {
  return PostDataNR("/FollowGroup", item, cb);
};

export const DeleteGroup = (itemId, cb = null) => {
  return DeleteDataNR("/Group", itemId, cb);
};
export const UnFollowGroup = (item, callback) => {
  return PutData("/UnfollowGroup", item, callback);
};

// Error
export const GetAllError = (callback) => {
  return GetDataNR("/Error", {}, callback);
};
export const ErrorChecked = (item, itemId, callback) => {
  return PutData("/Error/" + itemId, item, callback);
};
export const ErrorCorrected = (item, itemId, callback) => {
  return PutData("/Error/" + itemId, item, callback);
};

//irrigation

export const CreateIrrigation = (item, cb = null) => {
  return PostDataNR("/Irrigation", item, cb);
};
export const UpdateIrrigation = (item, callback) => {
  return PutData("/Irrigation/" + item.id, item, callback);
};
export const DeleteIrrigation = (itemId, cb) => {
  return DeleteDataNR("/Irrigation", itemId, cb);
};
export const CreateMultipleIrrigation = (item, cb = null) => {
  return PostDataNR("/multipleIrrigationInsert", item, cb);
};

export const multipleIrrigationDelete = (ids, cb = null) => {
  return PostDataNR("/multipleIrrigationDelete", {ids}, cb);
};

//WaterNeed
export const GetProductionCyclesPhaseNames = (area_id, callback) => {
  return GetDataNR("/getProductionCyclesPhaseNames/" + area_id, null, callback);
};
export const GetWaterNeedByParcelId = (parcelId, callback) => {
  return GetDataNR("/waterNeedByAreaId?area_id=" + parcelId, null, callback);
};
export const GetWaterNeedByProductionCycle = (
  production_cycle_id,
  callback
) => {
  logger(
    "GetWaterNeedByProductionCycle production_cycle_id",
    production_cycle_id
  );
  return GetDataNR(
    "/waterNeedsByProductionCycle",
    { production_cycle_id },
    callback
  );
};
export const DownloadWaterNeedHistoryExcel = (item, cb = null) => {
  return PostDataNR("/exportLogs", item, cb);
};

//SeabexCategorie
export const CreateSeabexCategory = (item, cb = null) => {
  return PostDataNR("/SeabexCategorie", item, cb);
};
export const DeleteSeabexCategory = (itemId, cb) => {
  return DeleteDataNR("/SeabexCategorie", itemId, cb);
};
export const UpdateSeabexCategory = (item, callback) => {
  return PutData("/SeabexCategorie/" + item.id, item, callback);
};

//deviceModel
export const getSeabexCategoryModelList = (seabex_category_id, callback) => {
  return GetDataNR(
    "/SeabexModels",
    { seabex_category_id: seabex_category_id },
    callback
  );
};
export const CreateDeviceModel = (item, cb = null) => {
  return PostDataNR("/SeabexModel", item, cb);
};
export const UpdateSeabexStationByCategory = (item, cb) => {
  return PutData("/SeabexModel/", item.id, item, cb);
};
export const DeleteSeabexStationByCategory = (itemId, callback) => {
  return DeleteDataNR("/SeabexModel", itemId, callback);
};

//user
export const impersonateTake = (userId, callback) => {
  return GetDataNR(`/admin/impersonate/take/${userId}`, {}, callback);
};
export const impersonateLeave = (callback) => {
  return GetDataNR(`/admin/impersonate/leave`, {}, callback);
};
export const GetUsersList = (callback) => {
  return GetDataNR("/admin/usersList", {}, callback);
};
export const GetAllUsers = (callback) => {
  return GetDataNR("/allUser", {}, callback);
};
export const DeleteUser = (itemId, cb) => {
  return DeleteDataNR("/admin/users", itemId, cb);
};
export const UpdateUser = (item, callback) => {
  return PutData("/admin/users/" + item.id, item, callback);
};

//oraganization
export const impersonateOrganizationTake = (userId, callback) => {
  return GetDataNR(`/organization/impersonate/take/${userId}`, {}, callback);
};
export const impersonateOrganizationLeave = (callback) => {
  return GetDataNR(`/organization/impersonate/leave`, {}, callback);
};
export const GetAllOrganizations = (callback) => {
  return GetDataNR("/admin/organizations", {}, callback);
};
export const AddOrganization = (item, cb = null) => {
  return PostDataNR("/admin/organizations", item, cb);
};
export const DeleteOrganizations = (itemId, cb) => {
  return DeleteDataNR("/admin/organizations", itemId, cb);
};
export const UpdateOrganizations = (item, callback) => {
  return PutData("/admin/organizations/" + item.id, item, callback);
};
//oraganization
export const impersonateOrganizationUserTake = (userId, callback) => {
  return GetDataNR(
    `/organization-user/impersonate/take/${userId}`,
    {},
    callback
  );
};
export const impersonateOrganizationUserLeave = (callback) => {
  return GetDataNR(`/organization-user/impersonate/leave`, {}, callback);
};

/* invoiceService */

// customers

export const GetAllCustomers = (callback, pagination) => {
  return GetDataNR(
    "/admin/invoiceService/customers",
    { ...pagination },
    callback
  );
};
export const GetCustomerById = (customerId, callback) => {
  return GetDataNR(
    "/admin/invoiceService/customers" + customerId,
    {},
    callback
  );
};
export const addCustomer = (item, cb = null) => {
  return PostDataNR("/admin/invoiceService/customers", item, cb);
};
export const UpdateCustomer = (customerId, item, callback) => {
  return PutData(
    "/admin/invoiceService/customers/" + customerId,
    item,
    callback
  );
};

// Product

export const GetAllProducts = (callback, pagination) => {
  return GetDataNR(
    "/admin/invoiceService/products",
    { ...pagination },
    callback
  );
};
export const GetProductById = (productId, callback) => {
  return GetDataNR("/admin/invoiceService/products" + productId, {}, callback);
};
export const addProduct = (item, cb = null) => {
  return PostDataNR("/admin/invoiceService/products", item, cb);
};
export const UpdateProduct = (productId, item, callback) => {
  return PutData("/admin/invoiceService/products/" + productId, item, callback);
};

// Invoice

export const GetAllInvoices = (callback, pagination) => {
  return GetDataNR(
    "/admin/invoiceService/invoices",
    { ...pagination },
    callback
  );
};
export const GetInvoiceById = (invoiceId, callback) => {
  return GetDataNR("/admin/invoiceService/invoices" + invoiceId, {}, callback);
};
export const addInvoice = (item, cb = null) => {
  return PostDataNR("/admin/invoiceService/invoices", item, cb);
};
export const UpdateInvoice = (invoiceId, item, callback) => {
  return PutData("/admin/invoiceService/invoices/" + invoiceId, item, callback);
};

// Voucher

export const GetAllVouchers = (callback) => {
  return GetDataNR("/admin/invoiceService/vouchers", {}, callback);
};
export const GetVoucherById = (voucherId, callback) => {
  return GetDataNR("/admin/invoiceService/vouchers" + voucherId, {}, callback);
};
export const addVoucher = (item, cb = null) => {
  return PostDataNR("/admin/invoiceService/vouchers", item, cb);
};
export const UpdateVoucher = (voucherId, item, callback) => {
  return PutData("/admin/invoiceService/vouchers/" + voucherId, item, callback);
};
export const DeleteVoucher = (voucherId, cb) => {
  return DeleteDataNR("/admin/invoiceService/vouchers", voucherId, cb);
};
export const checkVoucher = (payload, callback) => {
  return PostDataNR("/invoiceService/vouchers/check", payload, callback);
};

export const GetAllSeabexPack = (callback) => {
  return GetDataNR("/SeabexPack", {}, callback);
};

/* !invoiceService */

//notification

export const SendMessage = (item, cb = null) => {
  return PostDataNR("/sendNotification", item, cb);
};

//advice

export const GetAllAdvices = (callback) => {
  return GetDataNR("/Advice", {}, callback);
};
export const SaveAdvice = (item, cb = null) => {
  return PostDataNR("/Advice", item, cb);
};

//agentNotif
export const GetAgentNotifications = (callback) => {
  return GetDataNR("/AgentsOrganizationNotifs", {}, callback);
};
//waterNeedOnDemand

export const WaterNeedOnDemand = (item, cb = null) => {
  return PostDataNR("/waterNeedOnDemand", item, cb);
};

// automationPlan
export const deleteIrrigationPlan = (itemId, cb) => {
  return DeleteDataNR("/AutomationPlan", itemId, cb);
};

// task
export const createCronTaskItem = (item, cb = null) => {
  return PostDataNR("/Task", item, cb);
};
export const DeleteTask = (itemId, callback) => {
  return DeleteDataNR("/Task", itemId, callback);
};
export const GetIrrigationPlanTask = (id, callback) => {
  return GetDataNR("/rules/" + id, null, callback);
};

// realTime  send

export const SendCommand = (item, cb = null) => {
  return PostDataNR("/sendCommand", item, cb);
};
export const GetLastRealTimeCaptures = (id, callback) => {
  return GetDataNR("/getLatestCollectedData/" + id, {}, callback);
};

// waterNeedByDateRange

export const WaterNeedByDate = (areaId, startDate, endDate, callback) => {
  return GetDataNR(
    "/waterNeedByAreaIdbyDate",
    { area_id: areaId, start_date: startDate, end_date: endDate },
    callback
  );
};
export const WaterNeedByProductionCycleByDate = (
  productionCycleId,
  startDate,
  endDate,
  callback
) => {
  return GetDataNR(
    "/waterNeedByProductionCycleIdbyDate",
    {
      production_cycle_id: productionCycleId,
      start_date: startDate,
      end_date: endDate,
    },
    callback
  );
};
export const PluvioByAreaIdByDate = (areaId, startDate, endDate, callback) => {
  return GetDataNR(
    "/getPluviometrieByAreaId",
    { area_id: areaId, start_date: startDate, end_date: endDate },
    callback
  );
};
export const SetPluvioByAreaIdByDate = (
  areaId,
  startDate,
  endDate,
  correctedRainfall,
  callback
) => {
  return PostDataNR(
    "/setPluviometrieByAreaId",
    {
      area_id: areaId,
      start_date: startDate,
      end_date: endDate,
      correctedRainfall,
    },
    callback
  );
};
export const SetMultiplePluvio = (areas, pluvio, callback) => {
  return PostDataNR("/multiplePluviometrie", { areas, pluvio }, callback);
};
export const RecalculateWaterNeed = (item, cb = null) => {
  return PostDataNR(
    "/reCalculateButton/" + item.area_id + "/" + item.id,
    item,
    cb
  );
};

//userPreference

export const GetDataSourcesList = (callback) => {
  return GetDataNR("/FormulaPrmDataTypeDataUser", {}, callback);
};
export const createDataSource = (item, cb = null) => {
  return PostDataNR("/FormulaPrmDataTypeDataUser", item, cb);
};
export const updateDataSource = (item, callback) => {
  return PutData("/FormulaPrmDataTypeDataUser/" + item.id, item, callback);
};
export const DeleteDataSource = (itemId, callback) => {
  return DeleteDataNR("/FormulaPrmDataTypeDataUser", itemId, callback);
};

// source
export const GetSourcesList = (callback) => {
  return GetDataNR("/DataSource", {}, callback);
};
export const GetDefaultDataSource = (callback) => {
  return GetDataNR("/DefaultDataSource", {}, callback);
};
export const GetDataSourceByAreaId = (id, callback) => {
  return GetDataNR("/getDataSourceByArea?area_id=" + id, null, callback);
};

//Measures
export const GetMeasures = (callback) => {
  return GetDataNR("/mesures", {}, callback);
};

//Unit by measure

export const GetUnitByMeasure = (id, callback) => {
  return GetDataNR("/possibilities?param=" + id, null, callback);
};

//sendFile
export const sendFile = (item, cb = null) => {
  return PostDataNR("/importFile", item, cb);
};
export const GetFileList = (callback) => {
  return GetDataNR("/DataSourceLog", {}, callback);
};
export const DeleteFileByAreaId = (itemId, areaId, callback) => {
  return DeleteDataNR("/DeleteDataFile", itemId + "/" + areaId, callback);
};

// formulas
export const GetFormulas = (callback) => {
  return GetDataNR("/AllFormula", {}, callback);
};
export const AttachFormulaToStation = (item, cb = null) => {
  return PostDataNR("/AttachFormula", item, cb);
};
export const createFormulaItem = (item, cb = null) => {
  return PostDataNR("/Formula", item, cb);
};
export const DeleteFormula = (itemId, callback) => {
  return DeleteDataNR("/Formula", itemId, callback);
};
export const LoadUserFormulas = (callback) => {
  return GetDataNR("/UserFormula", {}, callback);
};
export const GetFormulaById = (id, callback) => {
  return GetDataNR("/Formula/" + id, null, callback);
};
export const UpdateFormula = (item, callback) => {
  return PutData("/Formula/" + item.id, item, callback);
};

//financial

export const GetFinancialCategories = (callback) => {
  return GetDataNR("/FinancialCategory", {}, callback);
};
export const DeleteFinancialCategory = (itemId, cb = null) => {
  return DeleteDataNR("/FinancialCategory", itemId, cb);
};
export const CreateFiancialCategory = (item, cb = null) => {
  return PostDataNR("/FinancialCategory", item, cb);
};
export const UpdateFiancialCategory = (item, callback) => {
  return PutData("/FinancialCategory/" + item.id, item, callback);
};

//activities
export const GetActivitiesList = (callback) => {
  return GetDataNR("/Activity", {}, callback);
};
export const DeleteActivity = (itemId, cb = null) => {
  return DeleteDataNR("/Activity", itemId, cb);
};
export const CreateActivity = (item, cb = null) => {
  return PostDataNR("/Activity", item, cb);
};
export const UpdateActivity = (item, callback) => {
  return PutData("/Activity/" + item.id, item, callback);
};

// activities type
export const GetActivitiesType = (callback) => {
  return GetDataNR("/ActivityType", {}, callback);
};
export const createActivitiesType = (item, cb = null) => {
  return PostDataNR("/ActivityType", item, cb);
};
export const UpdateActivitiesType = (item, callback) => {
  return PutData("/ActivityType/" + item.id, item, callback);
};
export const DeleteActivitiesType = (itemId, cb = null) => {
  return DeleteDataNR("/ActivityType", itemId, cb);
};

// dashboard  call request

export const GetAllRequestCall = (callback) => {
  return GetDataNR("/numbreRequetsNumber", {}, callback);
};
export const GetUsersStats = (callback) => {
  return GetDataNR("/admin/users_perday_reporting", {}, callback);
};
export const GetAreasStats = (callback) => {
  return GetDataNR("/admin/areas_created_perday", {}, callback);
};
// export const ErrorChecked = (item, itemId, callback) => { return PutData("/Error/" + itemId, item, callback) }
// export const ErrorCorrected = (item, itemId, callback) => { return PutData("/Error/" + itemId, item, callback) }

export const GetRequestCallByTime = (startDate, endDate, callback) => {
  return GetDataNR(
    "/getLogsRequestGroupByTime",
    { start_date: startDate, end_date: endDate },
    callback
  );
};

// Customers Orders List

export const GetOrdersList = (callback) => {
  return GetDataNR("/orders", {}, callback);
};
export const DeleteOrder = (itemId, cb = null) => {
  return DeleteDataNR("/orders", itemId, cb);
};
export const CreateOrder = (item, cb = null) => {
  return PostDataNR("/orders", item, cb);
};
export const UpdateOrder = (item, callback) => {
  return PutData("/orders/" + item.id, item, callback);
};
export const ManuallyPayOrder = (order_id, callback) => {
  return PostDataNR("/orders/paid", { order_id }, callback);
};

export const saveOrderTobePaid = (cart, billingDetails, currency, callback) => {
  return PostDataNR(
    "/orders/save",
    { cart, billingDetails, currency },
    callback
  );
};

export const GetOrdersLinesList = (order_id, callback) => {
  return GetDataNR("/orders/order_lines/" + order_id, {}, callback);
};
export const ManuallyProcessOrderLine = (order_id, callback) => {
  return GetDataNR("/orders/process/" + order_id, {}, callback);
};

export const GetSeabexOffers = (area_id, area_size, callback) => {
  return PostDataNR(
    "/getSeabexOffers",
    { area_id: area_id, area_size: area_size },
    [GetUserStatus, callback]
  );
};
export const GetActiveSeabexOffers = (area_id, area_size, callback) => {
  return PostDataNR(
    "/getActiveSeabexOffers",
    { area_id: area_id, area_size: area_size },
    callback
  );
};
export const GetActiveServices = (callback) => {
  return GetDataNR("/user/activeServices", {}, callback);
};

// Payment Processing

export const verifyCart = (cart, callback) => {
  return PostDataNR("/verifyCart", { cart: JSON.stringify(cart) }, [
    GetUserStatus,
    callback,
  ]);
};
export const ProcessPaymentRequest = (
  cart,
  paymentDetails,
  billingDetails,
  currency,
  callback
) => {
  return PostDataNR(
    "/processPaymentRequest",
    {
      cart: JSON.stringify(cart),
      paymentDetails: JSON.stringify(paymentDetails),
      currency,
      billingDetails,
    },
    [GetUserStatus, callback]
  );
};
export const paymentWithVoucher = (
  cart,
  paymentDetails,
  billingDetails,
  currency,
  callback
) => {
  return PostDataNR(
    "/paymentWithVoucher",
    { cart, paymentDetails: paymentDetails, currency, billingDetails },
    [GetUserStatus, callback]
  );
};
export const ConfirmPaymentProcessedStripe = (
  paymentIntent,
  order_id,
  callback
) => {
  return PostDataNR(
    "/confirmPaymentProcessed",
    {
      paymentProvider: PAYMENT_PROVIDER_STRIPE,
      paymentIntent: JSON.stringify(paymentIntent),
      orderId: order_id,
    },
    [GetUserStatus, callback]
  );
};
export const ConfirmPaymentProcessedPaymee = (
  paymentToken,
  order_id,
  callback
) => {
  return PostDataNR(
    "/confirmPaymentProcessed",
    {
      paymentProvider: PAYMENT_PROVIDER_PAYMEE,
      paymentToken: paymentToken,
      orderId: order_id,
    },
    [GetUserStatus, callback]
  );
};

// publication
export const GetPublicationsList = (callback) => {
  return GetDataNR("/Publications", {}, callback);
};
export const DeletePublication = (itemId, cb = null) => {
  return DeleteDataNR("/Publications", itemId, cb);
};
export const CreatePublication = (item, cb = null) => {
  return PostDataNR("/Publications", item, cb);
};
export const UpdatePublication = (item, callback) => {
  return PutData("/Publications/" + item.id, item, callback);
};
export const GetPublicationByCountry = (callback) => {
  return GetDataNR("/getPublicationByCountry", {}, callback);
};

//Item

export const GeItemList = (callback) => {
  return GetDataNR("/Item", {}, callback);
};
export const DeleteItem = (itemId, cb = null) => {
  return DeleteDataNR("/Item", itemId, cb);
};
export const CreateItem = (item, cb = null) => {
  return PostDataNR("/Item", item, cb);
};
export const UpdateItem = (item, callback) => {
  return PutData("/Item/" + item.id, item, callback);
};
export const GetItemByFinancialCategoryId = (id, callback) => {
  return GetDataNR("/groupByFinancialCategory/" + id, null, callback);
};

// expenses&&revenues
export const GetExpensesRevenues = (callback) => {
  return GetDataNR("/Expense", {}, callback);
};
export const DeleteExpensesRevenues = (itemId, cb = null) => {
  return DeleteDataNR("/Expense", itemId, cb);
};
export const CreateExpensesRevenues = (item, cb = null) => {
  return PostDataNR("/Expense", item, cb);
};
export const UpdateExpensesRevenues = (item, callback) => {
  return PutData("/Expense/" + item.id, item, callback);
};

// quotas

export const GetQuotasList = (callback) => {
  return GetDataNR("/quotas", {}, callback);
};
export const DeleteQuota = (itemId, cb = null) => {
  return DeleteDataNR("/quotas", itemId, cb);
};
export const CreateQuota = (item, cb = null) => {
  return PostDataNR("/quotas", item, cb);
};
export const UpdateQuota = (item, callback) => {
  return PutData("/quotas/" + item.id, item, callback);
};

// NETIRRIG

export const GetNetirrigSols = (callback) => {
  return GetDataNR("/netirrig_sols", {}, callback);
};
export const CreateNetirrigSols = (item, cb = null) => {
  return PostDataNR("/netirrig_sols", item, cb);
};
export const UpdateNetirrigSols = (item, callback) => {
  return PutData("/netirrig_sols/" + item.id, item, callback);
};
export const DeleteNetirrigSols = (itemId, cb = null) => {
  return DeleteDataNR("/netirrig_sols", itemId, cb);
};

// METEO STATIONS

export const GetMeteoStations = (callback) => {
  return GetDataNR("/meteo_stations", {}, callback);
};

// Organization user
export const getOrganizationUsersList = (callback) => {
  return GetDataNR("/organization/users", {}, callback);
};
export const organizationAddUser = (item, callback) => {
  return PostDataNR("/organization/users", item, callback);
};
export const organizationDeleteUser = (itemId, cb) => {
  return DeleteDataNR("/organization/users", itemId, cb);
};
export const organizationUpdateUser = (item, callback) => {
  return PutData("/organization/users/" + item.id, item, callback);
};

// Organization partners

export const getOrganizationPartnersList = (callback) => {
  return GetDataNR("/organization/partners", {}, callback);
};
export const organizationAddPartners = (item, callback) => {
  return PostDataNR("/organization/partners", item, callback);
};
export const organizationDeletePartners = (itemId, cb) => {
  return DeleteDataNR("/organization/partners", itemId, cb);
};

// Organization-user partners

export const getOrganizationUserPartnersList = (callback) => {
  return GetDataNR("/organization-user/partners", {}, callback);
};
export const organizationAddUserPartners = (item, callback) => {
  return PostDataNR("/organization-user/partners", item, callback);
};
export const organizationUserDeletePartners = (itemId, cb) => {
  return DeleteDataNR("/organization-user/partners", itemId, cb);
};
export const getOrganizationUserJoinRequestsList = (callback) => {
  return GetDataNR("/organization-user/join/requests", {}, callback);
};
export const organizationUserSendJoinRequests = (item, callback) => {
  return PostDataNR("/organization-user/join/requests", item, callback);
};
export const organizationUserDeleteJoinRequests = (itemId, cb) => {
  return DeleteDataNR("/organization-user/join/requests", itemId, cb);
};

// Organization join requests

export const getOrganizationJoinRequestsList = (callback) => {
  return GetDataNR("/organization/join/requests", {}, callback);
};
export const organizationSendJoinRequests = (item, callback) => {
  return PostDataNR("/organization/join/requests", item, callback);
};
export const organizationDeleteJoinRequests = (itemId, cb) => {
  return DeleteDataNR("/organization/join/requests", itemId, cb);
};

// Partner Integrations

export const ListPartnerIntegrations = (callback) => {
  return GetDataNR("/user/integrations", {}, callback);
};
export const AddPartnerDevices = (item, cb = null) => {
  return PostDataNR("/user/integrations", item, [GetUserStatus, cb]);
};
export const UpdatePartnerDevices = (item, cb = null) => {
  return PutData(`/user/integrations/${item.id}`, item, [GetUserStatus, cb]);
};
export const ActivateIntegrations = (partnerId, item, callback) => {
  return PostDataNR(`/user/integrations/${partnerId}/activate`, item, callback);
};
export const DisableIntegrations = (partnerId, callback) => {
  return GetDataNR(`/user/integrations/${partnerId}/disable`, {}, callback);
};
export const GetPartnerSharedIntegrations = (callback) => {
  return GetDataNR("/user/integrations/shared", {}, callback);
};
export const GetPartnerIntegration = (partnerId, callback) => {
  return GetDataNR(`/user/integrations/${partnerId}`, {}, [
    GetUserStatus,
    callback,
  ]);
};
export const GetPartnerDevices = (partnerId, callback) => {
  return GetDataNR(`/user/integrations/${partnerId}/devices`, {}, callback);
};
export const DeletePartnerDevice = (itemId, cb = null) => {
  return DeleteDataNR("/user/integrations", itemId, [GetUserStatus, cb]);
};

export const GetSeabexPackByProvider = (provider, callback) => {
  return GetDataNR(`/seabexPackByProvider/${provider}`, {}, callback);
};

export const GetBayerAuthorizationUrl = (callback) => {
  return GetDataNR("/user/bayer/login", {}, callback);
};

export const GetMapImage = (id, callback) => {
  return GetDataNR(`/area/${id}/map`, null, callback);
};

export const GetStatsMapping = (callback) => {
  return GetDataNR(`/admin/stats/mapping`, null, callback);
};

export const GetSettings = (callback) => {
  return GetDataNR(`/settings`, null, callback);
};
export const GetSettingsByName = (name, callback) => {
  return GetDataNR(`/settings/${name}`, null, callback);
};
export const SaveSettings = (name, value, callback) => {
  return PostDataNR(`/settings`, { name, value }, callback);
};
export const DeleteSettingByName = (name, value, callback) => {
  return PostDataNR(`/settings/${name}`, { value }, callback);
};

export const CreatePolygon = (areaId, callback = null) => {
  return PostDataNR("/user/polygons", areaId, callback);
};

export const GetPolygonById = (areaId, callback) => {
  return GetDataNR(`/user/polygons/${areaId}`, {}, callback);
};

export const CreateTimeSeries = (
  areaId,
  dateFrom,
  dateTo,
  vegetationIndex,
  callback = null
) => {
  return PostDataNR(
    "/user/timeseries",
    { areaId, dateFrom, dateTo, vegetationIndex },
    callback
  );
};

export const GetUserData = (callback) => {
  return GetDataNR("/user/data", {}, callback);
};

export const GetTimeSerieById = (areaId, callback) => {
  return GetDataNR(`/user/timeseries/${areaId}`, {}, callback);
};
export const GetAllTimeSeries = (areaId, callback) => {
  return GetDataNR("/user/timeseries", { areaId }, callback);
};

export const GetObservationById = (areaId, callback) => {
  return GetDataNR(`/user/observations/${areaId}`, {}, callback);
};
export const GetAllObservations = (areaId, callback) => {
  return GetDataNR("/user/observations", { areaId }, callback);
};

export const CreateZonation = (zonation, cb = null) => {
  return PostDataNR("/user/zonations", zonation, cb);
};
export const GetZonationById = (areaId, callback) => {
  return GetDataNR(`/user/zonations/${areaId}`, {}, callback);
};
export const GetAllZonations = (areaId, callback) => {
  return GetDataNR("/user/zonations", { areaId }, callback);
};

export const processLatestsSatellite = (callback) => {
  return GetDataNR(`/admin/satellite/process`, {}, callback);
};
export const activateSatellite = (ids, cb = null) => {
  return PostDataNR("/admin/satellite/activate", { ids }, cb);
};
export const updateSatellite = (ids, cb = null) => {
  return PostDataNR("/admin/satellite/update", { ids }, cb);
};
