import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import allReducers from "./reducers";

// Return redux state in redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//create redux store and pass to it combined reducer and used middelware
export default createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunk))
);
