import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'; // Global First Component that contain the hole application
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';// our redux store which contain combined reducer and used middelware
import { create } from 'jss';


import { customTheme } from './App/globalStyle'
import {createTheme,ThemeProvider,jssPreset,StylesProvider } from '@material-ui/core/styles';

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

const jss = create({
    plugins: [...jssPreset().plugins],
  });

  const theme = createTheme(customTheme);
// let history = useHistory();

//we pass store to provider to intialize redux
ReactDOM.render(<StylesProvider jss={jss}><ThemeProvider theme={theme}><Provider store={store}><App /></Provider></ThemeProvider></StylesProvider>, document.getElementById('root'));
serviceWorker.unregister();
