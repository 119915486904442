import { combineReducers } from 'redux';
import app from './appReducer';
import farmer from './farmerReducer'
import dataType from './dataTypeReducer'
import dashboard from './dashboardReducer'
import area from './areaReducer'
import station from './stationsReducer'
import seabexModel from './seabexModelReducer'
//import expandable from './expandableReducer'
import irrigationPlan from './irrigationPlanReducer'
import task from './taskReducer'
import scenario from './scenarioReducer'
import ref from './refComponentReducer'
import equipments from './EquipmentReducer'
import formulas from './FormulaReducer'
import culture from './CultureReducer'
import organization from './organizationReducer'
import advice from './adviceReducer'
import institution from './InstitutionReducer'
import cycle from './cycleReducer'
import SoilTypeByFamily from './solsReducer'
import irrigationfamilies from './irrigationFamilyReducer'
import irrigationType from './irrigationTypeReducer'
import soilFamily from './soilFamilyReducer'
import waterNeedByArea from './waterNeedReducer'
import seabexPacks from './seabexPackReducer'
import seabexCategorieByPack from './seabexCategorieReducer'
import seabexStationByCategorie from './seabexStationByCategorieReducer'
import meteoDay from './meteoDayReducer'
import countries from './countriesReducer'
import cities from './citiesReducer'
import getAllUser from './allUserReducer'
import cultureByFamily from './cultureByFamily'
import getDataType from './sensorDataTypeReducer'
import delegations from './delegationsReducer'
import sector from './sectorReducer'
import equipmentFamily from './equipmentFamilyReducer'
import seabexCategorieByParentId from './seabexCategorieByParentIdReducer'
import deviceModelReducer from './deviceModelReducer'
import waterBalance from './waterNeedBalanceReducer'
import subscription from './subscriptionReducer'
import followers from './followersReducer'
import group from './groupReducer'
import _globalState from './_globalStateReducer'

export default combineReducers({
    _globalState,
    app,
    cycle,
    meteoDay
    // farmer,
    // dataType,
    // dashboard,
    // area,
    // station,
    // seabexModel,
    // // expandable,
    // irrigationPlan,
    // task,
    // scenario,
    // ref,
    // equipments,
    // formulas,
    // culture,
    // organization,
    // advice,
    // institution,
    // cycle,
    // SoilTypeByFamily,
    // irrigationfamilies,
    // irrigationType,
    // soilFamily,
    // waterNeedByArea,
    // seabexPacks,
    // seabexCategorieByPack,
    // seabexStationByCategorie,
    // meteoDay,
    // countries,
    // cities,
    // getAllUser,
    // cultureByFamily,
    // getDataType,
    // delegations,
    // sector,
    // equipmentFamily,
    // seabexCategorieByParentId,
    // deviceModelReducer,
    // waterBalance,
    // subscription,
    // followers,
    // group
});