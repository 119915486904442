import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { vocabulary } from "../../App/Strings";

const StyledInput = ({ ref, register, rules, name, errors, type, triggerValidation, styles, value, helperText, fontSize=12, innerRef=null, disableAutoComplete=false, inputFontSize=18,  ...properties }) => {

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(value)
    }, [value])

    const useStyle = makeStyles({
        root: {
            margin: 8,
            '& p': {
                fontSize: fontSize
            },
            ...(inputFontSize && { 
            '& input': {
                fontSize: inputFontSize
            }}),
            ...styles
        }
    })

    const checkErrors = (errorType) => {
        switch (errorType) {
            case 'required':
                return vocabulary.required
            case 'minLength':
                return vocabulary.minLength + rules.minLength

            default:
                return true
        }
    }

    const classes = useStyle();
    return (
        <TextField
        innerRef={ref}
            inputRef={register ? register(rules) : innerRef}
            {...properties}
            required={rules && rules.required}
            name={name}
            type={type && type}
            className={classes.root}
            error={errors ? true : false}
            helperText={errors ? checkErrors(errors.type) : helperText}
            value={inputValue} 
            {...(disableAutoComplete && {InputProps:{readOnly:true}, onFocus:(e) => e.target.removeAttribute('readonly')})}
        />
    )
}

export default StyledInput