
/* eslint-disable  */

import * as types from './actionTypes';
import * as cruds from './cruds';
import ApolloClient from 'apollo-boost';
import { updateLanguage } from '../globalsTools/network';

const seabexDataEndpoint = new ApolloClient({
    // uri: 'https://api.github.com/graphql',
    // uri: types.GLOBAL_URL + '/graphql',
    request: operation => {
        operation.setContext({
            headers: {
                // to secure token we can imported from created file env :  ${process..GITHUB_PERSONAL_ACCESS_TOKEN}
                //  in our case : getState().app.token or JSON.parse(localStorage.getItem('login_Token')) 
                authorization: `Bearer ` + JSON.parse(localStorage.getItem('login_Token')),
            },
        });
    },
});

/**
 * n:any -> Action Selector
 * costumQuery:DocumentNode -> Quoted String 
 * cb:function -> Callback Function
 */
export const getDataFromGraphQl = (n, costumQuery, variableObj, cb = null) => {
    return function (dispatch, getState) {




        dispatch({ type: types.APP_LOADING, payload: true });
        seabexDataEndpoint
            .query({

                query: costumQuery,
                variables: variableObj
            })
            .then(function (r) {
                dispatch({
                    type: n == "profile" ? n : n + types.GET_ITEMS,
                    payload: r.data,
                    iserror: false,
                });
                dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading)
                //dispatchSucess(dispatch);//call dispatch sucess to show sucess message action  // walid remove the alert dispatch
                if (cb) { setTimeout(function () { cb(r); }, 10); }
            })
            .catch(function (r) {
                dispatchAlert(dispatch, 'Erreur serveur : ' + (r.response ? r.response.status : ''));//call dispatchAlert action 
                if (cb) { setTimeout(function () { cb(r.response); }, 10); }
            })
    }
}

export const toggleDrawer = (trueorfalse) => {
    return {
        type: types.TOGGLE_DRAWER,
        payload: trueorfalse
    }
}

export const dispatchAlert = (dispatch, message) => {
    dispatch({
        type: types.TOGGLE_ALERT,
        payload: message,
        etat: 2
    });
}

export const showAlert = (message) => {
    return function (dispatch, getState) {
        dispatchAlert(dispatch, message);
    }
}

export const dispatchSucess = (dispatch, message) => {
    dispatch({
        type: types.TOGGLE_ALERT,
        payload: message,
        etat: 1
    });
}

export const showSucess = (message) => {
    return function (dispatch, getState) {
        dispatchAlert(dispatch, message);
    }
}

export const hideAlertAndSucess = () => {
    return function (dispatch, getState) {
        dispatch({
            type: types.TOGGLE_ALERT,
            payload: '',
            etat: null
        });
    }
}



export const updateLocationMap = (currentLocation) => {
    return {
        type: "parkingCrud" + types.UPDATE_LOCATION_MAP,
        payload: currentLocation
    }
}


export const toggleDialogAddRule = (row, index) => {
    return {
        type: "rule" + types.TOGGLE_ADD_DIALOG,
    }
}

export const setToken = (loginToken) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "token": loginToken
        },
        iserror: false
    }
}

export const setUserid = (userid) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "userid": userid
        },
        iserror: false
    }
}

export const setCreatedAt = (created) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "created_at": created
        },
        iserror: false
    }
}

export const setUserRights = (rights) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "user_type": rights
        },
        iserror: false
    }
}

export const setUsername = (username) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "username": username
        },
        iserror: false
    }
}
export const setLanguage = (language) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "language": language
        },
        iserror: false
    }
}

export const syncAuth = (company_name, loginToken, userid, rights, username, currentLanguage) => {
    return {
        type: 'app_session' + types.CREATE_ITEM,
        payload: {
            "username": username,
            "user_type": rights,
            "userid": userid,
            "token": loginToken,
            "currentLanguage": currentLanguage,
            "company_name": company_name


        },
        iserror: false
    }
}


export const startImpersonating = (impersonate) => {
    return {
        type: types.START_IMPERSONATING,
        payload: {
            impersonate
        },
    }
}


export const leaveImpersonating = () => {
    return {
        type: types.LEAVE_IMPERSONATING,
    }
}


export const disconnectSession = () => {
    return {
        type: 'disconnect',
        iserror: false
    }
}



export const setCurrentPage = (currentPage) => {
    return {
        type: 'SET_CURRENTPAGE',
        payload: currentPage,
    }


    // return  dispatch => {
    //     setTimeout(() => {
    //       // Yay! Can invoke sync or async actions with `dispatch`
    //       dispatch({
    //         type: 'SET_CURRENTPAGE',
    //         payload: currentPage,
    //     });
    //     }, 3000);
    //   };

}

export const setCurrentLanguage = (language) => {
    updateLanguage(language)
    return {
        type: 'SET_CURRENTLANGUAGE',
        payload: language,
    }
}

export const loadFarmers = (cb = null) => {
    return cruds.getData('farmer' + types.GET_ITEMS, "/farmers", cb);
}


export const getStaticNodeModel = (selectedNodeIdFromDropdownList) => {
    return {
        type: 'GET_STATIC_NODE_MODEL',
        selectedNodeIdFromDropdownList: selectedNodeIdFromDropdownList,
    }
}

export const loadLogs = (type, nodeId, sensor, dateFrom, dateTo) => {
    return cruds.getData('log' + types.GET_ITEMS, "/log/" + type + "/" + nodeId + "/" + sensor + "/" + dateFrom + "/" + dateTo);
}

export const loadLogsDashboard = (nodeId, sensorType, model, dateFrom, dateTo, page) => {
    return cruds.getData('dashboard' + types.GET_ITEMS, "/datas/getdatafield?device_id=408BD13F&sensor=" + model + "&field=" + sensorType + "&fromDate=" + dateFrom + "&endDate=" + dateTo);
}

export const getStationbyGateway = (gatewayId) => {
    return cruds.getData('dashboard' + 'GET_STATION_GATEWAY', "/stationbygateway?id=" + gatewayId);
}



// 301 --> / zeyda :P
export const loginUser = (o, cb = null) => {
    // lorsque l'url est introuvable 404 request method will be automaticly OPTION
    return cruds.postData('app_session', "/login", o, cb);
}

export const signUpUser = (o, cb = null) => {
    // lorsque l'url est introuvable 404 request method will be automaticly OPTION
    return cruds.postData('app_session', "/register", o, cb);
}

export const setLeafletFgRef = (ref) => {
    return {
        type: 'LeafletFgRef',
        payload: ref
    }
}


export const closeEditParcelDialog = () => {
    return { type: 'area' + 'CLOSE_EDIT_PARCEL', }
}

export const openEditParcelDialog = () => {
    //  we can pass selected parcel here or we can laod it from server ( I think it's better to passit here)
    return { type: 'area' + 'OPEN_EDIT_PARCEL' }
}

export const resetUserPassword = (o, cb = null) => {
    // lorsque l'url est introuvable 404 request method will be automaticly OPTION
    return cruds.postData('reset_password', "/password/create", o, cb);
}

export const newUserPassword = (o, cb = null) => {
    // lorsque l'url est introuvable 404 request method will be automaticly OPTION
    return cruds.postData('reset_password', "/password/reset", o, cb);
}

export const setSelectedArea = (area) => {
    //  we can pass selected parcel here or we can laod it from server ( I think it's better to passit here)
    return {
        type: 'area' + types.SELECT_ITEM,
        payload: area
    }
}

// export const setSelectedAreaToParent = (parentId, type) => {
//     return {
//         type: 'area' + types.SELECT_ITEM + 'TO_PARENT',
//         parentId: parentId,
//         itemType: type
//     }
// }

export const setMapRef = (mapRef) => {
    return {
        type: 'area' + 'set_map_ref',
        payload: mapRef
    }
}

export const setFgRef = (ref) => {
    return {
        type: 'area' + 'set_FG',
        payload: ref
    }
}




export const setFeatureLayerObject = (featureLayerObject) => {
    return {
        type: 'area' + 'featureLayer_Object',
        payload: featureLayerObject
    }
}



export const setExpanded = (areaId) => {
    return {
        type: 'expandable' + 'set_expanded',
        payload: areaId
    }
}

export const setEditControlRef = (edit) => {
    return {
        type: 'area' + 'edit_control_ref',
        payload: edit
    }
}

export const setActiveItemPopupForm = (key, value) => {
    return {
        type: 'area' + 'set_active_item_data',
        key: key,
        value: value
    }
}


export const isAddingArea = (value) => {
    return {
        type: 'nothing',

    }
}



export const isEditingArea = (value) => {
    return {
        type: 'area' + 'SET_EDITING',
        payload: value
    }
}


// Now we can combine them like this for two reason 1-to show ordred item by server  2- to force reload ( if two user are updating ..)
export const createAreaItemThenLoadArea = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createAreaItem(item, cb)).then(() => {
            if (item.parent_id) {
                return dispatch(loadItemAndChilds(item.parent_id))
            }
            else {
                return dispatch(loadSitesToExpandable())
            }

            // return dispatch(loadSitesToExpandable())
        })
    }
}

// Now we can combine them like this for two reason 1-to show ordred item by server  2- to force reload ( if two user are updating ..)
export const deleteItemThenLoadArea = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(deleteAreaItem(item.id, cb)).then(() => {
            if (item.parent_id) {
                return dispatch(loadItemAndChilds(item.parent_id))
            }
            else {
                return dispatch(loadSites())
            }
            // return dispatch(loadSitesToExpandable())
        })
    }
}


// Now we can combine them like this for two reason 1-to show ordred item by server  2- to force reload ( if two user are updating ..)
export const deleteItemThenLoadExpandableArea = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(deleteAreaItem(item.id, cb)).then(() => {
            return dispatch(loadSitesToExpandable())
        })
    }
}



// Now we can combine them like this for two reason 1-to show ordred item by server  2- to force reload ( if two user are updating ..)
export const updateItemThenLoadArea = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateAreaItem(item, cb)).then(() => {
            if (item.parent_id) {
                return dispatch(loadSitesToExpandable())
            }
            else {
                return dispatch(loadSitesToExpandable())
            }
        })

    }
}




export const loadItemAndChildsThenFlyToBounds = (id, parent_id, cb = null) => {
    // return cruds.getAreaChildCrud('area', "/Area/" + id, cb);
    return (dispatch, getState) => {

        return dispatch(loadItemAndChilds(id, cb)).then(() => {
            let { mapRef, featureLayerObject, FGRef } = getState().area
            if (parent_id) {
                mapRef.flyToBounds(featureLayerObject[parent_id].getBounds());
            }
            else {
                mapRef.flyToBounds(FGRef.leafletElement.getBounds());
            }
        })
    }
}



export const loadParentThenFlyToBounds = (parentID, parent_id, cb = null) => {
    return (dispatch, getState) => {
        let { mapRef, featureLayerObject } = getState().area
        return dispatch(setSelectedAreaFromServer(parentID, cb)).then(() => {
            mapRef.flyToBounds(featureLayerObject[parentID].getBounds());
            featureLayerObject[parentID].openPopup()
        })
    }
}


export const loadSitesThenFlyToBounds = (cb = null) => {
    return (dispatch, getState) => {
        return dispatch(loadOnlySites()).then(() => {
            let { mapRef, FGRef } = getState().area
            mapRef.flyToBounds(FGRef.leafletElement.getBounds());
        })
    }
}





export const createAreaItem = (item, cb = null) => {
    return cruds.postData('area', "/Area", item, cb);
}

export const deleteAreaItem = (itemId, cb = null) => {
    return cruds.deleteData('area', "/Area/", itemId, cb);
}

export const updateAreaItem = (item, cb = null) => {
    return cruds.putData('area', '/Area/', item.id, item, cb);
}

export const loadItemAndChilds = (id, cb = null) => {
    return cruds.getData('area' + 'child', "/Area/" + id, cb);
}


export const loadItemAndChildsThenOpenPopup = (id, cb = null) => {
    return (dispatch, getState) => {
        // let { mapRef, featureLayerObject, FGRef } = getState().area
        return dispatch(loadItemAndChilds(id, cb)).then(() => {
            //  attetion getState must be called after dispatch to get the latest state otherwise it will not wortk 
            let { mapRef, featureLayerObject } = getState().area
            if (featureLayerObject[id]) {
                mapRef.flyToBounds(featureLayerObject[id].getBounds());
                featureLayerObject[id].openPopup()
            }

        })
    }
}



export const setSelectedAreaFromServer = (id, cb = null) => {
    return cruds.getAreaChildToupdateSelectedArea('area' + types.SELECT_ITEM, "/Area/" + id, cb);
}



/**
 * Get Initially All Sites
 * @param {function} cb CallBack function to call on success.
 */
export const loadSites = (f = '', paging = '', cb = null) => {
    return cruds.getData('area' + types.GET_ITEMS, "/Areas", cb);
}

export const loadOnlySites = (f = '', paging = '', cb = null) => {
    return cruds.getData('area' + types.GET_ITEMS, "/Area", cb);
}


// get intially all Sites
export const loadSitesToExpandable = (f = '', paging = '', cb = null) => {
    return cruds.getData('expandable' + types.GET_ITEMS, "/Areas", cb);
}




export const loadItemAndChildsToExpandable = (id, cb = null) => {
    return cruds.getData('expandable' + 'child', "/Area/" + id, cb);
}


export const createAreaItemToExpandable = (item, cb = null) => {
    return cruds.postData('expandable', "/Area", item, cb);
}

export const deleteAreaItemToExpandable = (itemId, cb = null) => {
    return cruds.deleteData('expandable', "/Area/", itemId, cb);
}

export const updateAreaItemToExpandable = (item, cb = null) => {
    return cruds.putData('expandable', '/Area', item.id, item, cb);
}



export const setNotfication = (msg) => {
    return {
        type: types.TOGGLE_ALERT,
        payload: msg,
        etat: 3
    }
}

export const displayWarning = (msg) => {
    return {
        type: types.TOGGLE_ALERT,
        payload: msg,
        etat: 4
    }
}



export const setActuator = (port, status, cb = null) => {
    return {
        type: 'mqtt',
    }
    /** TODO */
    // return cruds.customPost( {Destination:"ALL",Message: "A:R"+port+"/"+status+"**"+"A:R"+port+"/"+status} ); 
    // return cruds.customPost({ Destination: "ALL", Message: "A:R" + port + "/" + status });
}
// Stations
export const createStation = (item, cb = null) => {
    return cruds.postData('station', "/DeployedDevice", item, cb);
}
export const loadStations = (f = '', paging = '', cb = null) => {
    return cruds.getData('station' + types.GET_ITEMS, "/DeployedDevice", cb);
}
export const deleteStationItem = (itemId, cb = null) => {
    return cruds.deleteData('station', "/DeployedDevice/", itemId, cb);
}
export const updateStationItem = (o, itemId, cb = null) => {
    return cruds.putData('station', '/DeployedDevice/', itemId, o, cb);
}

export const updateStationItemThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateStationItem(o, itemId, cb = null)).then(() => {
            return dispatch(loadStations())
        })
    }
}

export const loadSeabexModel = (f = '', paging = '', cb = null) => {
    return cruds.getData('seabexModel' + types.GET_ITEMS, "/seabexstations", cb);
}




export const createCronTaskItem = (item, cb = null) => {
    return cruds.postData('task', "/Task", item, cb);
}
export const loadIrrigationPlanTasks = (id, paging = '', cb = null) => {
    return cruds.getData('task' + types.GET_ITEMS, "/rules/" + id, cb);
}
export const deleteCronTaskItem = (itemId, cb = null) => {
    return cruds.deleteData('task', "/Task/", itemId, cb);
}
export const updateCronTaskItem = (o, itemId, cb = null) => {
    return cruds.putData('task', '/Task/', itemId, o, cb);
}

//  to do refresh after delete and after add
export const deleteIrrigationPlanRules = (id, paging = '', cb = null) => {
    return cruds.getData('task' + types.GET_ITEMS, "/destroyRules/" + id, cb);
}






export const loadIrrigationPlans = (f = '', paging = '', cb = null) => {
    return cruds.getData('irrigationPlan' + types.GET_ITEMS, "/AutomationPlan", cb);
}

export const updateIrrigationPlanData = (glossaire) => {
    return {
        type: "irrigationPlan" + types.UPDATE_DATA,
        payload: glossaire
    }
}

export const createIrrigationPlan = (item, cb = null) => {
    return cruds.postData('irrigationPlan', "/AutomationPlan", item, cb);
}

export const deleteIrrigationPlan = (itemId, cb = null) => {
    return cruds.deleteData('irrigationPlan', "/AutomationPlan/", itemId, cb);
}


export const updateIrrigationPlan = (o, itemId, cb = null) => {
    return cruds.putData('irrigationPlan', '/AutomationPlan/', o.id, o, cb);

}

export const updateGetWayPlan = (id, cb = null) => {
    return cruds.postData('nothing', "/syncTasks", { "automationplan_id": id }, cb);
}

export const deleteGetWayPlan = (id, cb = null) => {
    return cruds.postData('nothing', "/syncTasks", { "automationplan_id": id }, cb);
}


export const sendScenario = (scenario, cb = null) => {
    return cruds.postData('scenario', "/NewScenario",
        scenario
        , cb);
}


export const getScenarios = (cb = null) => {
    return cruds.getData('scenario' + types.GET_ITEMS, "/Scenario", cb);
}

export const dropScenarios = (cb = null) => {
    return cruds.deleteData('scenario', "/Scenario", '', cb);
}





export const getLocalSiteNode = (cb = null) => {
    // return cruds.getLocalNodeCruds('log' + 'GET_LOCAL_NODE', "/GetNodes", cb);
    return { type: "test" }
}

export const getSensorVariable = (sensor, cb = null) => {
    // return cruds.getSensorVarialbesCruds('scenario' + 'GET_SENSOR_VARIABLE', "/GetSensorVariable/" + sensor, cb);
    return { type: "test" }
}


export const setToolbar = (toolbar) => {
    return {
        type: "ref" + "TOOLBAR",
        payload: toolbar
    }
}


// Scenario 
export const loadScenario = (f = '', paging = '', cb = null) => {
    return cruds.getData('scenario' + types.GET_ITEMS, "/Scenario", cb);
}

export const navigateScenario = (paging, cb = null) => {
    return cruds.getData('scenario' + types.GET_ITEMS, "/Scenario?page=" + paging, cb);
}

export const createScenario = (item, cb = null) => {
    return cruds.postData('scenario', "/Scenario", item, cb);
}
export const createScenarioThenLoad = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createScenario(item, cb)).then(() => {
            return dispatch(loadScenario())
        })
    }
}

export const deleteScenario = (itemId, cb = null) => {
    return cruds.deleteData('scenario', "/Scenario/", itemId, cb);
}

export const updateScenario = (o, itemId, cb = null) => {
    return cruds.putData('scenario', '/Scenario/', o.id, o, cb);

}
export const updateScenarioThenLoad = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateScenario(o, itemId, cb = null)).then(() => {
            return dispatch(loadScenario())
        })
    }
}

export const setScenariosPriority = (item, cb = null) => {
    return cruds.postData('scenario', "/SenarioPriority", item, cb);
}


// Now we can combine them like this for two reason 1-to show ordred item by server  2- to force reload ( if two user are updating ..)
export const setScenariosPriorityThenLoadData = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(setScenariosPriority(item, cb)).then(() => {
            return dispatch(loadScenario())
        })
    }
}

export const laodStationDataTypes = (stationId, f = '', paging = '', cb = null) => {
    return cruds.getData('dataType' + types.GET_ITEMS, "/stationDataTypes/" + stationId, cb);
}

export const createFormulaItem = (item, cb = null) => {
    return cruds.postData('formula', "/Formula", item, cb);
}

export const loadUserFormulas = (f = '', paging = '', cb = null) => {
    return cruds.getSpecData('formula', "/UserFormula", cb);
}

export const loadFormulas = (f = '', paging = '', cb = null) => {
    return cruds.getData('formula' + types.GET_ITEMS, "/Formula", cb);
}

export const deleteFormula = (itemId, cb = null) => {
    return cruds.deleteData('formula', "/Formula/", itemId, cb);
}

export const updateFormula = (o, itemId, cb = null) => {
    return cruds.putData('formula', "/Formula/", o.id, o, cb);
}



export const attachFormulaToStation = (item, cb = null) => {
    return cruds.postData('formula', "/AttachFormula", item, cb);
}

export const checkUsersNumber = (item, cb = null) => {
    return cruds.postData('advice', "/AdviceUsers", item, cb);
}

export const saveAdvice = (item, cb = null) => {
    return cruds.postData('advice', "/Advice", item, cb);
}

export const getAllAdvices = (f = '', paging = '', cb = null) => {
    return cruds.getData('advice' + types.GET_ITEMS, "/Advice", cb);
}
// Followers
export const getOrganizationFollowers = (f = '', paging = '', cb = null) => {
    return cruds.getData('followers' + types.GET_ITEMS, "/Followers", cb);
}

export const newFollower = (item, cb = null) => {
    return cruds.postData('organization', "/Follower", item, cb);
}

export const newFollowerThenLoadData = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(newFollower(item, cb = null)).then(() => {
            return dispatch(getOrganizationFollowers())
        })
    }
}

export const unfollowMember = (item, cb = null) => {
    return cruds.deleteData('organization', "/unfollow/", item, cb);
}

export const unfollowMemberThenLoadData = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(unfollowMember(item, cb = null)).then(() => {
            return dispatch(getOrganizationFollowers())
        })
    }
}

// organizations


export const getInstitutionOrganizations = (f = '', paging = '', cb = null) => {
    return cruds.getData('institution' + types.GET_ITEMS, '/ListOrganizations', cb);
}

export const getAllOrganizations = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/Organization', cb);
}

export const CreateOrganization = (item, cb = null) => {
    return cruds.postData('organization', "/Organization", item, cb);
}

export const deleteOrganization = (item, cb = null) => {
    return cruds.deleteData('organization', "/Organization/", item, cb);
}

export const updateOrganisme = (o, itemId, cb = null) => {

    return cruds.putData('organization', "/Organization/", o.id, o, cb);
}
export const disableEnableOrganisme = (o, itemId, cb = null) => {

    return cruds.putData('organization', "/Organization/", itemId, o, cb);
}
export const getAvailableOrganizations = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/ActiveOrganizations', cb);
}

export const followingOrganisme = (item, cb = null) => {
    return cruds.postData('organization', "/followeOrganization", item, cb);
}

export const getMyFollowedOrganizations = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/getMyOrganizations', cb);
}
export const unfollowOrganisme = (item, cb = null) => {
    return cruds.postData('organization', "/unfolloweOrganization", item, cb);
}

export const getMyOrganizations = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/getMyOrganizationsandFollowers', cb);
}

export const sendMessage = (item, cb = null) => {
    return cruds.postData('advice', "/sendNotification", item, cb);
}

export const loadDataStatistics = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/statistics', cb);
}
// agent
export const getMyAgents = (f = '', paging = '', cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/OrganizationAgents', cb);
}

export const updateAgent = (o, itemId, cb = null) => {
    return cruds.putData('organization', "/UpdateAgent/", itemId, o, cb);
}
export const updateAgentThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateAgent(o, itemId, cb = null)).then(() => {
            return dispatch(getMyAgents())
        })
    }
}
export const CreateAgent = (item, cb = null) => {
    return cruds.postData('organization', "/CreateAgent", item, cb);
}

export const getMyAgentsNotifications = (f = '', paging = '', cb = null) => {
    return cruds.getData('advice' + types.GET_ITEMS, '/AgentsOrganizationNotifs', cb);
}

export const getSpecificOrganisme = (organisme, cb = null) => {
    return cruds.getData('organization' + types.GET_ITEMS, '/SpecificOrganizations/' + organisme, cb);
}

// cycleCycle
export const loadCycle = (f = '', paging = '', cb = null) => {
    return cruds.getData('cycle' + types.GET_ITEMS, "/ProductionCycle", cb);
}


export const createCycle = (item, cb = null) => {
    return cruds.postData('cycle', "/ProductionCycle", item, cb);
}
export const createCycleThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createCycle(item, cb = null)).then(() => {
            return dispatch(loadCycle())
        })
    }
}

export const deleteCycle = (itemId, cb = null) => {
    return cruds.deleteData('cycle', "/ProductionCycle/", itemId, cb);
}

export const updateCycle = (o, itemId, cb = null) => {
    return cruds.putData('cycle', '/ProductionCycle/', o.id, o, cb);

}
export const EndCycle = (itemId, endDate, cb = null) => {
    return cruds.putData('cycle', "/enddate?id=", itemId + "&end_date=" + endDate, cb);
}



export const updateCycleThenLoadCycle = (itemId, endDate, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(EndCycle(itemId, endDate, cb)).then(() => {
            if (itemId) {
                return dispatch(loadCycle())
            }
            else {
                return dispatch(loadCycle())
            }
        })

    }
}

// waterNeed

export const getWaterNeedByParcelId = (parcelId) => {
    return cruds.getData('waterNeedbyArea' + types.GET_ITEMS, "/waterNeedByAreaId?area_id=" + parcelId);
}
export const getWaterBalance = (parcelId) => {
    return cruds.getData('waterBalance' + types.GET_ITEMS, "/WaterBalance/" + parcelId);
}

//
export const seabexCategorieByPack = (packId) => {
    return cruds.getData('seabexCategorieByPack' + types.GET_ITEMS, "/seabexcategoriebypack?id_pack=" + packId);
}
export const seabexStationByCategorie = (categorieId) => {
    return cruds.getData('seabexStationByCategorie' + types.GET_ITEMS, "/seabexstationbycategorie?id_categorie=" + categorieId);

}
export const getMeteoPerArea = (areaId) => {
    return cruds.getData('meteoDay' + types.GET_ITEMS, "/meteosDateWeek?area_id=" + areaId)
}

export const loadAllCountries = () => {
    return cruds.getData('countries' + types.GET_ITEMS, "/country");
}
export const loadAllCountriesWithBoundry = () => {
    return cruds.getData('countries' + types.GET_ITEMS, "/country?map=true")
}
export const loadAllCountriesWithOutBoundry = () => {
    return cruds.getData('countries' + types.GET_ITEMS, "/country?map=false")
}
export const loadCitiesByIdCountries = (idCountrie) => {
    return cruds.getData('cities' + types.GET_ITEMS, "/governates/country?prm_country_id=" + (idCountrie) + "&map=true");
}
export const loadCitiesByIdCountriesWithBoundry = (idCountrie) => {
    return cruds.getData('cities' + types.GET_ITEMS, "/governates/country?prm_country_id=" + (idCountrie) + "&map=true");
}
export const loadDelegationByIdGovernates = (idGovernates) => {
    return cruds.getData('delegations' + types.GET_ITEMS, "/delegations?Governate=" + (idGovernates) + "&map=true");
}
export const loadSectorByIdDelegations = (idDelegations) => {
    return cruds.getData('sector' + types.GET_ITEMS, "/sectors?Delegation=" + (idDelegations) + "&map=true");
}
export const loadAllUser = () => {
    return cruds.getData('getAllUser' + types.GET_ITEMS, "/allUser");
}

export const createUserThenReload = (o, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(signUpUser(o, cb)).then(() => {
            return dispatch(loadAllUser())
        })
    }
}

// irrigationFamily

export const loadIrrigationFamily = (f = '', paging = '', cb = null) => {
    return cruds.getData('irrigationfamilies' + types.GET_ITEMS, "/PrmIrrigationFamily", cb);
}

export const createIrrigationFamily = (item, cb = null) => {
    return cruds.postData('irrigationfamilies', "/PrmIrrigationFamily ", item, cb);
}

export const createIrrigationFamilyThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createIrrigationFamily(item, cb = null)).then(() => {
            return dispatch(loadIrrigationFamily())
        })
    }
}
export const updateIrrigationFamily = (o, itemId, cb = null) => {

    return cruds.putData('irrigationfamilies', '/PrmIrrigationFamily/', o.id, o, cb);
}
export const updateIrrigationFamilyThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateIrrigationFamily(o, itemId, cb = null)).then(() => {
            return dispatch(loadIrrigationFamily())
        })
    }
}

export const deleteIrrigationFamily = (itemId, cb = null) => {
    return cruds.deleteData('irrigationfamilies', "/PrmIrrigationFamily/", itemId, cb);
}

// irrigationType

export const getIrrigationTypeByFamily = (irrigationId) => {
    return cruds.getData('irrigationType' + types.GET_ITEMS, "/PrmIrrigationType?id=" + irrigationId);
}


export const createIrrigationTypeByFamily = (item, cb = null) => {

    return cruds.postData('irrigationType', "/PrmIrrigationType ", item, cb);
}
export const createIrrigationTypeByFamilyThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createIrrigationTypeByFamily(item, cb = null)).then(() => {
            return dispatch(loadIrrigationFamily())
        })
    }
}
export const deleteIrrigationTypeByFamily = (itemId, cb = null) => {
    return cruds.deleteData('irrigationType', "/PrmIrrigationType/", itemId, cb);
}

export const updateIrrigationType = (o, itemId, cb = null) => {

    return cruds.putData('irrigationType', '/PrmIrrigationType/', o.id, o, cb);
}

//soilFamilyTexture
export const getSoilFamily = (f = '', paging = '', cb = null) => {
    return cruds.getData('soilFamily' + types.GET_ITEMS, "/PrmTextureSol", cb);
}

export const createSoilTextureFamily = (item, cb = null) => {
    return cruds.postData('soilFamily', "/PrmTextureSol ", item, cb);
}
export const createSoilTextureFamilyThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createSoilTextureFamily(item, cb = null)).then(() => {
            return dispatch(getSoilFamily())
        })
    }
}
export const deleteSoilTextureFamily = (itemId, cb = null) => {
    return cruds.deleteData('soilFamily', "/PrmTextureSol/", itemId, cb);
}

export const updateSoilTextureFamily = (o, itemId, cb = null) => {

    return cruds.putData('soilFamily', '/PrmTextureSol/', o.id, o, cb);
}
export const updateSoilTextureFamilyThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateSoilTextureFamily(o, itemId, cb = null)).then(() => {
            return dispatch(getSoilFamily())
        })
    }
}

// SoilType

export const getSoilTypeByFamily = (familyId) => {
    return cruds.getData('SoilTypeByFamily' + types.GET_ITEMS, "/SolByTexture?id=" + familyId);
}
export const createSoilTypeByFamily = (item, cb = null) => {

    return cruds.postData('SoilType', "/Sol ", item, cb);
}
export const createSoilTypeByFamilyThenReload = (item, cb = null, familyId) => {
    return (dispatch, getState) => {
        return dispatch(createSoilTypeByFamily(item, cb = null)).then(() => {
            return dispatch(getSoilTypeByFamily(familyId))
        })
    }
}
export const deleteSoilTypeByFamily = (itemId, cb = null) => {
    return cruds.deleteData('SoilType', "/Sol/", itemId, cb);
}
export const updateSoilTypeByFamily = (o, itemId, cb = null) => {

    return cruds.putData('SoilType', '/Sol/', o.id, o, cb);
}
// cultureFamily
export const loadFamille = (f = '', paging = '', cb = null) => {
    return cruds.getData('cycle' + 'FAMILIES', "/PrmCultureFamily", cb);
}

export const createCultureFamily = (item, cb = null) => {
    return cruds.postData('Family', "/PrmCultureFamily ", item, cb);
}
export const createCultureFamilyThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createCultureFamily(item, cb = null)).then(() => {
            return dispatch(loadFamille())
        })
    }
}
export const deleteCultureFamily = (itemId, cb = null) => {
    return cruds.deleteData('Family', "/PrmCultureFamily/", itemId, cb);
}
export const deleteCultureFamilyThenReload = (itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(deleteCultureFamily(itemId, cb = null)).then(() => {
            return dispatch(loadFamille())
        })
    }
}
export const updateCultureFamily = (o, itemId, cb = null) => {

    return cruds.putData('Family', '/PrmCultureFamily/', o.id, o, cb);
}
export const updateCultureFamilyThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateCultureFamily(o, itemId, cb = null)).then(() => {
            return dispatch(loadFamille())
        })
    }
}

export const getAllCultures = (f = '', paging = '', cb = null) => {
    return cruds.getData('culture' + types.GET_ITEMS, "/PrmCulture", cb);
}


//cultureType

export const getCultureByFamily = (familyId) => {
    return cruds.getData('cultureByFamily' + types.GET_ITEMS, "/culturesByFamily?id=" + familyId);
}

export const createCultureByFamily = (item, cb = null) => {
    return cruds.postData('cultureByFamily', "/Culture ", item, cb);
}
export const deletCultureByFamily = (itemId, cb = null) => {
    return cruds.deleteData('cultureByFamily', "/Culture/", itemId, cb);
}
export const updateCultureByFamily = (o, itemId, cb = null) => {

    return cruds.putData('cultureByFamily', '/Culture/', o.id, o, cb);
}
// seabexPack

export const loadSeabexModelPacks = () => {
    return cruds.getData('seabexPacks' + types.GET_ITEMS, "/SeabexPack");
}
export const createSeabexPack = (item, cb = null) => {
    return cruds.postData('seabexPacks', "/SeabexPack ", item, cb);
}
export const createSeabexPackThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createSeabexPack(item, cb = null)).then(() => {
            return dispatch(loadSeabexModelPacks())
        })
    }
}
export const deleteSeabexPack = (itemId, cb = null) => {
    return cruds.deleteData('seabexPacks', "/SeabexPack/", itemId, cb);
}

export const updateSeabexPack = (o, itemId, cb = null) => {

    return cruds.putData('seabexPacks', '/SeabexPack/', o.id, o, cb);
}
export const updateSeabexPackThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateSeabexPack(o, itemId, cb = null)).then(() => {
            return dispatch(loadSeabexModelPacks())
        })
    }
}
//seabexModel 
export const loadSeabexCategoryModel = (id) => {
    return cruds.getData('deviceModelReducer' + types.GET_ITEMS, "/SeabexModels?seabex_category_id=" + id)
}

export const createDeviceModel = (item, cb = null) => {

    return cruds.postData('deviceModelReducer', "/SeabexModel ", item, cb);
}
//seabexCategory

export const LoadseabexCategorie = () => {
    return cruds.getData('seabexCategorieByPack' + types.GET_ITEMS, "/SeabexCategories")
}

export const LoadseabexCategorieByParentId = (parentId) => {
    return cruds.getData('seabexCategorieByParentId' + types.GET_ITEMS, "/SeabexCategories?parent_id=" + parentId)
}

export const createSeabexCategory = (item, cb = null) => {

    return cruds.postData('seabexPacks', "/SeabexCategorie ", item, cb);
}
export const createSeabexCategoryThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createSeabexCategory(item, cb = null)).then(() => {
            return dispatch(LoadseabexCategorie())
        })
    }
}
export const deleteSeabexCategory = (itemId, cb = null) => {
    return cruds.deleteData('seabexPacks', "/SeabexCategorie/", itemId, cb);
}
export const updateSeabexCategory = (o, itemId, cb = null) => {

    return cruds.putData('seabexPacks', '/SeabexCategorie/', o.id, o, cb);
}
export const updateSeabexCategoryThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateSeabexCategory(o, itemId, cb = null)).then(() => {
            return dispatch(LoadseabexCategorie())
        })
    }
}
export const deleteSeabexCategoryThenReload = (itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(deleteSeabexCategory(itemId, cb = null)).then(() => {
            return dispatch(LoadseabexCategorie())
        })
    }
}
//SeabexStationByCategory

export const createSeabexStationByCategory = (item, cb = null) => {

    return cruds.postData('seabexStationByCategorie', "/SeabexStation ", item, cb);
}
export const deleteSeabexStationByCategory = (itemId, cb = null) => {
    return cruds.deleteData('seabexStationByCategorie', "/SeabexStation/", itemId, cb);
}
export const updateSeabexStationByCategory = (o, itemId, cb = null) => {

    return cruds.putData('seabexStationByCategorie', '/SeabexStation/', o.id, o, cb);
}
// EquipmentFamily
export const loadEquipmentFamily = () => {

    return cruds.getData('equipmentFamily' + types.GET_ITEMS, "/PrmEquipmentFamily");
}
export const createEquipmentFamily = (item, cb = null) => {
    return cruds.postData('equipmentFamily', "/PrmEquipmentFamily ", item, cb);
}
export const createEquipmentFamilyThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createEquipmentFamily(item, cb = null)).then(() => {
            return dispatch(loadEquipmentFamily())
        })
    }
}
export const deleteEquipmentFamily = (itemId, cb = null) => {
    return cruds.deleteData('equipmentFamily', "/PrmEquipmentFamily/", itemId, cb);
}

export const updateEquipmentFamily = (o, itemId, cb = null) => {

    return cruds.putData('equipmentFamily', '/PrmEquipmentFamily/', o.id, o, cb);
}
export const updateEquipmentFamilyThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updateEquipmentFamily(o, itemId, cb = null)).then(() => {
            return dispatch(loadEquipmentFamily())
        })
    }
}


//Equipment 
export const loadEquipments = (value, cb = null) => {

    return cruds.getData('equipments' + types.GET_ITEMS, "/Equipment/", value, cb);
}
export const loadEquipmentsType = (type) => {

    return cruds.getData('equipments' + types.GET_ITEMS, "/Equipment?type=" + type);
}
export const createlEquipment = (item, cb = null) => {
    return cruds.postData('equipments', "/Equipment ", item, cb);
}
export const createlEquipmentThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createlEquipment(item, cb = null)).then(() => {
            return dispatch(loadEquipments())
        })
    }
}
export const deletelEquipment = (itemId, cb = null) => {
    return cruds.deleteData('Equipment', "/Equipment/", itemId, cb);
}

export const updatelEquipment = (o, itemId, cb = null) => {

    return cruds.putData('Equipment', '/Equipment/', o.id, o, cb);
}
export const updatelEquipmentThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updatelEquipment(o, itemId, cb = null)).then(() => {
            return dispatch(loadEquipments())
        })
    }
}

// DataType

export const loadAllDataType = () => {
    return cruds.getData('getDataType' + types.GET_ITEMS, "/PrmDataType");
}

export const createlDataType = (item, cb = null) => {
    return cruds.postData('getDataType', "/PrmDataType ", item, cb);
}
export const createlDataTypeThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createlDataType(item, cb = null)).then(() => {
            return dispatch(loadAllDataType())
        })
    }
}
export const deletelDataType = (itemId, cb = null) => {
    return cruds.deleteData('getDataType', "/PrmDataType/", itemId, cb);
}
export const deletelDataTypeThenReload = (itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(deletelDataType(itemId, cb = null)).then(() => {
            return dispatch(loadAllDataType())
        })
    }
}

export const updatelDataType = (o, itemId, cb = null) => {

    return cruds.putData('getDataType', '/PrmDataType/', o.id, o, cb);
}
export const updatelDataTypeThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updatelDataType(o, itemId, cb = null)).then(() => {
            return dispatch(loadAllDataType())
        })
    }
}

// subscription
export const loadAllSubcription = () => {
    return cruds.getData('subscription' + types.GET_ITEMS, "/Subscription");
}

export const createSubscription = (item, cb = null) => {
    return cruds.postData('subscription', "/Subscription ", item, cb);
}
export const createSubscriptionThenReload = (item, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(createSubscription(item, cb = null)).then(() => {
            return dispatch(loadAllSubcription())
        })
    }
}
export const deletelSubcription = (itemId, cb = null) => {
    return cruds.deleteData('subscription', "/Subscription/", itemId, cb);
}

export const updatelSubcription = (o, itemId, cb = null) => {

    return cruds.putData('subscription', '/Subscription/', o.id, o, cb);
}
export const updatelSubcriptionThenReload = (o, itemId, cb = null) => {
    return (dispatch, getState) => {
        return dispatch(updatelSubcription(o, itemId, cb = null)).then(() => {
            return dispatch(loadAllSubcription())
        })
    }
}
// profil 
export const updateFarmerProfil = (o, itemId, cb = null) => {

    return cruds.putData('updateProfil', '/updateProfil/', o.id, o, cb);
}


//irrigationMangment
export const createIrrigation = (item, cb = null) => {
    return cruds.postData('Irrigation', "/Irrigation ", item, cb);
}
export const deletelIrrigation = (itemId, cb = null) => {
    return cruds.deleteData('Irrigation', "/Irrigation/", itemId, cb);
}
export const updatelIrrigation = (o, cb = null) => {

    return cruds.putData('Irrigation', '/Irrigation/', o.id, o, cb);
}
export const updateGroupe = (o, cb = null) => {

    return cruds.putData('group', '/Group/', o.id, o, cb);
}
export const deleteGroup = (itemId, cb = null) => {
    return cruds.deleteData('group', "/Group/", itemId, cb);
}
// error 
export const errorChecked = (o, itemId, cb = null) => {


    return cruds.putData('organization', "/Error/", itemId, o, cb);
}
export const errorCorrected = (o, itemId, cb = null) => {


    return cruds.putData('organization', "/Error/", itemId, o, cb);
}
