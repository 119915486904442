/* eslint-disable no-useless-escape*/

export const en = {
  _SPACE_SENSE_LAUNCH_PACK_TITLE: 'One Year History of Satellite Imagery - Starter Pack',
  _SPACE_SENSE_LAUNCH_PACK_DESCRIPTION: 'One year history of satellite imagery relating to an area including NDVI, NDWI, NDRE, LAI and CHI indexes',
  parcelsNumber: 'Number of Parcels',
  areas: 'Areas',
  areasList: 'Areas List',
  parcels: 'Parcels',
  size: 'Size',
  parcel: 'Parcel',
  area: 'Area',
  areaInformation: 'Area information',
  addArea: 'Add a new Area',
  addParcel: 'Add a new Parcel',
  addSector: 'Add a new Sector',
  stopEditing: 'Stop Editing',
  editArea: 'Edit Area',
  removeSite: 'Remove site',
  removeParcel: 'Remove Parcel',
  selectedArea: 'Selected Area',
  addSite: 'Add Site',
  editParcel: 'Edit Parcel',
  editSector: 'Edit Sector',
  AgricultureArea: 'Agriculture Area',
  editSite: 'Edit Site',
  siteMap: 'Site Map',
  site: 'Site',
  mapView: 'Map View',
  listView: 'List View',
  yourSiteLabel: 'Your Sites',
  goToLabel: 'Find an Address/Coordinates',
  doubleClickToShowAreaDetail: 'Please double click a displayed area to load details...',
  sousArea: 'sub-areas',
  map: 'Map',
  numberDeployedDevices: '# of deployed devices',
  hectar: 'Hectar',
  navigateTo: 'Navigate to',
  sites: 'Sites',
  LatitudeLabel: 'Latitude',
  LongitudeLabel: 'Longitude',
  siteGateway: 'Site Gateway',
  drippers: 'Drippers',
  sector: 'Sector',
  searchPlace: 'Search Places ...',
  lines: 'Lines',
  noParcelCreatedLabel: 'There\'s no parcels ',
  noSectorCreatedLabel: 'There\'s no Sectors ',
  noAreaCreatedLabel: 'There\'s no Area ',
  ArgileLabel: 'Argile',
  onepage_areaDetail: 'Information and recommendations',
  addField: 'Add A New Field',
  providerLabel: 'Provider',
  personalizationdDataTypeSource: 'Customization of data type sources',
  addAreaUsingKmlFile: 'Add Area using KML, GeoJSON or JSON File',
  dragDropKmlFileLabel: 'Drag and drop a KML, GeoJSON or JSON file format here, or click to select a file',
  wrongChoosenFile: 'Wrong file format , please choose a KML, GeoJSON or JSON File Type',
  wrongFileContent: 'This file does not match the right content format',
  buyOneYearSateliteLabel: 'Buy one year of satellite image history ',
  indexTypeDescription: 'You will have access to 8 types of index including NDVI, NDWI, NDRE, EVI, CHI, LAI, SAVI, RGB ',
  lunchPromotionLabel: 'Launch promotion ',
  SateliteDescription: 'The use of satellite imagery in agriculture is not something new. It has been used in a limited way for decades, \n  mostly to provide simple things like indexes (NDVI, NDWI…). Satellite imagery help you to get :',
  cropHealthIndecator: 'Crop Health Indicator :',
  cropHealthIndecatorDescription: 'Monitor your crop health by detecting biotic and abiotic stresses through NDVI, NDWI and NDRE',
  cropAnomalyDetector: 'Crop Anomaly Detector:',
  cropAnomalyDetectorDescription: 'Identify the areas of the field where the crop is over or under performing compared to how it usually grows',
  viewDetailsLabel: 'View details',
  PayerLabel: 'Pay',
  paymentFormLabel: 'Payment Form',
  tva: 'VAT Number ',
  billingAddress: 'Billing address ',
  billingDetails: 'Invoicing Details',
  department: 'Department ',
  includingTaxesLabel: 'Including Taxes',
  seabexOffers: 'Seabex Offers',
  seabexOffersDiscount: 'I have a discount code',
  applyDiscount: 'Apply discount',
  seabexSatelliteImagesOffers: 'Satellite imagery for this parcel',
  cardDetailLabel: 'Card details',
  dialog_newArea: 'You have no areas saved yet. Do you want to create one now?',
  satelliteIndex: 'Vegetation Indices',
  satelliteImages: 'Satellite Images',
  noSatteliteImageAvailable: 'There is no satellite images available for this area. If you would get access to this service, please click on the button in the area details card.',
  ndvi: 'Normalized Difference Vegetation Index',
  ndwi: 'Normalized Difference Water Index',
  ndre: 'Normalized Difference Red Edge',
  savi: 'Soil Adjusted Vegetation Index',
  chi: 'Chlorophyll Index',
  evi: 'Enhanced Vegetation Index',
  lai: 'Leaf Area Index',
  nirv: 'Near-Infrared of Vegetation Index',
  rgb: 'RGB Satellite Image',
  ndvi_description: 'As the most popular index for crop health monitoring, NDVI measures the level of crop greenness and growth stage. For each pixel in this field image, the greener the pixel is, the more plant cover this area has; the redder the pixel is, the less plant cover this area has.',
  ndwi_description: 'As the most popular index for irrigation monitoring, NDWI measures the crop water stress status. For each pixel in this field image, the bluer the pixel is, the more water content this area has; the more orange the pixel is, the less water content this area has.',
  ndre_description: 'As the most popular index for fertilizer monitoring, NDRE measures the crop nutrient stress status, especially nitrogen. For each pixel in this field image, the greener the pixel is, the more nitrogen this area has; the redder the pixel is, the less nitrogen this area has.',
  savi_description: 'Similar to NDVI, SAVI is more suitable for crops with a large percentage of bare ground, such as wines, orchards early-stage crops, etc. For each pixel in this field image, the greener the pixel is, the more plant cover this area has; the redder the pixel is, the less plant cover this area has.',
  chi_description: 'As a crop productivity indicator, ChI measures the level of Chlorophyll content in plant leaves. For each pixel in this field image, the greener the pixel is, the more Chlorophyll content this area has; the redder the pixel is, the less Chlorophyll content this area has.',
  evi_description: 'When some crops arrive at a late growth stage, NDVI might saturate. EVI index allows you to continue crop monitoring at a late stage. For each pixel in this field image, the greener the pixel is, the more biomass this area has; the redder the pixel is, the less biomass this area has.',
  lai_description: 'As a commonly used index for biomass measurement, LAI indicates the variability in plant geometry. For each pixel in this field image, the greener the pixel is, the more biomass this area has; the redder the pixel is, the less biomass this area has.',
  nirv_description: 'Compared to other vegetation indices, NIRv is the closest reflection of crop productivity and yield. For each pixel in this field image, the greener the pixel is, the more productive this area is; the redder the pixel is, the less productive this area is.',
  rgb_description: 'When the vegetation indices change abruptly(i.e.cloud presence, harvesting), RGB (or \'truecolor\' images), is the perfect tool for you to do visual checks.',
  selectIndexLabel: 'You can select here your index',
  visibilityLabel: 'Visibility',
  lastupdatedLabel: 'Last updated on ',
  historicalLabel: 'Historical',
  forecastLabel: 'Forecast',
  emptyParcelLabel: 'The selected area does not contain parcels , do you want to create  one ?',
  emptySectorLabel: 'The selected area does not contain sectors , do you want to create  one ?',

  // irrigation types

  irrigationAspersion: "Sprinkler Irrigation",
  irrigationLocalized: "Localized Irrigation",
  irrigationSubmersion: "Flood Irrigation",
  irrigationPivot: "Pivot Irrigation",
  irrigationLocalizedDrip: "Drip Irrigation",
  irrigationLocalizedMicroAspersion: "Micro-sprinkler Irrigation",
  invoiceNumber: 'Invoice Number',
  invoicePaid: 'Paid',
  invoiceCurrencyAmount: 'Currency Amount',
  invoiceAmount: 'Amount',
  invoiceCurrencyTax: 'Currency Tax',
  invoiceDiscount: 'Discount',
  invoiceDeadline: 'Deadline',
  invoiceIsDraft: 'Draft',
  currency: 'Currency',
  deployedDevices: 'Deployed Devices',
  partnerIntegrations: 'Partner Integrations',
  deviceName: 'Device Name',
  deviceFamily: 'Device Family',
  identifier: 'Identifier',
  addStation: 'Add Station',
  stationEquipments: 'Equipments',
  deviceCategory: 'Device Category',
  deviceModel: 'Device Model',
  station: 'Station',
  selectGateway: 'Select Gateway',
  updateStation: 'Edit station',
  port: 'Port',
  selectStation: 'Select Station',
  addMarker: 'Add Marker',
  position: 'Position',
  gateway: 'Gateway',
  monitoring: 'Monitoring',
  stations: 'Stations',
  noModelYet: 'No model yet',
  action: 'Action',
  noAreaYet: 'No area yet',
  createDeployedDevice: 'Create Deployed Device',
  loadingDeviceInformation: 'Getting device informations',
  lastPing: 'Last Ping',
  publicIp: 'Public Ip',
  inetrnalIp: 'Internal IP',
  sdfreeSpace: 'SD free space',
  scheduledJobsLabel: 'Scheduled Jobs',
  confirmDeviceCoordinates: 'Confirm Device Coordinates',
  realTimeSensorsAndActuators: 'Real Time Sensors And Actuators',
  selectAreaToMonitor: 'Select the area you want to monitor',
  filterAdvice: 'Use filters for more precision',
  realTimeData: 'Connexion to Realtime Server',
  realtimeMonitoringPanel: 'Realtime Monitoring Panel',
  controlPanel: 'Control Panel',
  calculatedValue: 'Calculated Value',
  originalValue: 'Original value',
  notConnectedLabel: 'Disconnected',
  connectedLabel: 'Connected',
  relay_status: 'Relay status',
  noRealTimeDataLabel: 'there is no real time data for this site, because it does not contain Deployed Devices',
  autoScaleLabel: 'Auto scale',
  attachedOnLabel: 'attached on the',
  onSurLabel: 'on',
  isLabel: 'is',
  caseLabel: 'Reason',
  dueScenarioLabel: 'due to a scenario ',
  COMMAND: ' Sending command ... ',
  UserAction: ' User Action ',
  AutomationPlan: 'Automation plan',
  Scenario: 'Scenario',
  fromDeviceLabel: ' from the device ',
  possibleReasonLabel: 'Possible reason: the equipment is disconnected, improperly connected or damaged',
  originalUnitLabel: 'Original value unit',
  calculatedUnitLabel: 'Calculated value unit',
  activatedLabel: 'Activated',
  desactivatedLabel: 'Desactivated',
  youAreConnectedToGatewayLabel: 'You are connected to the Gateway!',
  noRealTimeDataToDisplayLabel: 'No real time data to display',
  defaultLabel: 'Default',
  rangeLabel: 'Range',
  relayActivationLabel: 'Relay activation ',
  relayDeactivation: 'Relay deactivation ',
  ofLabel: 'of',
  ageLabel: 'Age',
  cropCoefficientDescription: 'The Crop Coefficient (kc) is the ratio between crop evapotranspiration (ETc) and potential evapotranspiration (ET0), it integrates the effects of the 4 primary characteristics that distinguish a crop from the reference crop which are: height crop, soil-vegetation surface resistance, albedo, soil evaporation (Allen et al, 1998). " ',
  cycles: 'Cycles',
  cycleLabel: 'Cycle',
  day_number: 'Number of days since implantation',
  remainingDays: 'Remaining days',
  developmentPhase: 'Development phase',
  'Phase de developpement': 'Development phase',
  'Phase initiale': 'Initial Stage',
  Total: 'Total',
  Autre: 'Other',
  midSeasonPhase: 'Mid-season phase',
  suggestedValueLabe: 'This value is a suggested value based on our calculation! Feel free to change it.',
  'Phase mi-saison': 'Mid-season phase',
  durationMidSeasonPhase: 'Mid-season phase Duration',
  phaseArriereSaison: 'Late season',
  'Phase arrière-saison': 'Late season',
  durationPhaseArriereSaison: 'Late season Duration',
  phase: 'Phase',
  kc: 'Crop Coefficient',
  need_irrigation: 'Irrigation needed',
  currentPhaseIsLabel: 'The current phase is',
  endCycle: 'End Cycle',
  creationCycle: 'create a production cycle',
  RecommendedProductionCycle: 'Use Recommended Production Cycle',
  customizeProductionCycle: 'Customize your own Production Cycle',
  noCycleInSite: 'No production cycle is attributed to this site',
  cycleExist: 'Culture and area with cycle exist',
  productionCycle: 'Production Cycle',
  productionCycleCreateSuggestion: 'Would you like to create a production cycle for this new Area now?',
  addCycle: 'Add Cycle',
  editCycle: 'Edit Cycle',
  cycleDetails: 'Cycle Details',
  cycleDays: 'The number of days since the start of the cycle',
  end_cycle: 'End Production Cycle',
  openCycle: 'Open Cycle',
  cycle: 'Cycle',
  cycleStatus: 'In progress',
  finishCycle: 'Terminate',
  recommendedDate: 'Recommended Date',
  depth: 'Depth (m)',
  initialStage: 'Initial stage',
  durationInitialStage: 'Initial stage Duration',
  rootingDepth: 'Rooting depth (metre)',
  sensibilite: 'Sensitivity',
  durationDevelopmentPhase: 'Development phase Duration',
  addGrowthDuration: 'Add growth duration',
  editGrowthDuration: 'Edit growth duration',
  growthDurationLabel: 'Growth duration',
  yearsSinceImplimantation: 'Number of Years since implantation',
  monthSinceImplimantation: 'Number of Months since implantation',
  recalculateLabel: 'Start a recalculation',
  cycleInCalculation: 'Ongoing Calculation',
  cycle_text: 'The different phases of development',
  cycleEndedOn: 'Finished on',
  irrigationDaysNumber: 'Number of irrigation days',
  gettingCycleInformation: 'Getting cycle informations',
  addCycleLoadingMsg: 'Loading the calculation process',
  first_part: 'First 10 days',
  middle_part: 'Second 10 days',
  end_part: 'Last 10 days',
  implantationDateLabel: 'Date of implantation  ',
  evolutionCoefficientLabel: 'Evolution of the crop coefficient - Source (FAO) ',
  currentPeriod: 'Current period ',
  nocycleFoundedInSelectedArea: 'The selected area does not contain a production run, do you want to create  one ? ',
  numberOfDaysPerIrrigation: 'Number of days needed to water the field',
  specifyDateLabel: 'Specify Date ',
  currentPhaseLabel: 'Current phenological stage',
  newPhaseLabel: 'New phase',
  changePhaseLabel: 'Change Phase',
  confirmPassageLabel: 'Please confirm that the passage from ',
  tookplaceLabel: 'took place on ',
  toPhaseLabel: 'to phase',
  thePhaseLabel: 'The phase',
  endOnLabel: 'Completed on  ',
  calculatedStandinfLabel: 'calculated outstanding ',
  waterRequirementLabel: 'Water requirement and water balance',
  evapotranspiration: 'ETP potential evapotranspiration',
  adjustedIrrigationVolume: 'Suggested Irrigation',
  adjustedSoilWaterVolume: 'Adjusted soil water volume',
  survivalReserve: 'Survival Reserve',
  adjustedSurvivalReserve: 'Adjusted Survival Reserve',
  totalPrecipitation: 'Precipitation',
  totalPrecipitationForecasted: 'Precipitation forecast for today',
  waterInSoil: 'Amount water in Soil',
  availableWaterInSoil: 'Amount water in Soil',
  actualEvapotranspiration: 'Actual evapotranspiration',
  todayWaterNeed: 'Crop Water Need for Today',
  calculationParam: 'calculation parameter  ',
  waterNeedHistory: 'Water need evolution',
  eau_utile: 'Usable water',
  irrigationQantity: 'Quantity to irrigate ',
  waterNeedNextDays: 'Water Need for the next days of a complete irrigation cycle',
  fillRate: 'Soil fill rate ',
  useful_Reserve: 'Useful Reserve (mm)',
  starting_Reserve: 'Starting Water Content (mm)',
  easily_useful_Reserve: 'Easily Usable Reserve',
  metreJour: 'mm',
  precipitationUnit: 'L/m²',
  hectarMcube: 'm³/hectare',
  drainageLabel: 'Drainage',
  waterQuantity: 'Amount of water',
  waterNeedRecalculatedProcessing: 'The recalculation of water needs is being processed',
  waterNeedRecalculated: 'Water requirement successfully recalculated',
  howManyDaysLabel: 'Every how many days?',
  simplifiedViewLabel: 'Simplified view',
  recommendationsViewLabel: 'View with recommendations',
  totalPrecipitationUnitLabel: 'Total Precipitation (mm)',
  totalIrrigationsPerformedLabel: 'Total Irrigations Performed (mm)',
  totalRecommendedIrrigations: 'Total Recommended Irrigations (mm)',
  dataDetailsLabel: 'Details of the data',
  waterNeedOnDemand: 'Water requirement on demand',
  drawZone: 'Draw a zone',

  apply_changes_for_other_areas: "Apply changes to other areas?",
  automationPlan: 'Automation Plan',
  automationPlans: 'Automation Plans',
  AddAutomationPlan: 'Add Automation Plan',
  editAutomationPlan: 'Edit Automation Plan',
  tasksLabel: 'Tasks',
  addIrrigationPlan: 'Add Irrigation Plan',
  selectedMonths: 'Selected Months',
  selectedMonthDays: 'Selected Month Days',
  selectedWeekDays: 'Selected Week Days',
  selectActuator: 'Select Actuator',
  onLabel: 'On',
  offLabel: 'Off',
  scenarioLabel: 'Scenario',
  chapriority: 'Priority',
  changePriority: 'Change Priority',
  operatorLabel: 'Operator',
  conditions: 'Conditions',
  scEquation: 'Equation',
  sensor: 'Sensor',
  sensorType: 'Sensor Type',
  warningCondition: 'at least one condition must be provided',
  warningAction: 'at least one action must be provided',
  actionType: 'Action Type',
  priority: 'Priority',
  conditionsList: 'Conditions List',
  deviceID: 'Device Identifier',
  shared_device: 'Shared Device',
  related_areas: 'Related Areas',
  pin: 'PIN',
  operator: 'Operator: <>=',
  value: 'Value',
  equation: 'Equation',
  astationOrAutomation: 'Station/AutomationPlan',
  delay: 'Delay',
  delayInMinute: 'Delay in minutes',
  selectStatus: 'Status de départ',
  Logs: 'Logs',
  multiLogs: 'Multi-Logs',
  dataLogs: 'Data Logs',
  findLogs: 'Find Logs',
  choiceBySite: 'Choice By Site',
  choiceByDeployedDevice: 'Choice By Deployed Device',
  downloadLabel: 'Download',
  inputSourceOrigin: 'The origin of this entry is a file',
  loadingFilelogs: 'File download in progress ...',
  intervalUnitLabel: 'Interval Unit',
  intervalNumberLabel: 'Interval Number',
  addNewDataTypeLabel: 'Add new datatype',
  confirmSelectionLabel: 'Confirm the selection',
  saveSelectionLabel: 'Save sélection',
  findEvents: 'Find Events',
  eventsList: 'Events list',
  Events: 'Events',
  network: 'My Network',
  reason: 'Reason',
  assistedMode: 'Assisted Mode',
  automaticMode: 'Automatic Mode',
  detailsLabel: 'Details',
  connect: 'Connect',
  duration: 'Duration',
  AssistedAction: 'Assisted Mode',
  modeLabel: 'Mode',
  youHaveActivatedLabel: 'You have activated',
  ofTheDeviceLabel: 'of the device',
  youHaveDesactivatedLabel: 'You have deactivated',
  atTimeLabel: 'at',
  errorOccuredOnLabel: 'An error has occurred on',
  youAreConnectedLabel: 'You are connected',
  sentACommandLabel: 'You have sent a command on',
  youOpenedLabel: 'You opened',
  youClosedLabel: 'You closed',
  onTheDeviceLabel: 'on the device',
  irrigationMangement: 'Irrigation Management',
  irrigationDate: 'Irrigation Date',
  addIrrigation: 'Add Irrigation',
  editIrrigation: 'Edit Irrigation',
  findIrrigation: 'Find Irrigation',
  irrigationType: 'Irrigation Type',
  addIrrigationType: 'Add Irrigation Type',
  editIrrigationType: 'Edit Irrigation Type',
  irrigationFamily: 'Irrigations Family',
  addIrrigationFamily: 'Add Irrigation Family',
  editIrrigationFamily: 'Edit Irrigation Family',
  irrigationMode: 'Irrigation Mode',
  userIrrigationManagement: 'Irrigation Type Management',
  waterVolume: 'Irrigation',
  irrigationVolume: 'Irrigation Volume',
  waterVolumeLabel: 'Water volume',
  isoUnit: 'Iso Unit',
  efficiency: 'Efficiency',
  automatic: 'Automatic',
  manuel: 'Manual',
  gettingIrrigationInformation: 'Geting Irrigation informations',
  addMultipleIrrigationLabel: 'Add multiple irrigations',
  editMultipleIrrigationLabel: 'Edit multiple irrigations',
  multipleIrrigationLabel: 'Multiple irrigation',
  rainfallManagement: 'Rainfall management ',
  rainfallList: 'Rainfall list',
  currentPluvio: 'Current rainfall ',
  correctedRainfall: 'Corrected rainfall',
  subscriptions: 'Subscriptions',
  subscribe: 'Subscribe',
  smsPrice: 'SMS Price',
  emailPrice: 'Email Price',
  appPrice: 'Application Price',
  price: 'Price',
  total: 'Total',
  apiLabel: 'API',
  alert: 'Alert',
  advice: 'Advice',
  sms: 'SMS',
  application: 'Application',
  addSubscription: 'Add Subscription',
  editSubscription: 'Edit Subscription',
  SubscriptionType: 'Subscription type',
  disableEdit: 'You cannot Edit',
  showAllGroup: 'All Groups',
  showMyGroup: 'My Groups',
  groupAdded: 'Group added successfully',
  addGroup: 'Add Group',
  groupLabel: 'Group',
  leftGroup: 'You Left the Group',
  groupeManagment: 'Groups Mangement',
  editGroup: 'Edit Group',
  leaveGroup: 'leave the group',
  disableDelete: 'You cannot delete ',
  subscribeSucces: 'You are registered in the group',
  alreadySubscribe: 'You have already subscribed to the group',
  unfollow: 'Unfollow',
  followers: 'Followers',
  nbFollowers: 'Number of followers',
  nbFollowersSeabex: 'Number of SEABEX followers',
  newFollower: 'New follower',
  fullName: 'Full Name',
  fullNameOnCard: 'Full Name On Card',
  agents: 'Agents',
  newAgent: 'New Agent',
  agent: 'Agent',
  sentByAgent: 'Sent by agent',
  SentNotifNumber: 'Number of sent notifications',
  maximumHeat: 'maximum temperature ',
  minimumHeat: 'minimum temperature ',
  temperature: 'Temperature',
  temperatureAverage: 'The average temperature',
  temperatureDewPointWeekLabel: 'Temperature / dew point and humidity trends for today and the next 48 hours ',
  humidity: 'Humidity rate  ',
  rainPrecipitation: 'Rain Precipitation ',
  snowPrecipitation: 'Snow Precipitation ',
  uvIndex: 'Ultraviolet Index',
  cloudCover: 'Cloud Cover',
  dewPoint: 'Dew Point',
  windSpeed: 'Wind speed  ',
  windUnit: 'km/h',
  windSpeedUnit: 'm/s',
  windDirection: 'Wind direction',
  sunset: 'Sunrise & Sunset',
  veryHighLabel: 'Very high',
  windStatus: 'Wind Status',
  highLabel: 'Hight',
  extremeLabel: 'Extreme',
  pressureLabel: 'Atmospheric Pressure',
  nextWeekWeather: 'Next Days Weather',
  weatherStations: 'Weather Stations',
  Evolution24H: 'Today\'s 24 hour weather forecast',
  WeatherToday: 'Weather of Today',
  temperatureApparent: 'Apparent temperature ',
  humidite_au_point_fletrissement: 'Wilting point',
  bas_du_rfu: 'Minimum threshold of the easily usable reserve',
  seuil_declenchement: 'Irrigation trigger point',
  moderateLabel: 'Moderate',
  lowLabel: 'Low',
  timeZone: 'Time zone',
  weatherTrends: 'Weather Trends',
  SoilElecConductivity: 'Soil electroconductivity',
  SoilTemperature: 'Soil temperature',
  SoilWaterContent: 'Soil volumic water content',
  chooseMySoilType: 'Choose my soil type',
  createMyCustomSoilType: 'Create my soil texture by horizon',
  customSoilStructure: 'Custom soil texture by horizon',
  addNewSoilHorizon: 'Add a new soil horizon',
  soilStructure: 'Soil Structure in the Selected Area',
  addSoilType: 'Add a soil type',
  editSoilType: 'Edit soil type',
  solTexture: 'soil texture',
  addSoilTexture: 'Add soil Texture',
  editSoilTexture: 'Edit soil texture',
  soilSensibilite: 'Soil sensitivity',
  recommendedSoilType: 'Use Estimated Soil Type',
  soilType: 'Soil Type',
  soilFamily: 'Soil Family',
  userSoilManagement: 'Soil Management',
  humidite_capacite_au_champ: 'Field capacity',
  salinity: 'Salinity',
  densite_apparente: 'Apparent density',
  deleteCulture: 'Delete Culture',
  cultureFamily: 'Culture Family',
  addCultureFamily: 'Add Culture Family',
  editCultureFamily: 'Edit Culture Family',
  deleteCultureFamily: 'Delete Culture Family',
  addCulture: 'Add Culture',
  editCulture: 'Edit Culture',
  Culture_Name: 'Culture Name',
  culture: 'Culture',
  userCultureManagement: 'Culture Management',
  phaseNameLabel: 'Phase Name',
  phaseDurationLabel: 'Phase duration',
  developmentStagesLabel: 'Please insert the stages of development of the plant ',
  DegreeDayOfGrowthLabel: 'Degree day of growth ',
  cultureDetailLabel: 'Culture detail',
  length: 'Duration ',
  name: 'Phase ',
  degree: 'Degree',
  rootDepth: 'Depth',
  userPreferencesLabel: 'User Preferences',
  fileName: 'File name',
  fileSize: 'File size',
  bytesLabel: 'Bytes',
  dragDropFileLabel: 'Drag \'and\' drop some files here, or click to select files',
  correspondenceTableLabel: 'correspondence table',
  sourcesManagement: 'Sources management',
  sourceLabel: 'Sources type',
  measureLabel: 'Measures',
  colmunsLabel: 'Columns',
  fileManagment: 'File management',
  configurationLabel: 'Configuration',
  fileList: 'Files list',
  dataSource: 'Data Source',
  fileSended: 'File Successfully Sended',
  waitWhileFileIsSending: 'Loading a file sending ...',
  wrongChoosenXlsxFile: 'Wrong file format , please choose a XLSX type File',
  equipmentList: 'Equipment Type',
  addEquipmentType: 'Add Equipment Family',
  addEquipment: 'Add Equipment',
  editEquipmentType: 'Edit Equipment Family',
  editEquipment: 'Edit Equipment',
  noAttachedEquipment: 'No Equipment attached',
  noAttachedDataType: 'No Data Type attached',
  equipmentType: 'Equipment Family',
  userEquipmentManagment: 'Equipment Management',
  reference: 'Reference',
  attachDataType: 'Attach data type to equipment',
  equipmentLabel: 'Equipment',
  equipmentTypeOfFamily: 'Equipment Types of Family',
  onTheEquipmentLabel: 'on the equipment',
  financialManagment: 'Financial management ',
  financialCategorie: 'Financial categories',
  accountingManagement: 'Accounting Management',
  invoiceManagement: 'Invoices Management',
  customerManagement: 'Customers Management',
  productManagement: 'Products Management',
  voucherManagement: 'Vouchers Management ',
  financialCategoryLabel: 'Financial category',
  userName: 'User Name',
  financialType: 'Financial type',
  financialCategoryParent: 'Financial category parent',
  expensesRevenue: 'Expenses / Revenues',
  myFinancialCategorieLabel: 'My financial categories',
  activitiesManagement: 'Activities management',
  activitiesList: 'Activities list',
  activityLabel: 'Activity',
  activityType: 'Activity type',
  pickColor: 'Pick a color',
  activityTypeByMe: 'My Activity type',
  activities: 'Activities',
  addActivity: 'Add Activity',
  addActivityType: 'Add Activity Type',
  culturalActivities: 'Cultural activities',
  agriculturalActivities: 'Agricultural activities',
  homePageLabel: 'Home',
  AuthorLabel: 'Author',
  subTitle: 'SubTitle',
  publicationsLabel: 'Publications',
  publicationLabel: 'Publication',
  publicationManagment: 'Publications Management',
  worldWideLabel: 'World wide',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  'Every Month': 'Every Month',
  'Every Month Day': 'Every Month Day',
  'Every Day': 'Every Day',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Tomorrow: 'Tomorrow',
  today: 'Today',
  Yesterday: 'Yesterday',
  now: 'Now',
  dayLabel: 'days',
  MonthLabel: 'Month',
  owner: 'Owner',
  timeLabel: 'Time',
  startTime: 'Start Time',
  endTime: 'End Time',
  from: 'From',
  to: 'To',
  created_at: 'Created on',
  date: 'Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  captureTime: 'capture Time',
  meanLabel: 'Mean',
  noRecordToDisplay: 'No records to display',
  rowsLabel: 'rows',
  actions: 'Actions',
  nextpage: 'Next Page',
  lastpage: 'Last Page',
  previouspage: 'Previous Page',
  firstpage: 'First Page',
  search: 'Search',
  noDataAvailable: 'No Available Data',
  next: 'NEXT',
  previous: 'PREVIOUS',
  refreshData: 'Refresh Data',
  titleLabel: 'Title',
  loading: 'Loading...',
  valueLabel: 'Value',

  rainfallEditReminder: "When you finish editing the rainfall, don't forget to save your changes.",
  saveChanges: "Save Changes",
  confirmCancelMessage: "Please confirm or cancel the change before proceeding!",
  subArea: "Sub Area",
  referenceArea: "Reference Area",
  //snackbar msg 
  deleteMsg: ' Deleted successfully',
  accessGranted: 'Access Granted!',
  confirmationLabel: 'Are you sure you want to',
  confirmationLabelEndCycle: 'Are you sure you want to close the cycle on the selected date',
  emailCheckAccount: 'Please check your email to verify your account !',
  emailCheckPassword: 'Please check your email to reset your password !',
  emailCheckPasswordChanged: 'Password has been successfully changed, Please verify your account',
  checkAccessCode: 'Please check your access codes',
  errorOccurred: 'An error has occurred',
  errorChecked: 'Error checked',
  errorCorrected: 'Error Corrected',
  checkedLabel: 'Checked',
  correctedLabel: 'Corrected',
  updateSucc: 'apdated successfully',
  addedSucc: 'added successfully',
  registerDone: 'Register Done ',
  connectionSuccessful: 'Connection Successful',
  connectionFailed: 'Connection Failed',
  enabled: 'Enabled',
  disabled: 'Disabled',
  accepted: 'Accepted',
  proposed: 'Proposed',
  serverSideLabel: 'Server Side',
  tryAgainLabel: 'Please try again later',
  checkUrData: 'Please check your insert data',
  theLabel: 'the',
  successfullyConnectedOn: 'Successfully Connected on',
  at: 'at',
  successfullyExecutionOn: 'Successfuly Execution on',
  automationPlanExecutionOn: 'Automation plan execution on',
  automationPlanExecutionFailedOn: 'Automation plan execution failed on',
  addNewUser: 'New User',
  EditUser: 'Edit User',
  mobile: 'Telephone number',
  address: 'Address',
  diploma: 'Diploma',
  speciality: 'Speciality',
  experience: 'Experience',
  region: 'Region',
  agree: 'Agree',
  disagree: 'Disagree',
  skills: 'Skills',
  invitationVoucher: 'Purchase or discount coupon',
  iHaveAVoucherDiscount: 'I have a discount voucher',
  applyVoucher: 'Apply',
  invitationVoucherWarning: 'Registration to the Seabex platform is open, in preview, to customers who have made their requests to the Seabex team via the invitation form. You must have an invitation code to be able to register. Make sure you have this code before registering.',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'Email',
  stationName: 'Device name',
  password: 'Password',
  c_password: 'Confirm Password',
  memberSince: 'Member since',
  ville: 'city',
  enterPhoneNumber: 'Enter phone number',
  getContactedBySeabex: 'If you would like to be contacted by Seabex for a follow up concerning your account, please enter your phone number',
  requiredPhoneNumber: 'Required phone number',
  invalidPhoneNumber: 'Invalid phone number',
  clientSeabex: 'SEABEX member',
  username: 'Username',
  userList: 'User List',
  invoiceList: 'Invoice List',
  voucherList: 'Voucher List',
  addNewVoucher: 'Add new Voucher',
  EditVoucher: 'Edit Voucher',
  clientList: 'Client List',
  productList: 'Product List',
  organizationList: 'Organization List',
  forgetPassword: 'Forgot your password? click here.',
  checkYourEmail: 'Check you email address',
  resetPassword: 'Reset password',
  newpassword: 'New password',
  placeToSearch: 'Enter a place to search for',
  userManagement: 'User Management',
  partnerManagement: 'Partners Management',
  organizationManagement: 'Organization Management',
  areaManagement: 'Areas Management',
  termsConditions: 'Terms and conditions',
  youCanCreateOne: 'You can create one here',
  privacyPolicy: 'Privacy Policy',
  activeLabel: 'Active',
  active: 'Active',
  inactiveLabel: 'Deactivate',
  notConnectedYet: 'Pas encore connecté',
  restoreLabel: 'Restore',
  restoreDescription: 'This operation will definitively delete: the data of the selected area: ',
  restoredItem: 'Collected data , Weather, Irrigation, Cycle, Deployed devices',
  noAreaToDisplayLabel: 'The selected user does not have zones to display them ',
  securePasswordConditions: 'Minimum 10 alphanumeric characters with, preferably, at least one capital letter, a number and a symbol',
  userType: 'You are (please select the right option)',
  errorInSignUp: 'An error occurred during your registration process, please verify the information entered.',
  successfulSingup: 'Thanks for registering to the Seabex Platform, we sent you a validation email. Please click on the link to activate your account.',
  registrationSuccLabel: 'Registration Successful',
  registrationErrorLabel: 'Registration Error',
  accountExistLabel: 'Account Already Exists!',
  invalidInvitationVoucher: 'The invitation voucher is invalid!',
  _MOBILE_ALREADY_EXISTS_: 'This phone number is attached to another account!',
  dontHaveAnAccount: 'If you don\'t have an account',
  signin: 'Sign in',
  alreadyRegistered: 'Already registered ? connect here...',
  signup: 'Sign up',
  logout: 'Logout',
  loginInvalidLabel: 'Email or password is incorrect try again',
  mailInvalid: 'Email Invalid ',
  formFillText: 'Please fill out this form to register on the Seabex platform:',
  formFillTextAddUser: 'Please fill out this form to register a new user on the platform:',
  acceptTerms: 'To continue, please click on the checkbox to confirm that you agree to the <a href=\'https://seabex.com/conditions-generales-dutilisation/\' target=\'_blank\'>terms and conditions of use</a> and <a href=\'https://seabex.com/politique-de-confidentialite/\' target=\'_blank\'>the Privacy Policy</a>.',
  Farmer: 'Producer',
  Super: 'Admin',
  Expert: 'Expert',
  Institution: 'Institute',
  searchCountry: 'Search country',
  specifications: 'Specifications',
  searchGovernorate: 'Search  for Governorate',
  governates: 'Governates',
  delegations: 'Delegations',
  city: 'City',
  country: 'Countries',
  government: 'Government',
  confirmBtn: 'Confirm',
  cancelBtn: 'Cancel',
  add: 'Add',
  delete: 'Delete',
  multiDelete: 'Are you sure you want to confirm the deletion of irrigations?',
  editLabel: 'Edit',
  backLabel: 'Back',
  addLabel: 'Add',
  start: 'Start',
  open: 'Open',
  close: 'Close',
  openAction: 'Open',
  closeAction: 'Close',
  stateOpen: 'Open',
  stateClosed: 'Closed',
  addNode: 'Add Node',
  confirmationTitle: 'Confirmation',
  resetSelection: 'Reset Selection',
  addSelection: 'Add Selection',
  startAction: 'Start Action',
  endAction: 'End Action',
  save: 'Save',
  saved: 'Saved successfully!',
  cancel: 'Cancel',
  findLabel: 'Find',
  send: 'Send',
  operationSucc: 'Your operation was successful',
  ok: 'OK',
  yes: 'Yes',
  no: 'No',
  reset: 'Reset',
  finishLabel: 'Finish',
  newSearch: 'New Search',
  submit: 'Save',
  remove: 'Remove',
  addLigneLabel: 'Ajouter une ligne',
  validateBtnLabel: 'To validate ',
  confirmationbtnLabel: 'Confirmation',
  confirmDelete: 'Delete confirmation',
  confirmAction: 'Confirm the sending of the action',
  warningLabel: 'Warning',
  title: 'Title',
  description: 'Description',
  type: 'Type',
  fieldRequired: 'Required',
  passwordDntMatch: 'password don\'t match',
  emailInvalidLabel: 'Email Invalid',
  minLength: 'Must have a minimum length of ',
  emailNotValid: 'Invalid email',
  name_label: 'Name',
  required: 'this field is required',
  mismatch: 'password mismatch',
  name_ar: 'Name in Arabic',
  name_fr: 'Name in French',
  name_en: 'Name in English',
  unity: 'Unit',
  status: 'Status',
  'public': 'Public',
  'private': 'Private',
  nonProfit: 'Non profit',
  CompanyLabel: 'Company ',
  companyNameLabel: 'Comapny Name',
  modelPackName: 'Model name',
  addModelPack: 'Add Model',
  editModelPack: 'Edit Model',
  editCategory: 'Edit Categorie',
  seabexCategoryStation: 'Model Station',
  seabexModelCategory: ' Category Model',
  category: 'Category',
  addCategory: 'Add Category',
  addCategoryModel: 'Add Model',
  editCategoryModel: 'Edit Model',
  userCatalogManagement: 'Catalog Management',
  serielNumber: 'Serial Number',
  categoryName: 'Category Name',
  seabexModelType: 'Seabex Model',
  categories: 'Categories',
  portNameLabel: 'Port name',
  portTypeLabel: 'Port Type',
  categoryFamily: 'Category family',
  errorHandler: 'Error Management',
  andLabel: 'and',
  error_text: 'Error ',
  errorList: 'Error List',
  statusCode: 'Code Http',
  itemManagmentLabel: 'Item Managment',
  itemCategory: 'Item Category',
  item: 'Item',
  itemsLabel: 'Items',
  otherInformations: 'Other Information',
  profile: 'Profile  ',
  userPersonnelInfo: 'User Informations',
  seeProfile: 'See Profile',
  activesince: 'Active Since',
  addExpense: 'Add Expense',
  farmers: 'Farmers',
  experts: 'Experts',
  viewMore: 'View More',
  avatarImportationLabel: 'import your avatar',
  birthDateLabel: 'Birth date (optional) ',
  joinedLabel: 'Joined  : ',
  lastConnectionLabel: 'Last connection : ',
  addDataType: 'Add Data Type',
  editDataType: 'Edit Data Type',
  datatype: 'Data Types',
  dataTypeManagment: 'Data Type Management',
  dataTypeNature: 'Data type nature',
  abbreviationLabel: 'Abbreviation',
  systemLabel: 'System',
  SingularLabel: 'Singular',
  pluralLabel: 'Plural',
  defaultUnit: 'Default unit',
  unitListsLabel: 'Unit List ',
  dataTypeListOfLabel: 'Data Type List of ',
  noDataTypeSelectedYetLabel: 'No data types selected yet',
  dataTypesDisplayed: 'Data types to be displayed: ',
  managingQuotasLabel: 'Managing  quotas',
  quotaLabel: 'Quota  (m3)',
  quota: 'Quota',
  seasonStartLabel: 'Start of season ',
  seasonEndLabel: 'End of season ',
  addQuota: 'Add quota',
  editQuota: 'Edit quota',
  organization: 'Organizations',
  contactOrganization: 'Contact Organization',
  organizationName: 'Organization name',
  newAffiliatedorganization: 'New Affiliated organization',
  editAffiliatedorganization: 'Edit Affiliated organization ',
  organizationcity: 'Organization city',
  Organization: 'Organization ',
  formula: 'Formula',
  myformulas: 'My formulas',
  allFormulas: 'All formulas',
  seeFormula: 'See formula',
  FormulaName: 'Formula name',
  newFormula: 'new Formula',
  formules: 'Formulas',
  formulasList: 'Formulas List',
  formulaDisplay: 'This is your formula',
  serviceListLabel: 'Services',
  useFormulaLabel: 'Use Formula',
  useAlgorithmLabel: 'Use algorithm',
  algorithmLabel: 'Algorithm',
  addVariableToDataType: 'Add variable to selected data Type',
  nbbeneficiers: 'Check the number of subscribers',
  AdvicesService: 'Notifications Service',
  newNotification: 'New notification',
  notificationType: 'Notification type',
  others: 'Others',
  content: 'Content',
  sendingDate: 'Sending date',
  lastNotifs: 'latest notifications',
  allStepsCompleted: 'All steps completed',
  color: 'Choose The Color',
  plantType: 'Plant Type',
  harvestDate: 'Harvest Date',
  activityTypes: 'Activity Types',
  expenses: 'Expenses',
  revenues: 'Revenues',
  addRevenue: 'Add Revenue',
  quantity: 'Quantity',
  planification: 'Planning',
  planning: 'Planning',
  Statistics: 'Statistics',
  model: 'Model',
  Settings: 'Settings',
  userGeoDataManagement: 'GeoData Management',
  introSeabexTitleGuide: 'Welcome',
  changeLanguageTitleGuide: 'Change the language of the guide title',
  changeStepsTitleGuide: 'change Steps Title Guide',
  stepperTriggerTitleGuide: 'stepper Trigger Title Guide',
  ariticleTitleGuide: 'Home',
  introSiteTitleGuide: 'Introduction Sites',
  addSiteTitleGuide: 'Adding a Site via ‘+’',
  goToButtonTitleGuide: 'Adding sites ‘Go to’',
  goToModalTitleGuide: 'Latitude in combination with longitude',
  drawAreaButtonsTitleGuide: 'Add a new zone sites',
  drawPolygonRectangleButtonTitleGuide: 'Drawing of Sites on the map',
  addNewAreaModalTitleGuide: 'The fields to add the site',
  areaOnMapTitleGuide: 'Le cadre du site',
  areaSoilStructureTitleGuide: 'Soil composition',
  nextButtonAddAreaTitleGuide: 'Next',
  dataSourceTableTitleGuide: 'Type of data sources ',
  addNewSourceTitleGuide: 'Add Data Type & Data Sources',
  saveBtnAddAreaTitleGuide: 'Save',
  areasListTitleGuide: 'New site registration notification',
  addAreaUsingFileTitleGuide: 'Adding a new site using the pin',
  addParcelSectorTitleGuide: 'Site list view',
  introCycleTitleGuide: 'Introduction Cycles',
  addCycleBtnTitleGuide: 'Add a cycle via ‘+’',
  addCycleModalTitleGuide: 'The fields to add the cycle',
  cycleListTitleGuide: 'List of cycles',
  mapViewBtnTitleGuide: 'Introduction one pager Report',
  onePagerMeteoTitleGuide: 'Weather  report ',
  onePagerWaterNeedTitleGuide: 'Water requirement report',
  onePagerWaterNeedHierarchyTitleGuide: 'Report The calculation tree',
  onePagerWaterNeedHistoryTitleGuide: 'Historical water requirement report',
  introDeployedDevicesTitleGuide: 'Introduction Deployed Devices',
  addDeployedDeviceBtnTitleGuide: 'Adding a device deployed via ‘+’ ',
  addDeployedDeviceModalTitleGuide: 'The fields to add the Device',
  addDeployedDeviceModal1TitleGuide: 'Modal Step 1',
  addDeployedDeviceModal2TitleGuide: 'Modal Step 2',
  addDeployedDeviceModal3TitleGuide: 'Modal Step 3',
  realTimeintroTitleGuide: 'Real Time intro Title Guide',
  realTimeTitleGuide: 'real Time Title Guide',
  logsIntroTitleGuide: 'logs Intro Title Guide',
  logsTitleGuide: 'logs Title Guide',
  dialog_notNow: 'Not Now',
  dialog_okAcceptance: 'OK',
  dialogRecalculateTitle: 'This recalculation operation can take several minutes. When the recalculation is complete, you will be notified. Please consult the results on the page dedicated to the plot concerned by clicking on the \'information and recommendations\' button in the line of the plot concerned.',
  helpAndSupport: 'Help And Support',
  watchGuideAgain: 'Watch guide again',
  addAreaNameGuide: 'Site Name',
  addAreaDescriptionGuide: 'Site Description',
  introSeabexGuide: 'Our tutorial helps you to have the best experience on our platform:\n- The creation of a site / Sub-sites (parcel / sector)\n- The creation of a cycle\n- Deployed devices\n- Real time',
  changeLanguageGuide: 'Please choose from 3 languages ​​to use:\n- French\n- English\n- Arabic',
  changeStepsGuide: 'change Steps Guide\nPlease go to the next step',
  stepperTriggerGuide: '::stepper Trigger::stepper Trigger Guide\nPlease click on the question mark, the tutorial clarifying an item among the menu eg Sites will be displayed.',
  articleGuide: 'Please find in the home, news and events that interest you\n(Example: Citrus training by the Citrus Technical Center (CTA))',
  introSiteGuide: 'Please start by creating the sites / parcel and sectors:\nIndeed, a site (a location dedicated to agricultural activities  nA sub-site / a plot (Portion of land with the same crop / vegetation) and a sector (Subdivision of a plot).  N  nYou now start with the creation of a site.',
  addSiteGuide: 'Please start by pressing the ‘+’ button to add a new site, you will automatically access the \'Map View\' to choose the location and draw the site on the World Map.',
  goToButtonGuide: ' Please go to any location quickly by clicking \'Go to\' this green button for quick and easy navigation.',
  goToModalGuide: 'Please press ‘Go to’, you will automatically get a popup of a form. You have the choice:\n- Or fill in the first field with the name of the country / city / street address or known place.\n- Or insert the latitude in combination with the longitude to indicate the precise position of an element on Earth. Please click on Go to coordinates for the location.',
  drawAreaButtonsGuide: ' Please press the add new zone button to add a new site.',
  drawPolygonRectangleButtonGuide: '::stop Editing::If you want to stop drawing the site boundaries before you finish it and go back to the previous step.\n::add Area Polygon::Pour le polygone , please click once on a point on the site boundaries and end up clicking on the same starting point.\n::add Area Rectangle::For the rectangle, please click once on the mouse, drag according to the size of the site and finally release the mouse.\nBy pressing the space button on the keyboard, these shapes will be deactivated and by pressing again they will be reactivated.\n::map Layers::You have the world Map in several versions (Satellite, terrain etc ...) through the button of the superimposed rectangles at the top right on the map.\n::draw Area Buttons::If you want to stop drawing the site boundaries before finishing it and go back to the previous step .........',
  addNewAreaModalGuide: 'If you finish drawing the site boundaries, a form with fields to fill in will appear.\n\nYou will have the opportunity to:\n- Note the title of the site (required field)\n- Note the description field (optional field)\n\n;;Case 1:;; If you choose the recommended soil type checked by default, you will have the fields appearing successively one after the other is filled:\n* ‘The irrigation family \'to be filled in with 3 choices\n* ’Type of irrigation ’to be filled in with 4 choices\n;;Case 2:;; If you check \'choose my soil type\', you will have the fields appearing successively one after the other is filled:\n* \'Soil type\'\n* \'Soil texture ’to fill in\n* ‘The irrigation family’ to be filled in with 3 choices\n* ‘Irrigation type’ to be filled in with 4 choices',
  areaOnMapGuide: '- The site created with a blue frame on the map according to the limits drawn in the previous steps.',
  areaSoilStructureGuide: '- You will have a detailed composition of the site\'s soil.  N * On the left you find the different depths of the soil\n* Each row represents the percentage of each of these elements in your soil at that level.\n*On the right you find the essential components of soils: clay, silt and sand.\n*At the bottom you find the percentage of each of these elements in your soil.',
  nextButtonAddAreaGuide: 'Once the fields are completed, please move forward in the site configuration by pressing the NEXT button',
  dataSourceTableGuide: 'You can go to the level to choose among the parameters \'Type of data\' or by adding your own, obtained by previously downloading files \'sources of data\' or by choosing the default ones like \'Seabex Default\'.',
  addNewSourceGuide: '- To add a new data type at the ‘Data type’ parameters.\n- You can add your own data types by clicking on the + button.\n- By default, this last type of data will be added at the end of the list of other data.\n- Moreover this last one will have by default “SEABEX DEFAULT” as source but you can choose another one.',
  saveBtnAddAreaGuide: 'Once the fields are filled in, you can finish configuring the site by pressing the SAVE button',
  areasListGuide: 'Once the steps for creating a new site are completed,\n::snackBar::A notification will appear at the bottom left of the screen:\n;;- Case 1:;; If the site is not created due to an error, the notification will be red showing \'error\'\n;;- Case 2:;; If the site is created, the notification will be green showing \'Added successfully\'\n;;-Case 3:;; ......\n\nOn the other hand, you can see a list of all created sites with actions on each site (like edit or delete).\n',
  addAreaUsingFileGuide: 'You have another method to add a new site: Please press the pin to download a KML, JSON or GeoJSon file containing the coordinates of the site.\nBy downloading the file, you will automatically be in the form step of creating a site the same steps.',
  addParcelSectorGuide: 'Addition of a sub-section (parcel of a site or a sector of a parcel):\n::map Button:: By pressing this button, you will automatically access the mapview and have the possibility to add a sub-section (parcel of a site or a sector of a parcel).\nIn the map, by double clicking on a site, you can create a sub-section. (parcel of a site or a sector of a parcel) .........\n::arrow Site:: By pressing the arrow to the left of the site names, a list of sub-sites / plots is displayed below.',
  introCycleGuide: 'You start by creating a cycle in order to have several useful information about your cycles such as the need for water.\nIndeed, the type of production can be classified according to different criteria:\n;;the scope of agricultural activity: ;; for consumption or industrial purposes, the latter can in turn have a food (peanut) or industrial processing (raw material: cotton)  n ;; type of crop: ;; Perennial (fruit trees) or annual ( But)\n;;the objective of animal production: ;; Food consumption or valuation of by-products, etc.',
  addCycleBtnGuide: 'Please start by pressing the ‘+’ button to add a new cycle and you will have a form to fill with several fields.',
  addCycleModalGuide: 'The fields to be filled in to declare the cycle:\n- Site: choose the site for which you have this cycle\n- Crop family: There are 4 families of fodder crops, arboriculture, market gardening, cereals\nBy choosing one among these families, a field of the sub-families appears:\n-Culture: depends on the culture family chosen\n- Cycle start date: How long have these crops been planted\n::Date cycle:: \n- After the farmer will choose:\n- (a) In the case: Use the recommended production cycle the following fields appear:\nProduction cycle*\nRoot depth (meter)*\nNumber of days of irrigation (non-compulsory field)\nQuantity of water in the soil (non-mandatory field)\nField capacity*\nWilting point*\n::recommended Production Cycle:: \n \n- (b) In the case: Customize your own production cycle the following mandatory fields appear:  nInitial phase*\nDevelopment phase*\nMid-season phase*\nPhase arrière-saison*\nRoot depth (meter)*\nNumber of days of irrigation\nAmount of water in the soil\nField capacity*\nWilting point*\n::Cycle production:: The water requirement calculation and the preparation of a \'one pager\' report will be calculated automatically and you will wait until the loading is completed\n- Case 1: If the cycle is not created due to an error, the notification will be red showing \'error\'\n \n::culture Cycle:: \n- Case 2: Si le cycle est créé, la notification va être verte affichant ‘Ajouté avec succès’\n- Cas 3: Si le cycle existe déjà pour ce site (sera double),lLa notification va être bleu. \n::existCycleNotif:: \n',
  cycleListGuide: 'Once the fields have been filled in and the steps for creating a new cycle are completed, you will automatically find the list of your cycles.\nColumns containing:\n- The names of the sites\n- Crop names\n- Cycle start date (When planted)\n- their descriptions inserted by you previously in the site creation form.\n* You get the information as added value from SEABEX:\n-Number of days since implantation\n-End of cycle: Can be finished according to the recommended duration automatically or manually by clicking on the button  n-The phase in which your crop is: initial phase, development phase, mid-season phase, post-season phase.  N - By pressing the arrow to the left of the cycle names, the phase your crop is in + the recommended start and end date for your cycle is displayed below.',
  mapViewBtnGuide: 'Please press the button on the right, you will automatically access the mapview and have “a one pager”, a report containing:\n- Soil structure in the selected area\n- Meteorological data:\n- Cycle details\n- the history of water requirements with curves\n- irrigation details\n- the amount of water to irrigate\n- the tree structure for calculating the water requirement',
  onePagerMeteoGuide: 'Crop name: (example: Green beans) and the parameters: Quantity to irrigate and its inputs for example Real Evapotranspiration',
  onePagerWaterNeedGuide: 'one Pager Water NeedGuide 24',
  onePagerWaterNeedHierarchyGuide: 'Please find the tree structure for calculating the water requirement, each row represents the inputs needed for the parameter in the row above until you have the amount of water to irrigate.\nAll data is calculated for 24',
  onePagerWaterNeedHistoryGuide: 'Please choose the period for which you want to view the amount of irrigated water and other data.\nPlease press\nYou will have curves that represent the following data that appear by checking the boxes next to it\nThe adjusted water reserve\nSuggested irrigation\nReference evapotranspiration\nActual Evapotranspiration\nPrecipitation\nIrrigationQuantity to irrigate\nCapacité au champ\nPoint de flétrissement\nQuantité d\'eau dans le sol\nVeuillez trouver les données enregistrées chaque 24 h dans ce tableau comme historique de référence pour vous.',
  introDeployedDevicesGuide: 'You start by creating a Device',
  addDeployedDeviceBtnGuide: 'Please start by pressing the ‘+’ button to add a new Device and you will have a form to fill with several fields.',
  addDeployedDeviceModalGuide: 'The fields to be filled in to declare the Device:\n- Site: choose the site for which you have this Device\n- stationName : the Device name \n device Category: There are 9 Category like Gateway , Action , Acquisition \nBy choosing one among these Categories, a field of the sub-Categories appears:\n-Device Family: depends on the Category  chosen\n- Device Model : depends on the Device family  chosen\n-Identifier : the Device identifier \n',
  addDeployedDeviceModal1Guide: 'step 1 explain fields contens',
  addDeployedDeviceModal2Guide: 'step 2 explain how to draw and change location using gif image including some text',
  addDeployedDeviceModal3Guide: 'step 3 explain in use case if gateway there is no step 3 and the other use cases explain the fields to fill',
  realTimeintroGuide: 'You can find the list of deployed devices here.',
  realTimeGuide: '::real Time Status:: You can know whether you are connected to the devices or not.\n \n \n::real Time Table:: \n \n \nHere you find the list of deployed devices, with some actions on the devices (OPEN, CLOSE).\n If you are in the event of an error, or a change of state, You will be notified by the notification bar.',
  logsIntroGuide: 'You can find the history of all data captured here.',
  logsGuide: 'You can search and list the logs through the choice of site or the choice of a deployed device.\n::logs Statistics:: \n This chart lists the values ​​captured over time.\n::logs Table:: \n You will find all the information captured in this table sorted with the capture time.\n::download XSL:: You can even download an XLS format file containing all the captured data by clicking this button',
  dashboardLabel: 'Dashboard',
  processingtimeLabel: 'Processing time',
  CounterLabel: 'Counter',
  allRequestStatusLabel: 'All requests Status',
  requestStatusByDateLabel: 'Request Status Logs by Date',
  requestStatusLogsLabel: 'Request status  logs',
  refreshAreaManagement: 'Refresh the list of sites',
  cleanDeletedAreas: 'Permanently delete deleted sites',
  ordersMenu: 'My Purchases and Subscriptions',
  orderDate: 'Date',
  orderTotal: 'Total paid',
  orderDetail: 'Purchase order details',
  ordersList: 'Purchase Orders List',
  nowConnected: 'You are now connected to instant notifications!',
  nowDisconnected: 'You are now disconnected from instant notifications!',
  productionCycleCalculationNotification: 'The calculation for the production cycle of the culture %culture_name% on %area_name% has been restarted',
  mySubscribedServices:'My Subscribed Services',


  // Payment Manager
  paymentConfirming: "Confirmation of payment in progress...",
  paymentSuccessful: "Your payment has been taken into account, you will receive a confirmation by email.",


  // extra

  impersonatingUser: "You are impersonating the user account of ",
  sessionEndsIn: "Session ends in ",
  leaveImpersonation: "Leave Impersonation",
  solMaximumDepth: 'Maximum Exploitable Soil Depth',
  unknownDepth: "I don't know my default depth",
  compactionRate: "Compaction Rate (%)",
  greenFruits: "Green Fruits",
  changeStartDate: "To change the start date of a phenological stage, click on it on the progress bar.",
  totalCumulativePrecipitation: "Total and Cumulative Precipitation and Rainfall by Month",
  totalCumulativePrecipitationIrrigation: "Total and Cumulative Precipitation and Irrigation by Month",
  demandeAssistance: 'Request for Assistance',
  videosTutoriaux: 'Tutorial Videos',
  rainfallPerMonth: 'Precipitation per month',
  irrigationPerMonth: "Irrigation per month",
  dontKnow: 'I do not know',
  numberDripperPerFoot: "Number of drippers per foot",
  flowRatePerDripper: "Flow per dripper (L/h)",
  sizePerTree: "Area size per foot (m²)",
  minIrrigationCapacity: "Minimum irrigation capacity (mm)",
  maxIrrigationCapacity: "Maximum irrigation capacity (mm)",
  minIrrigationCapacityHelper: "Minimum Recommendation (mm)",
  maxIrrigationCapacityHelper: "Maximum Recommendation (mm)",
  fixedIrrigationCapacity: "Fixed irrigation capacities (separated by commas) (mm)",
  fixedIrrigationCapacityHelper: "Only one of the values entered will be used for the recommendation",
  minMaxVariableIrrigations:"Min/max Variable Irrigation",
  fixedIrrigations:"Fixed Irrigations",
  recommendationLimit:"Recommendation limits",
  speed: 'Speed',
  radius: 'Radius',
  partner: 'Partenaire',
  permissions: 'Access rights',
  unit: 'Unit',
  yield: 'Yield',
  quintals: 'Quintals per hectare (q/ha)',
  tons: 'Tons per hectare (t/ha)',
  kilograms: 'Kilograms per hectare (kg/ha)',
  renewalMsg: '🌟 Renew now and save 10%! 💼🔒 Limited Offer till %validity%',
  discountForAllMsg: '🌟 Subscribe now and save 10%! 💼🔒 Limited Offer till %validity%',
  activeSubscriptionExpiration: "Active subscription expires on ",
  save_order_btn : 'Submit Order and Request quote',
  laterProcessingMessage : "You can submit your order and request that we send you a quote. This will allow you to pay by check or transfer and have rapid validation. For more information do not hesitate to contact us by telephone",
  laterProcessingMessageMailPart : "or by email on",
  postal_code: 'Postal Code',

  //partners Integration
  partner_activate:'Activate',
  partner_configure:'Configure',
  partner_disable:'Disable',

  advancedOptions:'Advanced Settings (soon)',
  

}




