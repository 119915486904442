import React from "react";
import { Card } from "@material-ui/core";

 const StyledCard = React.forwardRef((props, ref) => {

    const {children,marginTop,direction,width, elevation,displayCard,styleProp, borderRadius, additionalStyle, ...properties} = props

    const style = {
        borderRadius: borderRadius!=null ? borderRadius : 6,   
        display:displayCard ? displayCard:"flex",
        flexDirection: direction ,
        width:width,
        marginTop:marginTop, 
        ...additionalStyle
    }


    return (
        <Card
            {...properties} 
            ref={ref}
            elevation={elevation ? elevation : 8} 
            style={styleProp?styleProp:style}
            // variant="outlined"
        >
            {children}
        </Card>
    )

})

export default StyledCard