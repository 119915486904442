import * as types from '../actions/actionTypes';
import { dispatchAlert } from '../actions';
import { GAxios } from '../globalsTools/network'
import { envKeys } from '../config';


export const postFile = (n, url, key, file, cb = null) => {
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        let data = new FormData();
        data.append(key, file[0]);

        //call upload file web serice then call reducer to set new changes
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url, 'post', data).then(function (r) {
            dispatch({
                type: n + types.GET_ITEMS,
                payload: r.data,
                iserror: false,
            });
        }).catch(function (r) {
            dispatchAlert(dispatch, 'Erreur  serveur while Posting Data  : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
        }).then(function (r) {
            dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading) 
        });


    }
}


/**
 * Central function to execute Get Queries on URL 
 * 
 * @param {String} n : Action Name to Dispatch on Success
 * @param {String} url : URL to get Data from
 * @param {Function} cb : CallBack Function to execute on success
 */
export const getData = (n, url, cb = null) => {

// with redux Thunk (action return) is a function that contain two params 1-dispatch 2- getState()-> function contain application store (all reducer)
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        //** consume the web service using get method to get the list of items then call reducer to set new changes**
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url).then(function (r) {
            dispatch({
                type: n ,
                payload: r.data,
                iserror: false,
            });
            if (cb) { setTimeout(function () { cb(r); }, 10); }
        }).catch(function (r) {

            dispatchAlert(dispatch, r.response? (r.response.data ? r.response.data.message :''):r.response  ) ;//call dispatchAlert action 

            if (cb) { setTimeout(function () { cb(r.response); }, 10); }
        }).then(function (r) {
            dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading) 
        });
    }
}


export const getSpecData = (n, url, cb = null) => {

    // with redux Thunk (action return) is a function that contain two params 1-dispatch 2- getState()-> function contain application store (all reducer)
        return function (dispatch, getState) {
            dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
            //** consume the web service using get method to get the list of items then call reducer to set new changes**
            return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url).then(function (r) {
                dispatch({
                    type: n + types.GET_SPECIFIC_ITEMS,
                    payload: r.data,
                    iserror: false,
                });
                if (cb) { setTimeout(function () { cb(r); }, 10); }
            }).catch(function (r) {
                dispatchAlert(dispatch, 'Erreur serveur while geting Data : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
    
                // dispatch({
                //     type: n + types.GET_ITEMS,
                //     payload: [],
                //     iserror: true
                // });
                if (cb) { setTimeout(function () { cb(r.response); }, 10); }
            }).then(function (r) {
                dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading) 
            });
        }
    }

export const postData = (n, url, data, cb = null) => {
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        //** consume the web service using post method to pass new item as data then call reducer to set new changes**
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url, 'post', data).then(function (r) {
            dispatch({
                type: n + types.CREATE_ITEM,
                payload: r.data,
                iserror: false
            });
            if (cb) { setTimeout(function () { cb(r); }, 10); }
        }).catch(function (r) {
            dispatchAlert(dispatch, r.response? (r.response.data?r.response.data.message:'')  :  'Error connection to server' ) ;//call dispatchAlert action 
            if (cb) { setTimeout(function () { cb(r.response); }, 10); }
        }).then(function (r) { dispatch({ type: types.APP_LOADING, payload: false }); });//call reducer to set loading to "false"(stop loading) 
    }
}




export const deleteMultiple = (n, url, data, remainingElement, cb = null) => {
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        //** */consume the web service using post method to pass array of items as data to delete them then call reducer to set new changes**
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url, 'post', data).then(function (r) {
            dispatch({
                type: n + types.DELETE_MULTIPLE,
                payload: remainingElement,
                iserror: false
            });
            if (cb) { setTimeout(function () { cb(r); }, 10); }
        }).catch(function (r) {
            dispatchAlert(dispatch, 'Erreur  serveur while deleting Data  : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
            // dispatch({
            //     type: n + types.DELETE_MULTIPLE,
            //     payload: null,
            //     iserror: true
            // });
            if (cb) { setTimeout(function () { cb(r.response); }, 10); }
        }).then(function (r) { dispatch({ type: types.APP_LOADING, payload: false }); });//call reducer to set loading to "false"(stop loading) 
    }
}



export const putData = (n, url, id, data, cb = null) => {
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        //** */consume the web service using put method to update an item  then call reducer to set new changes**
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url + id, 'put', data).then(function (r) {
            dispatch({
                type: n + types.UPDATE_ITEM,
                id: id,
                payload: r.data,  //r.data,
                iserror: false
            });
        }).catch(function (r) {
            dispatchAlert(dispatch, 'Erreur  serveur while Editing Data  : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
        }).then(function (r) {
            dispatch({ type: types.APP_LOADING, payload: false });});//call reducer to set loading to "false"(stop loading)
        
    }
}

export const deleteData = (n, url, id, cb = null) => {
    return function (dispatch, getState) {
        dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
        //** */consume the web service using delete method to delete an item  then call reducer to set new changes**
        return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url + id, 'delete').then(function (r) {
            dispatch({
                type: n + types.DELETE_ITEM,
                id: id,
                iserror: false
            });
        }).catch(function (r) {
            dispatchAlert(dispatch, 'Erreur  serveur while deleting Data  : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
            // dispatch({
            //     type: n + types.DELETE_ITEM,
            //     id: id,
            //     iserror: true
            // });
        }).then(function (r) {
            dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading) 
        });
    }
}

// export const linkData = (n, url, e1, e2, linkedName) => {
//     return function (dispatch, getState) {
//         dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
//         return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url + '?e1=' + e1.id + '&e2=' + e2.id).then(function (r) {
//             let newE1 = { ...e1 };
//             newE1[linkedName].push(e2);
//             dispatch({
//                 type: n + types.UPDATE_ITEM,
//                 id: newE1.id,
//                 payload: newE1,
//                 iserror: false
//             });
//         }).catch(function (r) {
//             dispatchAlert(dispatch, 'Erreur serveur : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
//             dispatch({
//                 type: n + types.GET_ITEMS,
//                 payload: [],
//                 iserror: true
//             });
//         }).then(function (r) {
//             dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading)
//         });
//     }
// }

// export const unlinkData = (n, url, e1, e2Id, linkedName) => {

//     let myArray = [{ id: 'a1', name: 'Rabbit' }, { id: 'a2', name: 'Cat' }];
//     myArray = myArray.filter(i => i.id !== 'a1');


//     return function (dispatch, getState) {
//         dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
//         return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url + '?e1=' + e1.id + '&e2=' + e2Id).then(function (r) {
//             let newE1 = { ...e1 };
//             newE1[linkedName] = newE1[linkedName].filter(i => i.id !== e2Id);

//             dispatch({
//                 type: n + types.UPDATE_ITEM,
//                 id: newE1.id,
//                 payload: newE1,
//                 iserror: false
//             });
//         }).catch(function (r) {
//             dispatchAlert(dispatch, 'Erreur serveur : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 
//             dispatch({
//                 type: n + types.GET_ITEMS,
//                 payload: [],
//                 iserror: true
//             });
//         }).then(function (r) {
//             dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading)
//         });
//     }
// }



    export const getAreaChildToupdateSelectedArea = (n, url, cb = null) => {

        // with redux Thunk action return is a function that contain two params 1-dispatch 2- getState()-> function contain application store (all reducer)
            return function (dispatch, getState) {
                dispatch({ type: types.APP_LOADING, payload: true });//call reducer to set loading to "true"(start loading)
                //** consume the web service using get method to get the list of items then call reducer to set new changes**
                return GAxios(getState().app.token, envKeys.GLOBAL_URL + "/api" + url).then(function (r) {
                    dispatch({
                        type: "area"+types.SELECT_ITEM ,
                        payload: r.data,
                        iserror: false,
                    });
                    if (cb) { setTimeout(function () { cb(r); }, 10); }
                }).catch(function (r) {
                    dispatchAlert(dispatch, 'Erreur serveur while Get Data : ' + ((r.response) ? r.response.status : ''));//call dispatchAlert action 

                    if (cb) { setTimeout(function () { cb(r.response); }, 10); }
                }).then(function (r) {
                    dispatch({ type: types.APP_LOADING, payload: false });//call reducer to set loading to "false"(stop loading) 
                });
            }
        }